"use client";

import { useComponentState } from "@natera/material/lib/hooks";
import Textfield, { TextfieldProps } from "@natera/material/lib/textfield";
import {
  Textfield as TextfieldV2,
  TextfieldProps as TextfieldPropsV2,
} from "@natera/material/lib/textfield/v2";
import classnames from "classnames";
import * as R from "ramda";
import { v4 as uuidv4 } from "uuid";
import * as React from "react";
import InputMask from "react-input-mask";
import { defineMessages, useIntl } from "react-intl";
import { Utils } from "../";
import { onInputBlurChange } from "./inputMaskBlurChange";

export type BaseDateInputProps = {
  minDate?: Date;
  maxDate?: Date;
} & TextfieldProps &
  TextfieldPropsV2;

interface DateInputMaskProps extends BaseDateInputProps {
  date?: Date;
  defaultDate?: Date;
  onMaskComplete?: (date: Date | undefined) => void;
}

export const messages = defineMessages({
  datePlaceholder: {
    id: "material.inputMask.placeholder",
    defaultMessage: "mm/dd/yyyy",
  },
});

export const DateInputMask = React.forwardRef<
  HTMLInputElement,
  DateInputMaskProps
>(
  (
    {
      date,
      defaultDate,
      maxDate,
      minDate,
      onMaskComplete,
      clearable = true,
      className,
      disabled,
      name,
      required = false,
      forceActiveFocus,
      ...props
    },
    ref: React.RefObject<HTMLInputElement>
  ) => {
    const [uuid, setUuid] = React.useState<string>();

    const TextfieldComponent = props.label ? TextfieldV2 : Textfield;

    React.useEffect(() => {
      setUuid(uuidv4());
    }, []);

    const [day, setDay] = useComponentState<Date>({
      controlledValue: date,
      defaultValue: defaultDate,
      onChange: onMaskComplete,
    });

    const [maskedValue, setMaskedValue] = React.useState<string>("");

    const setMaskedValue$ = React.useCallback(
      (date$: Date | undefined) => setMaskedValue(Utils.getUSLocaleDate(date$)),
      []
    );

    const setDay$ = React.useCallback(
      (date$: Date | undefined) => {
        if (!R.equals(date$, day)) {
          setDay(date$);
        }

        setMaskedValue$(date$);
      },
      [day, setMaskedValue$, setDay, date, maskedValue, onMaskComplete]
    );

    React.useEffect(() => {
      setMaskedValue$(day);
    }, [day]);

    const handleInputDateChange = React.useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        setMaskedValue(value);
        e.stopPropagation();
      },
      [setMaskedValue]
    );

    const handleInputBlurChange = React.useCallback(
      () => onInputBlurChange(setDay$, maskedValue, date, maxDate, minDate),
      [date, maskedValue, maxDate, minDate, setDay$, onMaskComplete]
    );

    const onKeyDown = React.useCallback(
      (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
          handleInputBlurChange();
        }
      },
      [handleInputBlurChange, date, maskedValue, onMaskComplete]
    );

    const handleClearChange = React.useCallback(() => {
      setDay$(undefined);
    }, [setDay$, date, maskedValue, onMaskComplete]);

    const alwaysShowMask = props.label
      ? !forceActiveFocus && !day
        ? false
        : true
      : undefined;

    const intl = useIntl();

    const textfieldV2Props = props.label
      ? {
          forceActiveFocus: forceActiveFocus,
        }
      : {};

    return (
      <>
        <input
          hidden={true}
          name={name}
          readOnly={true}
          value={day ? Utils.writeDate(day) : ""}
        />
        {!date && (
          <span hidden={true} id={`input-mask-id_${uuid}`}>
            {intl.formatMessage(messages.datePlaceholder)}
          </span>
        )}
        <InputMask
          mask="99/99/9999"
          maskPlaceholder={props.label ? "MM/DD/YYYY" : "_"}
          alwaysShowMask={alwaysShowMask}
          value={maskedValue}
          onChange={handleInputDateChange}
          onBlur={handleInputBlurChange}
          disabled={disabled}
        >
          <TextfieldComponent
            aria-haspopup="true"
            aria-describedby={`input-mask-id_${uuid}`}
            {...props}
            {...textfieldV2Props}
            placeholder={intl.formatMessage(messages.datePlaceholder)}
            clearable={clearable && Boolean(day)}
            onClear={handleClearChange}
            ref={ref}
            required={required}
            className={classnames("date-field", className)}
            onKeyDown={!disabled ? onKeyDown : undefined}
          />
        </InputMask>
      </>
    );
  }
);

export default DateInputMask;
