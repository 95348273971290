import * as React from "react";
import { DateFormatter } from "react-day-picker";

export interface DateInputController {
  minDate: Date;
  maxDate: Date;
  numberOfMonths?: number;
  changeMonthYear: (date: Date) => void;
  formatMonthTitle: DateFormatter;
  formatMonthInMenu: (date: Date) => string;
}

export const DateInputContext = React.createContext<DateInputController>({
  minDate: new Date(),
  maxDate: new Date(),
  numberOfMonths: 1,
  changeMonthYear: () => undefined,
  formatMonthTitle: () => undefined,
  formatMonthInMenu: () => "",
});
