import * as React from "react";

interface Props {
  weekDaysLength: number;
  slotTimesLength: number;
  children?: React.ReactNode;
}

export interface ArrowNavigationController {
  onKeydownHandlerArrowNavigation: (e: React.KeyboardEvent) => void;
  currentFocusWeek: () => number;
  currentFocusSlot: () => number;
}

export const ArrowNavigationContext =
  React.createContext<ArrowNavigationController>({
    onKeydownHandlerArrowNavigation: () => undefined,
    currentFocusWeek: () => -1,
    currentFocusSlot: () => -1,
  });

export const ArrowNavigationProvider: React.FunctionComponent<Props> = ({
  children,
  weekDaysLength,
  slotTimesLength,
}) => {
  const [currentFocusWeek, setCurrentFocusWeek] = React.useState<number>(0);
  const [currentFocusSlot, setCurrentFocusSlot] = React.useState<number>(0);

  const onKeydownHandlerArrowNavigation = (e: React.KeyboardEvent) => {
    if (e.key === "ArrowRight") {
      e.preventDefault();
      setCurrentFocusSlot(
        currentFocusSlot === slotTimesLength - 1 ? 0 : currentFocusSlot + 1
      );
    } else if (e.key === "ArrowLeft") {
      e.preventDefault();
      setCurrentFocusSlot(
        currentFocusSlot === 0 ? slotTimesLength - 1 : currentFocusSlot - 1
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setCurrentFocusWeek(
        currentFocusWeek === 0 ? weekDaysLength - 1 : currentFocusWeek - 1
      );
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      setCurrentFocusWeek(
        currentFocusWeek === weekDaysLength - 1 ? 0 : currentFocusWeek + 1
      );
    }
  };

  const controller: ArrowNavigationController = React.useMemo(
    () => ({
      onKeydownHandlerArrowNavigation: onKeydownHandlerArrowNavigation,
      currentFocusWeek: () => currentFocusWeek,
      currentFocusSlot: () => currentFocusSlot,
    }),
    [onKeydownHandlerArrowNavigation, currentFocusWeek, currentFocusSlot]
  );

  return (
    <ArrowNavigationContext.Provider value={controller}>
      {children}
    </ArrowNavigationContext.Provider>
  );
};

export const useArrowNavigation = (): ArrowNavigationController => {
  return React.useContext<ArrowNavigationController>(ArrowNavigationContext);
};
