import * as R from "ramda";

type GetConfig = (...names: string[]) => string | undefined;

export interface ConfigServiceInterface {
  getConfig: GetConfig;
}

export default class ConfigService<C> implements ConfigServiceInterface {
  constructor(readonly config: C) {}

  public getConfig: GetConfig = (...names) => {
    return R.path<string>(names, this.config);
  };
}
