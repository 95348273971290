"use client";

import { useComponentState } from "@natera/material/lib/hooks";
import { Textfield } from "@natera/material/lib/textfield";
import * as R from "ramda";
import { defineMessages, useIntl } from "react-intl";
import { DayRangePickerWrapper, DateRange } from "../";
import { getUSLocaleDateRange } from "../utils";
import { BaseDateInputProps } from "./inputMask";

import * as React from "react";
import { DateFormatter } from "react-day-picker";

export interface DateRangeInputProps extends BaseDateInputProps {
  range?: DateRange;
  defaultRange?: DateRange;
  onDateRangeChange?: (range: DateRange | undefined) => void;
  numberOfMonths?: number;
  showOutsideDays?: boolean;
  enableOutsideDaysClick?: boolean;
  defaultMonthYear?: Date;
  formatWeekdayName?: DateFormatter;
  formatMonthTitle?: DateFormatter;
  formatMonthInMenu?: (date: Date) => string;
}

export const messages = defineMessages({
  placeholder: {
    id: "material.range.placeholder",
    defaultMessage: "mm/dd/yyyy - mm/dd/yyyy",
  },
});

export const DateRangeInput: React.FunctionComponent<DateRangeInputProps> = ({
  range,
  defaultRange,
  maxDate,
  minDate,
  disabled,
  onDateRangeChange = R.always(undefined),
  numberOfMonths,
  showOutsideDays = false,
  enableOutsideDaysClick = false,
  defaultMonthYear = new Date(),
  formatWeekdayName,
  formatMonthTitle,
  formatMonthInMenu,
  ...props
}) => {
  const [range$, setRange$] = useComponentState<DateRange | undefined>({
    controlledValue: range,
    defaultValue: defaultRange,
    onChange: onDateRangeChange,
  });
  const intl = useIntl();

  const onClear$ = () => {
    setRange$(undefined);
  };

  const title = range$ ? getUSLocaleDateRange(range$) : "";

  return (
    <DayRangePickerWrapper
      numberOfMonths={numberOfMonths}
      enableOutsideDaysClick={enableOutsideDaysClick}
      showOutsideDays={showOutsideDays}
      minDate={minDate}
      maxDate={maxDate}
      defaultMonthYear={defaultMonthYear}
      range={range$}
      onSelectRange={setRange$}
      formatWeekdayName={formatWeekdayName}
      formatMonthTitle={formatMonthTitle}
      formatMonthInMenu={formatMonthInMenu}
    >
      {(menu) => (
        <Textfield
          {...props}
          title={title}
          readOnly={true}
          selectable={true}
          clearable={true}
          disabled={disabled}
          onClick={!disabled ? menu.openMenu : undefined}
          value={title}
          onClear={onClear$}
          dropDownActive={menu.isOpen()}
          placeholder={intl.formatMessage(messages.placeholder)}
        />
      )}
    </DayRangePickerWrapper>
  );
};

export default DateRangeInput;
