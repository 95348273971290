import Chip from "@natera/material/lib/chip";
import { TabItem, TabItemProps } from "@natera/material/lib/tab";
import { Link } from "@natera/platform/lib/components/link";
import classnames from "classnames";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import "./bucket.scss";

export interface BucketProps extends TabItemProps {
  to: string;
  name: string;
  count?: number | undefined;
}

export const messages = defineMessages({
  loading: {
    id: "platform.bucket.loading",
    defaultMessage: "Loading...",
  },
});

export const Bucket: React.FunctionComponent<BucketProps> = ({
  id,
  className,
  to,
  name,
  count,
  active,
  title,
  icon,
}) => {
  const size = (
    <Chip tabIndex={-1} className="bucket-size">
      {typeof count === undefined ? "..." : count}
    </Chip>
  );

  const intl = useIntl();

  return (
    <Link id={id} to={to} className="link-tab">
      <TabItem
        title={title ? title : intl.formatMessage(messages.loading)}
        active={active}
        className={classnames("bucket", className)}
        icon={icon || size}
      >
        <span className="bucket-name">{name}</span>
        {Boolean(icon) && size}
      </TabItem>
    </Link>
  );
};

export default Bucket;
