import classnames from "classnames";
import * as React from "react";
import "./chip.scss";

export type ChipSetProps = React.HTMLAttributes<HTMLDivElement>;

export const ChipSet: React.FunctionComponent<ChipSetProps> = ({
  children,
  className,
  ...props
}) => (
  <div
    {...props}
    role={props.role || "grid"}
    className={classnames("mdc-evolution-chip-set", className)}
  >
    <div className="mdc-evolution-chip-set__chips">{children}</div>
  </div>
);

export default ChipSet;
