import { Link, LinkProps } from "@natera/platform/lib/components/link";
import * as React from "react";
import { BasePatient } from "@natera/patient/lib/types";
import { getFullName } from "@natera/patient/lib/utils/patient";

interface Props extends Omit<LinkProps, "to"> {
  patient: BasePatient;
}

const PatientLink: React.FC<Props> = ({ patient, ...rest }) => {
  return (
    <Link {...rest} className="patient-link" to={`/patient/${patient.id}`}>
      {getFullName(patient)}
    </Link>
  );
};

export default PatientLink;
