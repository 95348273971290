"use client";

import { IconButton } from "@natera/material/lib/button";
import { DateInputContext } from "./context";
import MonthYearSelect from "./monthYear";

import * as R from "ramda";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";

import ArrowLeftSvg from "@natera/material/assets/svg/arrow_leftward.svg";
import ArrowRightSvg from "@natera/material/assets/svg/arrow_rightward.svg";
import classNames from "classnames";
import {
  addMonths,
  cloneDate,
  isAfter,
  isBefore,
  startOfMonth,
} from "../utils";

interface NavBarElementProps {
  month: Date;
}

export const messages = defineMessages({
  previousMonthAriaLabel: {
    id: "material.date.previousMonth",
    defaultMessage: "Previous month",
  },
  nextMonthAriaLabel: {
    id: "material.date.nextMonth",
    defaultMessage: "Next month",
  },
});

const NavBarElement: React.FunctionComponent<NavBarElementProps> = ({
  month,
}) => {
  const intl = useIntl();

  const nextMonth = addMonths(startOfMonth(month), 1);
  const previousMonth = addMonths(startOfMonth(month), -1);

  const {
    maxDate,
    minDate,
    numberOfMonths,
    changeMonthYear,
    formatMonthTitle,
  } = React.useContext(DateInputContext);
  const numberOfMonths$ = numberOfMonths || 1;

  const previousMonthLastDay = cloneDate(previousMonth);
  previousMonthLastDay.setMonth(previousMonthLastDay.getMonth() + 1);
  previousMonthLastDay.setDate(previousMonthLastDay.getDate() - 1);

  const nextHandler: React.MouseEventHandler = React.useCallback(
    () => changeMonthYear(nextMonth),
    [nextMonth]
  );

  const previousHandler: React.MouseEventHandler = React.useCallback(
    () => changeMonthYear(previousMonth),
    [previousMonth]
  );
  return (
    <div
      className={classNames("rdp-base-navbar", {
        "rdp-base-paged-months": numberOfMonths$ > 1,
      })}
    >
      {numberOfMonths$ === 1 ? (
        <>
          <MonthYearSelect date={month} />
          <IconButton
            disabled={
              Boolean(minDate) && isBefore(previousMonthLastDay, minDate)
            }
            className="rdp-base-navbar-arrow"
            onClick={previousHandler}
            aria-label={intl.formatMessage(messages.previousMonthAriaLabel)}
          >
            {ArrowLeftSvg}
          </IconButton>
          <IconButton
            disabled={Boolean(maxDate) && isAfter(nextMonth, maxDate)}
            className="rdp-base-navbar-arrow"
            onClick={nextHandler}
            aria-label={intl.formatMessage(messages.nextMonthAriaLabel)}
          >
            {ArrowRightSvg}
          </IconButton>
        </>
      ) : (
        R.range(0, numberOfMonths$).map((index) => (
          <div key={index}>
            {index === 0 ? (
              <IconButton
                disabled={
                  Boolean(minDate) && isBefore(previousMonthLastDay, minDate)
                }
                className="rdp-base-navbar-arrow"
                onClick={previousHandler}
                aria-label={intl.formatMessage(messages.previousMonthAriaLabel)}
              >
                {ArrowLeftSvg}
              </IconButton>
            ) : (
              <div className="rdp-base-navbar-empty-icon" />
            )}

            <span className="rdp-base-navbar-month-title">
              {formatMonthTitle(addMonths(month, index))}
            </span>

            {index === numberOfMonths$ - 1 ? (
              <IconButton
                disabled={Boolean(maxDate) && isAfter(nextMonth, maxDate)}
                className="rdp-base-navbar-arrow"
                onClick={nextHandler}
                aria-label={intl.formatMessage(messages.nextMonthAriaLabel)}
              >
                {ArrowRightSvg}
              </IconButton>
            ) : (
              <div className="rdp-base-navbar-empty-icon" />
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default NavBarElement;
