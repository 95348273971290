import * as R from "ramda";
import * as React from "react";
import { merge, ReplaySubject, Subject } from "rxjs";
import { map } from "rxjs/operators";

export type Index = string;
export type GetRecords<T> = () => T[];
export type GetRecord<T> = (index: Index) => T | undefined;
export type RemoveRecord = (index: Index) => void;
export type RemoveRecords = (indexes: Index[]) => void;
export type AddRecord<T> = (record: T) => void;
export type AddRecords<T> = (records: T[]) => void;
export type RecordIndex<T> = (record: T) => Index;
export type UpdateRecord<T> = (
  recordIndex: Index,
  update: (record: T) => T
) => void;

type Clear = () => void;
type Reset = () => void;

interface StorageActions<T> {
  removeRecord: RemoveRecord;
  removeRecords: RemoveRecords;
  addRecord: AddRecord<T>;
  addRecords: AddRecords<T>;
  updateRecord: UpdateRecord<T>;
  clear: Clear;
  reset: Reset;
}

interface StorageSelectors<T> {
  getRecord: GetRecord<T>;
  getRecords: GetRecords<T>;
  recordIndex: RecordIndex<T>;
}

export interface StorageController<T>
  extends StorageActions<T>,
    StorageSelectors<T> {}

interface Props<T> {
  initialRecords?: T[];
  recordIndex?: RecordIndex<T>;
}

export const useStorage = <T>({
  initialRecords = [],
  recordIndex = R.propOr("", "id"),
}: Props<T>): StorageController<T> => {
  // Here below we use ReplaySubject in order to have the possibility to add records before observers are set and then handle them.
  // There are also some limitations that we need to set not to save events forever.
  const inputs = React.useMemo(
    () => ({
      removeRecord: new Subject<Index>(),
      removeRecords: new Subject<Index[]>(),
      addRecord: new ReplaySubject<T>(100, 1000),
      addRecords: new ReplaySubject<T[]>(100, 1000),
      updateRecord: new ReplaySubject<{
        index: Index;
        update: (record: T) => T;
      }>(100, 1000),
      clear: new Subject<void>(),
      reset: new Subject<void>(),
    }),
    []
  );

  const initial = React.useMemo(
    () => R.indexBy(recordIndex, initialRecords),
    []
  );

  const [records$, setRecords$] = React.useState<Record<Index, T>>(initial);

  React.useLayoutEffect(() => {
    const subscription = merge(
      merge(
        inputs.addRecord.pipe(map((record) => [record])),
        inputs.addRecords
      ).pipe(
        map((records) => R.mergeLeft(R.pipe(R.indexBy(recordIndex))(records)))
      ),
      inputs.removeRecord.pipe(map((index) => R.dissoc(index))),
      inputs.removeRecords.pipe(map((indexes) => R.omit(indexes))),
      inputs.clear.pipe(map(() => R.always({}))),
      inputs.reset.pipe(map(() => R.always(initial))),
      inputs.updateRecord.pipe(
        map(({ index, update }) => (records: Record<Index, T>) => {
          const record = R.prop(index, records);
          if (!record) {
            return records;
          }

          const record$ = update(record);

          return R.pipe(
            R.dissoc(index),
            R.assoc(recordIndex(record$), record$)
          )(records);
        })
      )
    ).subscribe(setRecords$);

    return () => subscription.unsubscribe();
  }, []);

  const getRecord: GetRecord<T> = React.useCallback(
    (index) => R.prop(index, records$),
    [records$]
  );

  const getRecords: GetRecords<T> = React.useCallback(
    () => R.values(records$),
    [records$]
  );

  return React.useMemo<StorageController<T>>(
    () => ({
      recordIndex,
      reset: inputs.reset.next.bind(inputs.reset),
      clear: inputs.clear.next.bind(inputs.clear),
      addRecords: inputs.addRecords.next.bind(inputs.addRecords),
      removeRecord: inputs.removeRecord.next.bind(inputs.removeRecord),
      removeRecords: inputs.removeRecords.next.bind(inputs.removeRecords),
      addRecord: inputs.addRecord.next.bind(inputs.addRecord),
      updateRecord: (index, update) =>
        inputs.updateRecord.next({ index, update }),
      getRecords,
      getRecord,
    }),
    [getRecords, getRecord]
  );
};
