"use client";

import { useComponentState } from "@natera/material/lib/hooks";
import { Label } from "@natera/material/lib/label";
import { DayModifiers, DateRange, Matcher } from "react-day-picker";
import { Utils } from "../";
import { DateInputMask } from "../input/inputMask";
import { BaseDayPicker, BaseDayPickerProps } from "./base";

import classnames from "classnames";
import * as R from "ramda";
import React from "react";
import { defineMessages, useIntl } from "react-intl";

import "./../date.scss";

export interface DayRangePickerProps extends BaseDayPickerProps {
  range?: DateRange;
  defaultRange?: DateRange;
  onDateRangeChange?: (date: DateRange | undefined) => void;
  header?: boolean;
  enableOutsideDaysClick?: boolean;
  defaultMonthYear?: Date;
}

export const messages = defineMessages({
  from: {
    id: "material.range.from",
    defaultMessage: "From",
  },
  to: {
    id: "material.range.to",
    defaultMessage: "To",
  },
});

const modifiersClassNames = {
  start: "rdp-day--start",
  end: "rdp-day--end",
  enterTo: "rdp-day--enterTo",
  enterToStart: "rdp-day--enterToStart",
  enterToEnd: "rdp-day--enterToEnd",
  startReady: "rdp-day--startReady",
  endReady: "rdp-day--endReady",
  firstOfMonth: "rdp-day--firstOfMonth",
  lastOfMonth: "rdp-day--endOfMonth",
  enterStart: "rdp-day--enterStart",
  enterEnd: "rdp-day--enterEnd",
};

export const DayRangePicker: React.FunctionComponent<DayRangePickerProps> = ({
  minDate,
  maxDate,
  range,
  defaultRange,
  onDateRangeChange = R.always(undefined),
  className,
  header = false,
  showOutsideDays = false,
  enableOutsideDaysClick = false,
  numberOfMonths = 1,
  defaultMonthYear = new Date(),
  ...props
}) => {
  const [currentMonth, setCurrentMonth] = React.useState<Date | undefined>(
    new Date(defaultMonthYear)
  );
  const [range$, setRange$] = useComponentState<Partial<DateRange>>({
    controlledValue: range,
    defaultValue: defaultRange,
    onChange: onDateRangeChange,
  });
  const intl = useIntl();
  const [enterTo, setEnterTo] = React.useState<Date>();

  const rangeIsFull = React.useMemo(
    () => Boolean(range$?.to) && Boolean(range$?.from),
    [range$?.to, range$?.from]
  );

  const singleSelectedDate = React.useMemo(() => {
    if (range$?.to && range$?.from) {
      return undefined;
    }
    if (!range$?.to && !range$?.from) {
      return undefined;
    }

    return range$?.from || range$?.to;
  }, [range$?.to, range$?.from]);

  const modifiers = {
    start: range$?.from,
    end: range$?.to,
    startReady: (date$: Date) =>
      Utils.isSameDay(date$, range$?.from) && rangeIsFull,
    endReady: (date$: Date) =>
      Utils.isSameDay(date$, range$?.to) && rangeIsFull,
    enterTo: (date$: Date) => {
      return (
        Boolean(singleSelectedDate) &&
        (Utils.isSameDay(date$, enterTo) ||
          Utils.isDayBetween(date$, singleSelectedDate, enterTo))
      );
    },
    enterToStart: (date$: Date) =>
      Utils.isSameDay(date$, enterTo) &&
      Utils.isDayBefore(date$, singleSelectedDate),
    enterToEnd: (date$: Date) =>
      Utils.isSameDay(date$, enterTo) &&
      Utils.isDayAfter(date$, singleSelectedDate),
    enterStart: (date$: Date) =>
      Utils.isSameDay(date$, singleSelectedDate) &&
      Utils.isDayAfter(enterTo, date$),
    enterEnd: (date$: Date) =>
      Utils.isSameDay(date$, singleSelectedDate) &&
      Utils.isDayBefore(enterTo, date$),
    firstOfMonth: Utils.isDayFirstOfMonth,
    lastOfMonth: Utils.isDayLastOfMonth,
  } as DayModifiers;

  const onDayClick = React.useCallback(
    (
      day$: Date,
      { disabled, outside }: DayModifiers,
      e: React.MouseEvent<HTMLButtonElement>
    ) => {
      if (outside && showOutsideDays && enableOutsideDaysClick) {
        setCurrentMonth(day$);
        e.currentTarget.blur();
      }
      if (disabled || outside) {
        return;
      }

      setRange$((oldRange) =>
        Utils.addDayToRange(
          day$,
          (rangeIsFull || !oldRange ? {} : oldRange) as DateRange
        )
      );
    },
    [onDateRangeChange, rangeIsFull]
  );

  const onDayMouseEnter = (date: Date, { disabled, outside }: DayModifiers) =>
    setEnterTo(disabled || outside ? undefined : date);
  const onMouseLeave = () => {
    setEnterTo(undefined);
  };

  const setFrom = React.useCallback(
    (day$?: Date | undefined) =>
      setRange$((oldRange) => ({
        from: day$,
        to: Utils.isDayAfter(day$, oldRange?.to) ? undefined : oldRange?.to,
      })),
    []
  );

  const setTo = React.useCallback(
    (day$?: Date | undefined) =>
      setRange$((oldRange) => ({
        from: Utils.isDayBefore(day$, oldRange?.from)
          ? undefined
          : oldRange?.from,
        to: day$,
      })),
    []
  );

  const currentRange = React.useMemo(() => {
    const { from, to } = range$ || {};
    if (from && to) {
      return {
        from,
        to,
      };
    }

    if (from) {
      return { from } as DateRange;
    }

    if (to) {
      return { to } as DateRange;
    }

    return undefined;
  }, [range$]);

  return (
    <>
      {header && (
        <div className="rdp-range-header">
          <div>
            <Label>{intl.formatMessage(messages.from)}</Label>
            <DateInputMask
              minDate={minDate}
              maxDate={maxDate}
              date={range$?.from}
              onMaskComplete={setFrom}
            />
          </div>
          <div>
            <Label>{intl.formatMessage(messages.to)}</Label>
            <DateInputMask
              minDate={minDate}
              maxDate={maxDate}
              date={range$?.to}
              onMaskComplete={setTo}
            />
          </div>
        </div>
      )}
      <div onMouseLeave={onMouseLeave}>
        <BaseDayPicker
          className={classnames(className, "rdp-range")}
          numberOfMonths={numberOfMonths}
          showOutsideDays={showOutsideDays}
          enableOutsideDaysClick={enableOutsideDaysClick}
          minDate={minDate}
          maxDate={maxDate}
          selected={[range$?.from, currentRange] as Matcher[]}
          modifiers={modifiers}
          modifiersClassNames={modifiersClassNames}
          onDayClick={onDayClick}
          onDayMouseEnter={onDayMouseEnter}
          defaultMonthYear={currentMonth}
          {...props}
        />
      </div>
    </>
  );
};

export default DayRangePicker;
