import classnames from "classnames";
import * as React from "react";

interface Props extends Omit<React.HTMLProps<HTMLDivElement>, "title"> {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
}

const Error: React.FC<Props> = ({
  title,
  subtitle,
  className,
  children,
  ...rest
}) => (
  <div {...rest} className={classnames("application-error", className)}>
    <h1>{title}</h1>
    {subtitle && <h3>{subtitle}</h3>}
    {children && <div className="description">{children}</div>}
  </div>
);

export default Error;
