import * as React from "react";

export interface KeyboardController {
  onKeydownHandler: (e: React.KeyboardEvent) => void;
}

type KeyboardEnterController = (callback: () => void) => KeyboardController;

export const useKeyboardEnterController: KeyboardEnterController = (
  callback
) => {
  const onKeydownHandler = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" || e.key === " " || e.key === "Spacebar") {
      callback();
    }
  };

  return {
    onKeydownHandler,
  };
};
