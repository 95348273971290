import {
  HttpError,
  HttpValidationError,
  ValidationError,
} from "@natera/platform/lib/service";
import * as R from "ramda";
import * as React from "react";

export type SetError = (error: Error | string | undefined) => void;
export type GetError = () => Error | undefined;
export type GetErrorMessage = () => string | undefined;
export type SetValidationError = (name: string, message: string) => void;
export type SetValidationErrors = (errors: ValidationError[]) => void;
export type SetValidationErrorMap = (errorMap: ErrorMap) => void;
export type GetValidationError = (...names: string[]) => string | undefined;
export type GetValidationErrorAtIndex = (
  ...names: string[]
) => (index: number) => string | undefined;
export type ClearValidationError = (...names: string[]) => void;
export type ClearErrors = () => void;
export type HasError = () => boolean;

export interface ErrorController {
  setError: SetError;
  getError: GetError;
  hasError: HasError;
  getErrorMessage: GetErrorMessage;
  setValidationError: SetValidationError;
  clearValidationError: ClearValidationError;
  setValidationErrors: SetValidationErrors;
  setValidationErrorMap: SetValidationErrorMap;
  getValidationError: GetValidationError;
  getValidationErrorAtIndex: GetValidationErrorAtIndex;
  clearErrors: ClearErrors;
}

export const defaultErrorController: ErrorController = {
  setError: R.always(undefined),
  getError: R.always(undefined),
  hasError: R.always(false),
  getErrorMessage: R.always(undefined),
  setValidationError: R.always(undefined),
  clearValidationError: R.always(undefined),
  setValidationErrors: R.always(undefined),
  setValidationErrorMap: R.always(undefined),
  getValidationError: R.always(undefined),
  getValidationErrorAtIndex: R.always(R.always(undefined)),
  clearErrors: R.always(undefined),
};

type ErrorOrErrors = ValidationError | ErrorMap;

export interface ErrorMap {
  [name: string]: ErrorOrErrors;
}

export const convertErrors = (errors: ValidationError[]): ErrorMap => {
  const level = R.indexBy(R.prop("name"), errors);

  return R.mapObjIndexed(
    (error) => (error.fields ? convertErrors(error.fields) : error),
    level
  );
};

type UseErrorController = (
  error?: Error,
  validationError?: ValidationError[]
) => ErrorController;

export const useErrorController: UseErrorController = (
  initialError,
  initialValidationError = []
) => {
  const [error$, setError$] = React.useState<Error | undefined>(initialError);
  const [validationErrors$, setValidationErrors$] = React.useState<ErrorMap>(
    convertErrors(initialValidationError)
  );

  React.useEffect(() => {
    if (
      getError() instanceof HttpValidationError &&
      R.isEmpty(validationErrors$)
    ) {
      setError$(undefined);
    }
  }, [validationErrors$]);

  const setError: SetError = (error) => {
    setValidationErrors([]);

    if (error instanceof HttpValidationError) {
      if (error.body.fields) {
        setValidationErrors(error.body.fields || []);
        setError$(error);
      } else {
        setValidationErrorMap(error.body);
        setError$(error);
      }
    } else {
      setError$(error as Error);
    }
  };

  const getError: GetError = () => error$;

  const getErrorMessage: GetErrorMessage = () => {
    if (!error$) {
      return undefined;
    }

    if (error$ instanceof HttpError) {
      return error$.message;
    }

    if (error$ instanceof Error) {
      return error$.message;
    }

    return String(error$);
  };

  const setValidationErrors: SetValidationErrors = (errors) => {
    setValidationErrors$(convertErrors(errors));
  };

  const setValidationErrorMap: SetValidationErrorMap = (errorMap) => {
    setValidationErrors$(errorMap);
  };

  const setValidationError: SetValidationError = (name, message) => {
    setValidationErrors$((errors) => R.assoc(name, { message }, errors));
  };

  const getValidationError: GetValidationError = (...names) => {
    const message = R.path([...names, "message"], validationErrors$);
    if (message instanceof Array) {
      return R.head(message.filter(Boolean));
    }

    return message;
  };

  const getValidationErrorAtIndex: GetValidationErrorAtIndex = (...names) => (
    index
  ) => {
    const message = R.path([...names, "message"], validationErrors$);

    if (message instanceof Array) {
      return R.prop(index, message);
    }

    return message;
  };

  const clearValidationError: ClearValidationError = (...names) => {
    setValidationErrors$((errors) => R.dissocPath(names, errors));
  };

  const clearErrors: ClearErrors = () => {
    setError$(undefined);
    setValidationErrors([]);
  };

  const hasError: HasError = React.useCallback(() => {
    return Boolean(error$);
  }, [error$]);

  const errorController: ErrorController = {
    setError,
    getError,
    hasError,
    getErrorMessage,
    setValidationErrors,
    setValidationErrorMap,
    setValidationError,
    getValidationError,
    getValidationErrorAtIndex,
    clearValidationError,
    clearErrors,
  };

  return errorController;
};
