import classnames from "classnames";
import * as React from "react";

type Props = React.HTMLProps<HTMLSpanElement>;

export const ListItemGraphic: React.FunctionComponent<Props> = ({
  children,
  className,
  ...props
}) => {
  return (
    <span
      className={classnames(className, "mdc-deprecated-list-item__graphic")}
      {...props}
    >
      {children}
    </span>
  );
};

export default ListItemGraphic;
