import { isAfter, isBefore } from "../utils";

const EMPTY_MASK = "__/__/____";
const EMPTY_MASKV2 = "MM/DD/YYYY";

export const onInputBlurChange = (
  setDay$: (date$: Date | undefined) => void,
  maskedValue: string,
  date?: Date,
  maxDate?: Date,
  minDate?: Date
): void => {
  const match = maskedValue.match("(\\d{2})/(\\d{2})/(\\d{4})");
  const dateNum = date ? date.getTime() : undefined;
  if (match) {
    const matches = match.slice(1).map((item) => Number(item));
    const [month, day$, year] = matches;
    const newDate = dateNum
      ? new Date(dateNum)
      : new Date(year, month - 1, day$);
    newDate.setFullYear(year);
    newDate.setDate(day$);
    newDate.setMonth(month - 1);

    // day 0 of next month equals the max amount of days in the current month
    // month 12 in this case is valid
    const maxDateInCurrentMonth = new Date(year, month, 0).getDate();

    const ifDayBefore =
      minDate && isBefore(newDate, minDate) ? minDate : newDate;

    const nextDate =
      maxDate && isAfter(newDate, maxDate) ? maxDate : ifDayBefore;

    if (
      month <= 12 &&
      day$ <= maxDateInCurrentMonth &&
      nextDate.getTime() !== dateNum
    ) {
      setDay$(nextDate);
      return;
    }
  } else if (maskedValue === EMPTY_MASK || maskedValue === EMPTY_MASKV2) {
    setDay$(undefined);
  }

  setDay$(date);
};
