import classnames from "classnames";
import * as React from "react";

export type GridRowProps = React.HTMLAttributes<HTMLDivElement>;

export const GridRow: React.FunctionComponent<GridRowProps> = ({
  children,
  className,
  ...props
}) => (
  <div {...props} className={classnames("mdc-layout-grid__inner", className)}>
    {children}
  </div>
);

export default GridRow;
