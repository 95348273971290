import { Segment, SegmentedButton } from "@natera/material/lib/button";
import { Corner, Menu, MenuController } from "@natera/material/lib/menu";
import downIcon from "@natera/platform/assets/svg/icons/arrow_down.svg";
import upIcon from "@natera/platform/assets/svg/icons/arrow_up.svg";
import classnames from "classnames";
import * as React from "react";
import "./splitButton.scss";
import { defineMessages, useIntl } from "react-intl";

export interface Props
  extends Omit<React.HTMLProps<HTMLDivElement>, "onClick"> {
  className?: string;
  menu: React.ReactNode;
  icon?: string;
  children: React.ReactNode;
  menuCorner?: Corner;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const messages = defineMessages({
  ariaLabelOpenMenu: {
    id: "platform.splitButton.ariaLabelOpenMenu",
    defaultMessage: "Open menu",
    description: "Open menu",
  },
});

export const SplitButton: React.FC<Props> = ({
  className,
  children,
  icon,
  menu,
  menuCorner = Corner.BOTTOM_LEFT,
  onClick,
  ...rest
}) => {
  const intl = useIntl();

  return (
    <SegmentedButton
      {...rest}
      className={classnames(className, "split-button")}
    >
      <Menu menu={menu} corner={menuCorner}>
        {(controller: MenuController) => {
          const isOpen = controller.isOpen();

          return (
            <>
              <Segment
                icon={icon}
                onClick={onClick}
                className="split-button__button"
              >
                {children}
              </Segment>

              <Segment
                checked={isOpen}
                icon={isOpen ? upIcon : downIcon}
                className="split-button__actions"
                onClick={controller.toggleMenu}
                aria-label={intl.formatMessage(messages.ariaLabelOpenMenu)}
              />
            </>
          );
        }}
      </Menu>
    </SegmentedButton>
  );
};

export default SplitButton;
