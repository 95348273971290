import classnames from "classnames";
import * as React from "react";
import "./chip.scss";

export interface ChipProps extends React.ButtonHTMLAttributes<HTMLDivElement> {
  outlined?: boolean;
  rounded?: boolean;
  size?: "small";
  selected?: boolean;
  info?: boolean;
  success?: boolean;
  error?: boolean;
  warning?: boolean;
  disabled?: boolean;
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
}

export const Chip = React.forwardRef<HTMLDivElement, ChipProps>(
  (
    {
      children,
      outlined,
      rounded,
      size,
      selected = false,
      disabled = false,
      className,
      info = false,
      success = false,
      error = false,
      warning = false,
      leadingIcon,
      trailingIcon,
      tabIndex = 0,
      ...props
    },
    ref: React.RefObject<HTMLDivElement>
  ) => (
    <div
      className={classnames("mdc-evolution-chip", className, {
        "mdc-evolution-chip--outlined": outlined,
        "mdc-evolution-chip--rounded": rounded,
        "mdc-evolution-chip--small": size === "small",
        "mdc-evolution-chip--selected": selected,
        "mdc-evolution-chip--disabled": disabled,
        "mdc-evolution-chip--info": info,
        "mdc-evolution-chip--success": success,
        "mdc-evolution-chip--error": error,
        "mdc-evolution-chip--warning": warning,
        "mdc-evolution-chip--deletable": true,
      })}
      {...props}
    >
      <div
        tabIndex={tabIndex}
        ref={ref}
        className="mdc-evolution-chip__action mdc-evolution-chip__action--primary"
      >
        <div className="mdc-evolution-chip__ripple mdc-evolution-chip__ripple--primary" />
        {leadingIcon && (
          <i className="material-icons mdc-evolution-chip__icon mdc-evolution-chip__icon--leading">
            {leadingIcon}
          </i>
        )}
        <span className="mdc-evolution-chip__text-label">{children}</span>
        {trailingIcon && (
          <span role="gridcell">
            <i
              className="material-icons mdc-evolution-chip__icon mdc-evolution-chip__icon--trailing"
              tabIndex={-1}
            >
              {trailingIcon}
            </i>
          </span>
        )}
      </div>
    </div>
  )
);

export default Chip;
