import * as React from "react";
import {
  AttachmentProvider,
  AttachmentProviderProps,
} from "./attachmentContext";
import { AttachmentField, AttachmentFieldProps } from "./attachmentField";

interface Props extends AttachmentProviderProps, AttachmentFieldProps {}

export const Attachment: React.FunctionComponent<Props> = ({
  defaultAttachments,
  loadFileMetadata,
  loadFile,
  uploadFile,
  removeFile,
  ...props
}) => (
  <AttachmentProvider
    defaultAttachments={defaultAttachments}
    loadFileMetadata={loadFileMetadata}
    loadFile={loadFile}
    uploadFile={uploadFile}
    removeFile={removeFile}
  >
    <AttachmentField {...props} />
  </AttachmentProvider>
);

export default Attachment;
