import * as R from "ramda";
import * as React from "react";
import { useStorage } from "../useStorage";
import { Proxy, ProxyProps, useStorageProxy } from "../useStorageProxy";

export type FilterName = string;
export type FilterValue = string;

export interface FilterAPI {
  getValue: () => FilterValue | undefined;
  setValue: (value: FilterValue) => void;
  clearValue: () => void;
}

export interface FiltersController<K> {
  hasFilters: () => boolean;
  getFiltersCount: () => number;
  clearFilters: () => void;
  getFilters: () => Record<string, FilterValue>;
  getFilterAPI: (filterName: K) => FilterAPI;
}

interface FiltersSettings {
  [key: string]: FilterValue;
}

interface FilterRecord {
  id: string;
  value: FilterValue;
}

export const useFilters = <K extends string>(
  initFilters: FiltersSettings,
  proxy?: <T>(proxyProps: ProxyProps<T>) => Proxy<T>
): FiltersController<K> => {
  const filtersToStorageSettings = React.useCallback(
    (filtersSettings: FiltersSettings) =>
      R.pipe(
        R.toPairs,
        R.map((filterSettings) => ({
          id: filterSettings[0],
          value: typeof filterSettings[1] === "string" ? filterSettings[1] : "",
        }))
      )(filtersSettings),
    []
  );

  const serializeFilters = React.useCallback((records: FilterRecord[]) => {
    const sortedRecords = R.sortWith<FilterRecord>([R.ascend(R.prop("id"))])(
      records
    );
    return sortedRecords.reduce(
      (acc, filterRecord) => ({
        ...acc,
        [filterRecord.id]: filterRecord.value,
      }),
      {}
    );
  }, []);

  const initialRecords: FilterRecord[] = React.useMemo(
    () =>
      filtersToStorageSettings(initFilters).filter(
        (initFilter) => !R.isEmpty(initFilter.value)
      ),
    []
  );

  const filtersStorage = proxy
    ? useStorageProxy<FilterRecord>({
        initialRecords,
        serialize: serializeFilters,
        deserialize: filtersToStorageSettings,
        proxy,
      })
    : useStorage({ initialRecords });

  const getFilterAPI = React.useCallback(
    (filterName: K): FilterAPI => {
      const getValue = () => filtersStorage.getRecord(filterName)?.value || "";
      const setValue = (newValue: FilterValue) => {
        if (newValue === "") {
          clearValue();
          return;
        }
        if (!filtersStorage.getRecord(filterName)?.value) {
          filtersStorage.addRecord({ id: filterName, value: newValue });
        }
        if (newValue !== getValue()) {
          filtersStorage.updateRecord(filterName, (filterRecord) => ({
            ...filterRecord,
            value: newValue,
          }));
        }
      };
      const clearValue = () => filtersStorage.removeRecord(filterName);

      return {
        getValue,
        setValue,
        clearValue,
      };
    },
    [
      filtersStorage.getRecord,
      filtersStorage.removeRecord,
      filtersStorage.updateRecord,
    ]
  );

  const filters: Record<string, FilterValue> = React.useMemo(
    () => serializeFilters(filtersStorage.getRecords()),
    [filtersStorage.getRecords]
  );

  const hasFilters = React.useCallback(
    () => filtersStorage.getRecords().length > 0,
    [filtersStorage.getRecords]
  );

  const getFiltersCount = React.useCallback(
    () => filtersStorage.getRecords().length,
    [filtersStorage.getRecords]
  );

  const clearFilters = React.useCallback(() => {
    filtersStorage.clear();
  }, []);

  return React.useMemo<FiltersController<K>>(
    () => ({
      getFilters: () => filters,
      getFilterAPI,
      hasFilters,
      getFiltersCount,
      clearFilters,
    }),
    [filters]
  );
};
