import {
  CollectionController,
  defaultCollectionController,
  useCollection,
} from "@natera/platform/lib/hooks";
import { HttpCollection } from "@natera/platform/lib/service";
import * as R from "ramda";
import * as React from "react";

export type GetOptionsCollection<T> = () => CollectionController<T>;
export type GetPageLimit = () => number;
export type GetInputValue = () => string;
export type IsCustomValueAllowed = () => boolean;
export type Reset = () => void;
export type SetInputValue = React.Dispatch<React.SetStateAction<string>>;
export type OnSelect<T> = (option: T | undefined) => void;
export type Clear = () => void;
export type ForceValue = () => void;

export type DisplayValue<T> = (option: T) => string;
export type IsOptionDisabled<T> = (item: T) => boolean;

export interface TypeAheadController<T> {
  getOptionsCollection: GetOptionsCollection<T>;
  getPageLimit: GetPageLimit;
  getInputValue: GetInputValue;
  setInputValue: SetInputValue;
  isCustomValueAllowed: IsCustomValueAllowed;
  selectOption: OnSelect<T>;
  forceValue: ForceValue;
  reset: Reset;
  clear: Clear;
  isOptionDisabled: IsOptionDisabled<T>;
}

export interface TypeAheadContextProps<T> {
  getOptions: (value: string, size: number) => Promise<T[]>;
  pageLimit?: number;
  delayTime?: number;
  allowCustomValue?: boolean;
  selectOnFocus?: boolean;
  optionFactory?: (input: string) => T;
  displayValue?: DisplayValue<T>;
  selectedOption?: T | undefined;
  onSelect?: OnSelect<T>;
  autoSelect?: boolean;
  clearOnSelect?: boolean;
  isOptionDisabled?: IsOptionDisabled<T>;
}

const DEFAULT_PAGE_LIMIT = 100;
const DEFAULT_DELAY_TIME = 75;

const TypeAheadContext = React.createContext<TypeAheadController<unknown>>({
  getOptionsCollection: R.always(defaultCollectionController),
  getPageLimit: R.always(DEFAULT_PAGE_LIMIT),
  getInputValue: R.always(""),
  setInputValue: R.always(undefined),
  isCustomValueAllowed: R.always(false),
  selectOption: R.always(undefined),
  reset: R.always(undefined),
  clear: R.always(undefined),
  forceValue: R.always(undefined),
  isOptionDisabled: R.always(false),
});

export function getTypeAheadContext<T extends object>() {
  return TypeAheadContext as React.Context<TypeAheadController<T>>;
}

export const TypeAheadProvider = <T extends object>({
  getOptions,
  pageLimit = DEFAULT_PAGE_LIMIT,
  delayTime = DEFAULT_DELAY_TIME,
  allowCustomValue = false,
  selectOnFocus = false,
  optionFactory,
  children,
  displayValue = R.always(""),
  selectedOption,
  onSelect = R.always(undefined),
  clearOnSelect = false,
  isOptionDisabled = R.always(false),
  autoSelect = false,
}: React.PropsWithChildren<
  TypeAheadContextProps<T>
>): React.ReactElement | null => {
  const isMounted = React.useRef(false);
  const getDisplayValue: DisplayValue<T | undefined> = (option) => {
    if (!option) {
      return "";
    }

    return displayValue(option);
  };

  const defaultInputValue = getDisplayValue(selectedOption);
  const [inputValue$, setInputValue$] = React.useState<string>(
    defaultInputValue
  );

  const optionsCollection = useCollection({
    load: async () => {
      const results = selectOnFocus
        ? await getOptions("", pageLimit)
        : await getOptions(inputValue$, pageLimit);

      if (allowCustomValue && inputValue$ && optionFactory) {
        const hasValue = results.some(
          (option) => displayValue(option) === inputValue$
        );

        if (!hasValue) {
          results.unshift(optionFactory(inputValue$));
        }
      }

      return new HttpCollection(results, results.length);
    },
    delayTime,
  });

  const clear: Clear = () => {
    optionsCollection.getResource().clear();
    setInputValue$("");
  };

  const reset: Reset = () => {
    setInputValue$(getDisplayValue(selectedOption));
  };

  const selectOption: OnSelect<T> = (option) => {
    onSelect(option);

    if (clearOnSelect) {
      clear();
    }
  };

  const forceValue: ForceValue = () => {
    if (!R.isNil(selectedOption)) {
      reset();
      return;
    }

    if (allowCustomValue && optionFactory && inputValue$) {
      if (autoSelect) {
        setInputValue$(getDisplayValue(optionFactory(inputValue$)));
      } else {
        selectOption(optionFactory(inputValue$));
      }
      return;
    }

    clear();
  };

  React.useEffect(() => {
    if (isMounted.current) {
      optionsCollection.load();
    } else {
      isMounted.current = true;
    }
  }, [inputValue$]);

  React.useEffect(() => {
    reset();
  }, [selectedOption]);

  const controller: TypeAheadController<T> = {
    getOptionsCollection: R.always(optionsCollection),
    getPageLimit: R.always(pageLimit),
    getInputValue: R.always(inputValue$),
    setInputValue: setInputValue$,
    isCustomValueAllowed: R.always(allowCustomValue),
    isOptionDisabled,
    forceValue,
    selectOption,
    reset,
    clear,
  };

  return (
    <TypeAheadContext.Provider value={controller}>
      {children}
    </TypeAheadContext.Provider>
  );
};
