import Card from "@natera/material/lib/card";
import { LinearProgress } from "@natera/material/lib/progress";
import classnames from "classnames";
import React from "react";
import { StepperContext } from ".";

interface Props {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}

const StepperContent: React.FunctionComponent<Props> = ({
  title,
  subtitle,
  children,
  className,
  ...props
}) => {
  const { isLoading } = React.useContext(StepperContext);
  return (
    <Card {...props} className={classnames("stepper-content", className)}>
      {isLoading() && <LinearProgress className="stepper-content__progress" />}
      {title && (
        <div className={classnames("stepper-content__title", className)}>
          {title}
        </div>
      )}
      {subtitle && (
        <div className={classnames("stepper-content__subtitle", className)}>
          {subtitle}
        </div>
      )}
      {children}
    </Card>
  );
};

export default StepperContent;
