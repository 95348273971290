"use client";

import * as React from "react";
import classnames from "classnames";
import Svg from "@natera/material/lib/svg";
import "./segmentedButton.scss";

export interface SegmentProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  singleSelect?: boolean;
  children?: React.ReactNode;
  checked?: boolean;
  icon?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export interface SegmentedButtonProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;
  singleSelect?: boolean;
  children: React.ReactNode;
}

const SegmentedButtonContex = React.createContext<{ disabled?: boolean }>({
  disabled: undefined,
});

export const Segment: React.FC<SegmentProps> = ({
  className,
  singleSelect,
  children,
  checked,
  icon,
  onClick = () => void 0,
  ...props
}) => {
  const { disabled } = React.useContext(SegmentedButtonContex);

  return (
    <button
      onClick={onClick}
      role={singleSelect ? "radio" : undefined}
      aria-pressed={singleSelect ? undefined : checked}
      aria-checked={singleSelect ? checked : undefined}
      className={classnames(className, "mdc-segmented-button__segment", {
        "mdc-segmented-button__segment--selected": checked,
      })}
      {...props}
      disabled={props.disabled || disabled}
    >
      <div className="mdc-segmented-button__ripple"></div>
      {icon && (
        <Svg
          className="material-icons mdc-segmented-button__icon segmented-button__icon"
          content={icon}
        />
      )}

      {children && (
        <div className="mdc-segmented-button__label">{children}</div>
      )}
    </button>
  );
};

export const SegmentedButton: React.FC<SegmentedButtonProps> = ({
  className,
  singleSelect,
  children,
  disabled,
  ...rest
}) => {
  const controller = React.useMemo(() => ({ disabled }), [disabled]);

  return (
    <div
      {...rest}
      className={classnames(className, "mdc-segmented-button", {
        "mdc-segmented-button--single-select": singleSelect,
        "mdc-segmented-button--disabled": disabled,
      })}
      role={singleSelect ? "radiogroup" : "group"}
    >
      <SegmentedButtonContex.Provider value={controller}>
        {children}
      </SegmentedButtonContex.Provider>
    </div>
  );
};

export default SegmentedButton;
