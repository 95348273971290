"use client";

import Label from "@natera/material/lib/label";
import classnames from "classnames";
import * as React from "react";

import "./switch.scss";

export interface SwitchProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: React.ReactNode;
  leftLabel?: React.ReactNode;
  checked?: boolean;
  variant?: "v2" | "v3";
  size?: "default" | "small";
}

export const Switch: React.FunctionComponent<SwitchProps> = ({
  className,
  label,
  leftLabel,
  id,
  defaultChecked = false,
  disabled,
  checked,
  variant = "v2",
  size = "default",
  onClick,
  ...props
}) => {
  const isControlled = React.useMemo(() => checked !== undefined, []);
  const [checked$, setChecked$] = React.useState(defaultChecked);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (!isControlled) {
      setChecked$(!checked$);
    }
    if (onClick) {
      onClick(e);
    }
  };

  const isSelected = isControlled ? checked : checked$;

  return (
    <div
      className={classnames("natera-switch", {
        "natera-switch--v3": variant === "v3",
        "natera-switch--small": size === "small",
      })}
    >
      {leftLabel && <Label htmlFor={id}>{leftLabel}</Label>}
      <button
        id={id}
        className={classnames("mdc-switch", className, {
          "mdc-switch--selected": isSelected,
          "mdc-switch--unselected": !isSelected,
        })}
        type="button"
        role="switch"
        onClick={handleClick}
        disabled={disabled}
        aria-checked={isControlled ? checked : checked$}
        {...props}
      >
        <div className="mdc-switch__track"></div>
        <div className="mdc-switch__handle-track">
          <div className="mdc-switch__handle">
            <div className="mdc-switch__shadow">
              <div className="mdc-elevation-overlay"></div>
            </div>
            <div className="mdc-switch__ripple"></div>
          </div>
        </div>
      </button>
      {label && <Label htmlFor={id}>{label}</Label>}
    </div>
  );
};

Switch.displayName = "SwitchMaterialComponent";

export default Switch;
