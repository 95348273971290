import { useStorage } from "@natera/platform/lib/hooks";
import * as R from "ramda";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";

export type AttachmentId = number | string;
export type FileMetadata = Pick<File, "name" | "size">;

export interface Attachment {
  id: AttachmentId;
  isLoading?: boolean;
  file?: File | FileMetadata;
  error?: string;
}

type LoadAttachment = (attachmentId: AttachmentId) => void;
type GetAttachment = (attachmentId: AttachmentId) => Attachment | undefined;
type ClearAttachment = (attachmentId: AttachmentId) => void;
type RemoveAttachment = (attachmentId: AttachmentId) => void;
type CreateAttachment = (file: File) => void;
type DownloadAttachment = (attachmentId: AttachmentId) => void;
type GetAttachments = () => Attachment[];
type AddAttachment = (attachment: Attachment) => void;
type HasAttachment = (attachment: Attachment) => boolean;

export interface AttachmentController {
  loadAttachment: LoadAttachment;
  getAttachement: GetAttachment;
  clearAttachment: ClearAttachment;
  removeAttachment: RemoveAttachment;
  createAttachment: CreateAttachment;
  downloadAttachment: DownloadAttachment;
  getAttachments: GetAttachments;
  addAttachment: AddAttachment;
  hasAttachment: HasAttachment;
}

export const AttachmentContext = React.createContext<AttachmentController>({
  loadAttachment: R.always(undefined),
  getAttachement: R.always(undefined),
  clearAttachment: R.always(undefined),
  removeAttachment: R.always(undefined),
  createAttachment: R.always(undefined),
  downloadAttachment: R.always(undefined),
  getAttachments: R.always([]),
  addAttachment: R.always(undefined),
  hasAttachment: R.always(false),
});

export interface AttachmentProviderProps {
  defaultAttachments?: Attachment[];
  uploadFile: (file: File) => Promise<AttachmentId>;
  loadFileMetadata?: (attachmentId: AttachmentId) => Promise<FileMetadata>;
  loadFile?: (attachmentId: AttachmentId) => Promise<File>;
  removeFile?: (attachmentId: AttachmentId) => Promise<void>;
  menu?: React.ReactNode;
  children?: React.ReactNode;
}

export const AttachmentProvider: React.FunctionComponent<AttachmentProviderProps> = ({
  defaultAttachments = [],
  loadFileMetadata,
  uploadFile,
  removeFile,
  children,
}) => {
  const attachmentStorage = useStorage<Attachment>({
    initialRecords: defaultAttachments,
  });
  const [attachments$, setAttachments$] = React.useState<AttachmentId[]>(
    R.map(attachmentStorage.recordIndex, defaultAttachments)
  );

  React.useEffect(() => {
    attachments$.forEach(loadAttachment);
  }, []);

  const loadAttachment: LoadAttachment = async (attachmentId) => {
    if (!loadFileMetadata) {
      return undefined;
    }

    attachmentStorage.addRecord({
      id: attachmentId,
      isLoading: true,
    });

    try {
      const file = await loadFileMetadata(attachmentId);
      attachmentStorage.addRecord({
        id: attachmentId,
        isLoading: false,
        file,
      });
    } catch (error) {
      attachmentStorage.addRecord({
        id: attachmentId,
        isLoading: false,
        error: String(error),
      });
    }
  };

  const getAttachement: GetAttachment = attachmentStorage.getRecord;
  const clearAttachment: ClearAttachment = (attachmentId) => {
    attachmentStorage.removeRecord(String(attachmentId));
    setAttachments$((attachments) =>
      R.reject(R.equals(attachmentId), attachments)
    );
  };

  const removeAttachment: RemoveAttachment = async (attachmentId) => {
    try {
      if (removeFile) {
        attachmentStorage.updateRecord(String(attachmentId), (record) => ({
          ...record,
          isLoading: true,
        }));
        await removeFile(attachmentId);
      }
      clearAttachment(attachmentId);
    } catch (error) {
      attachmentStorage.updateRecord(String(attachmentId), (record) => ({
        ...record,
        isLoading: false,
        error: String(error),
      }));
    }
  };

  const createAttachment: CreateAttachment = async (file) => {
    const uuid: AttachmentId = uuidv4();

    try {
      attachmentStorage.addRecord({
        id: uuid,
        isLoading: true,
        file,
      });
      setAttachments$((attachments) => R.prepend(uuid, attachments));

      const attachmentId = await uploadFile(file);
      setAttachments$(R.prepend(attachmentId));
      attachmentStorage.updateRecord(uuid, (attachment) => ({
        ...attachment,
        id: attachmentId,
        isLoading: false,
      }));
    } catch (error) {
      attachmentStorage.updateRecord(uuid, (attachment) => ({
        ...attachment,
        isLoading: false,
        error: String(error),
      }));
    }
  };

  const downloadAttachment: DownloadAttachment = () => {
    // TODO: implement
  };

  const isDefined = <T,>(value: T | undefined): value is T => {
    return value !== undefined;
  };

  const getAttachments: GetAttachments = () => {
    return attachments$.map(attachmentStorage.getRecord).filter(isDefined);
  };

  const addAttachment = (attachment: Attachment) => {
    setAttachments$((attachments) => [...attachments, attachment.id]);
    attachmentStorage.addRecord(attachment);
  };

  const hasAttachment = (attachment: Attachment) =>
    attachmentStorage
      .getRecords()
      .find((attachment$) => attachment$.id === attachment.id) !== undefined;

  const controller = {
    loadAttachment,
    getAttachement,
    clearAttachment,
    removeAttachment,
    createAttachment,
    downloadAttachment,
    getAttachments,
    addAttachment,
    hasAttachment,
  };

  return (
    <AttachmentContext.Provider value={controller}>
      {children}
    </AttachmentContext.Provider>
  );
};

export default AttachmentProvider;
