import * as qs from "query-string";
import * as R from "ramda";
import { Observable, Subject, throwError } from "rxjs";
import { ajax, AjaxRequest } from "rxjs/ajax";
import { catchError } from "rxjs/operators";
import { ConfigServiceInterface } from "../config";
import {
  ConfigSetterInterface,
  RuntimeConfigurationServiceInterface,
} from "../runtimeConfiguration";
import { OktaIdentity, SessionServiceInterface } from "../session";
import { HttpError, HttpValidationError } from "./error";
import HttpCollection from "./httpCollection";

export type SortOrder = "ASC" | "DESC";

export const enum METHOD {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

const MIME_JSON = "application/json";
const CONTENT_TYPE = "Content-Type";

export default class HttpService {
  public static getErrorObservable(): Observable<HttpError> {
    return this.errorSubject.asObservable();
  }

  protected static generateError(
    message: string,
    code: number,
    body: unknown
  ): HttpError {
    if (code === 400) {
      return new HttpValidationError(message, code, body);
    } else {
      return new HttpError(message, code, body);
    }
  }

  private static errorSubject: Subject<HttpError> = new Subject();

  private baseURL = "/";
  private runtimeHeadersConfig: ConfigSetterInterface;
  private options = {
    method: METHOD.GET,
  };

  constructor(
    readonly configService: ConfigServiceInterface,
    readonly sessionService: SessionServiceInterface<OktaIdentity>,
    readonly runtimeConfiguration: RuntimeConfigurationServiceInterface
  ) {
    this.baseURL = configService.getConfig("api", "basename") || this.baseURL;
    this.runtimeHeadersConfig = this.runtimeConfiguration.getConfig(
      "http",
      "headers"
    );
  }

  protected getResourceUrl(url = "", query = {}): string {
    const q = qs.stringify(R.pickBy(R.complement(R.isNil), query));
    const q$ = q ? `?${q}` : "";
    const prefix = /^https?:\/\//.test(url) ? "" : this.baseURL;
    return `${prefix}${url}${q$}`;
  }

  protected async fetch(
    url = "",
    query = {},
    { headers = {}, ...options }: RequestInit
  ) {
    const url$ = this.getResourceUrl(url, query);
    const token = await this.sessionService.getToken();
    const runtimeHeaders = this.runtimeHeadersConfig.get();

    return fetch(url$, {
      ...this.options,
      ...options,
      headers: R.pickBy(R.complement(R.isNil), {
        Authorization: token ? `Bearer ${token.accessToken}` : undefined,
        "Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
        ...runtimeHeaders,
        ...headers,
      }),
    }).then(async (res) => {
      if (!res.ok) {
        const clonedRes = res.clone();
        const contentType = clonedRes.headers.get(CONTENT_TYPE);
        const isContentJSON = contentType && R.includes(MIME_JSON, contentType);

        const data = isContentJSON
          ? await clonedRes.json()
          : await clonedRes.text();

        HttpService.errorSubject.next(
          HttpService.generateError(res.statusText, res.status, data)
        );
      }

      return this.checkAuth(res);
    });
  }

  protected fetchResource(
    url = "",
    query = {},
    { headers = {}, ...options }: RequestInit = {}
  ) {
    return this.fetch(url, query, {
      ...options,
      headers: {
        Accept: MIME_JSON,
        ...headers,
      },
    }).then(this.extractResource);
  }

  protected async fetchCollection<T = unknown>(
    url = "",
    query = {},
    { headers = {}, ...options }: RequestInit = {}
  ): Promise<HttpCollection<T>> {
    const res = await this.fetch(url, query, {
      ...options,
      ...{ headers: { Accept: MIME_JSON, ...headers } },
    });

    try {
      const data = await this.extractResource(res);
      if (!(data instanceof Array)) {
        throw new Error("Collection expected to be array");
      }
      const total = res.headers.get("total");
      return new HttpCollection<T>(
        data,
        total ? Number.parseInt(total, 10) : 0
      );
    } catch (err) {
      return new HttpCollection<T>([], 0);
    }
  }

  protected fetchFile(url = "", query = {}, options = {}): Promise<File> {
    return this.fetch(url, query, options).then(async (res) => {
      if (res.ok) {
        const contentDisposition = res.headers.get("content-disposition") || "";
        const match = contentDisposition.match(
          /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        );
        const filename = (match ? match[1] : "").replace(/"|'/g, "");

        const blob = await res.blob();
        const file = new File([blob], filename, {
          lastModified: new Date().getTime(),
          type: res.headers.get("content-type") || "",
        });

        return Promise.resolve(file);
      } else {
        throw HttpService.generateError(res.statusText, res.status, res.body);
      }
    });
  }

  protected postResource(
    url = "",
    body = {},
    { headers = {}, ...options }: RequestInit = {}
  ) {
    return this.fetchResource(
      url,
      {},
      {
        body: JSON.stringify(body),
        method: METHOD.POST,
        ...options,
        headers: {
          "Content-Type": MIME_JSON,
          ...headers,
        },
      }
    );
  }

  protected putResource(url = "", body = {}, options: RequestInit = {}) {
    return this.postResource(url, body, {
      method: METHOD.PUT,
      ...options,
    });
  }

  protected patchResource(url = "", body = {}, options: RequestInit = {}) {
    return this.postResource(url, body, {
      method: METHOD.PATCH,
      ...options,
    });
  }

  protected deleteResource(url = "", options: RequestInit = {}) {
    return this.fetch(
      url,
      {},
      {
        method: METHOD.DELETE,
        ...options,
      }
    ).then((res) => {
      if (res.ok) {
        return res;
      } else {
        throw HttpService.generateError(res.statusText, res.status, res.body);
      }
    });
  }

  protected ajax = async (url = "", request: AjaxRequest) => {
    const token = await this.sessionService.getToken();

    return ajax({
      ...request,
      url: `${this.baseURL}${url}`,
      // @ts-ignore
      headers: {
        Authorization: token ? `Bearer ${token.accessToken}` : "",
        ...request.headers,
      },
    }).pipe(
      catchError((error) => {
        const contentType = error.xhr.getResponseHeader("content-type");
        if (R.includes(MIME_JSON, contentType)) {
          const response = JSON.parse(error.response);
          return throwError(
            HttpService.generateError(response.message, error.status, response)
          );
        }

        return throwError(
          HttpService.generateError(error.response, error.status, error)
        );
      })
    );
  };

  protected getSortString = (sort: Record<string, SortOrder>): string[] => {
    return R.pipe(
      R.toPairs,
      R.map(([key, order]) => {
        if (order === "ASC") {
          return `${key},asc`;
        }
        if (order === "DESC") {
          return `${key},desc`;
        }
        return "";
      }),
      R.filter(Boolean)
    )(sort);
  };

  private async extractResource(res: Response) {
    const contentType = res.headers.get(CONTENT_TYPE);
    const isContentJSON = contentType && R.includes(MIME_JSON, contentType);

    const data = isContentJSON ? await res.json() : await res.text();
    if (!res.ok) {
      if (isContentJSON) {
        throw HttpService.generateError(data.message, res.status, data);
      } else {
        throw HttpService.generateError(res.statusText, res.status, {
          text: data,
        });
      }
    }

    if (res.status === 204) {
      return null;
    }

    return data;
  }

  private checkAuth = async (res: Response) => {
    if (!res.ok && res.status === 401) {
      await this.sessionService.clearToken();
    }

    return res;
  };
}
