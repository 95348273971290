import { FormContext } from "@natera/platform/lib/components/form";
import { MenuController } from "@natera/platform/lib/components/menu";
import classnames from "classnames";
import * as R from "ramda";
import * as React from "react";
import {
  getTypeAheadContext,
  TypeAheadContextProps,
  TypeAheadController,
  TypeAheadProvider,
} from "./typeAheadContext";
import { TypeAheadInput, TypeAheadInputProps } from "./typeAheadInput";
import { TypeAheadMenu, TypeAheadMenuProps } from "./typeAheadMenu";
import {
  TypeAheadResultList,
  TypeAheadResultListProps,
} from "./typeAheadResultList";

import "./typeAhead.scss";

type ResultListProps<T> = Pick<
  TypeAheadResultListProps<T>,
  "notFoundText" | "lastText" | "children"
>;
type InputProps = Omit<TypeAheadInputProps, "onSelect" | "children">;
type MenuProps = Pick<
  TypeAheadMenuProps,
  "dense" | "dropdownWidth" | "dropdownTwoLines"
> & {
  menuClassName?: string;
};

export interface TypeAheadProps<T extends object>
  extends ResultListProps<T>,
    TypeAheadContextProps<T>,
    InputProps,
    MenuProps {
  secondaryValue?: (option: T) => React.ReactNode;
  searchEmptyValue?: boolean;
  openOnFocus?: boolean;
}

export const TypeAhead = <T extends object>({
  id,
  className,
  secondaryValue,

  // Context Props
  selectedOption,
  displayValue,
  getOptions,
  pageLimit,
  delayTime,
  allowCustomValue,
  selectOnFocus,
  optionFactory,
  onSelect = R.always(undefined),
  clearOnSelect,
  autoSelect,
  openOnFocus = true,

  // Menu props
  dense,
  dropdownWidth,
  dropdownTwoLines,
  menuClassName,

  // ResultListProps
  lastText,
  notFoundText,
  children,

  // Input Props
  disabled,
  allowType = true,
  searchEmptyValue = false,
  ...textfiledProps
}: TypeAheadProps<T>): React.ReactElement => {
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { setFormChanged } = React.useContext(FormContext);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const optionSelectHandler = React.useCallback(
    (option: T) => {
      if (onSelect) {
        onSelect(option);
      }
      setFormChanged();
      inputRef.current?.focus();
    },
    [onSelect]
  );

  const TypeaheadContext = getTypeAheadContext<T>();

  const menuOpenHandler = (
    typeahead: TypeAheadController<T>,
    menu: MenuController
  ) => () => {
    if (searchEmptyValue || Boolean(typeahead.getInputValue())) {
      menu.openMenu();
    }

    if (selectOnFocus) {
      inputRef.current?.select();
    }
  };

  const onMenuFocus = (
    typeahead: TypeAheadController<T>,
    menu: MenuController
  ) => () => {
    if (openOnFocus && !isMenuOpen) {
      const openMenu = menuOpenHandler(typeahead, menu);
      openMenu();
      setIsMenuOpen(true);
    }
  };

  const menuCloseHandler = (menu: MenuController) => (
    event: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    const isFocusOutsideMenu = !menuRef.current?.contains(
      document.activeElement
    );
    const isClickOutsideMenu = !menuRef.current?.contains(event.relatedTarget);
    const isNextTargetMenu = event.relatedTarget?.tagName === "UL";

    if (isFocusOutsideMenu && isClickOutsideMenu && !isNextTargetMenu) {
      setIsMenuOpen(false);
      menu.closeMenu();
    }
  };

  const changeHandler = (menu: MenuController) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value) {
      menu.openMenu();
    }
  };

  const secondaryValue$ =
    secondaryValue && selectedOption && secondaryValue(selectedOption);

  return (
    <TypeAheadProvider
      selectedOption={selectedOption}
      getOptions={getOptions}
      pageLimit={pageLimit}
      delayTime={delayTime}
      allowCustomValue={allowCustomValue}
      selectOnFocus={selectOnFocus}
      optionFactory={optionFactory}
      clearOnSelect={clearOnSelect}
      onSelect={optionSelectHandler}
      displayValue={displayValue}
      autoSelect={autoSelect}
    >
      <TypeAheadMenu
        ref={menuRef}
        menuButtonRef={inputRef}
        className={menuClassName}
        dropdownTwoLines={dropdownTwoLines}
        dropdownWidth={dropdownWidth}
        dense={dense}
        anchorRef={anchorRef}
        anchorElement={(menu) => (
          <div
            ref={anchorRef}
            className={classnames(className, "typeahead", {
              "typeahead--with-meta-value": Boolean(secondaryValue$),
              "typeahead--allow-type": allowType,
            })}
          >
            <TypeaheadContext.Consumer>
              {(typeahead) => (
                <TypeAheadInput
                  ref={inputRef}
                  id={id}
                  disabled={disabled}
                  allowType={allowType}
                  onClear={menu.closeMenu}
                  onClick={menuOpenHandler(typeahead, menu)}
                  onFocus={onMenuFocus(typeahead, menu)}
                  onChange={changeHandler(menu)}
                  onBlur={menuCloseHandler(menu)}
                  {...textfiledProps}
                />
              )}
            </TypeaheadContext.Consumer>

            {Boolean(secondaryValue$) && (
              <div
                className={classnames("typeahead-secondary-value", {
                  "typeahead-secondary-value--disabled": disabled,
                })}
              >
                {secondaryValue$}
              </div>
            )}
          </div>
        )}
      >
        <TypeAheadResultList lastText={lastText} notFoundText={notFoundText}>
          {children}
        </TypeAheadResultList>
      </TypeAheadMenu>
    </TypeAheadProvider>
  );
};

export default TypeAhead;
