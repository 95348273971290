import * as React from "react";
import DisplayError from "./display";

interface State {
  hasError: boolean;
  error: Error | undefined | string;
}

interface Props extends React.HTMLProps<HTMLDivElement> {
  error?: Error;
  children?: React.ReactNode;
}

export class ErrorBoundary extends React.PureComponent<Props, State> {
  public static getDerivedStateFromError(error: Error) {
    return { error, hasError: true };
  }

  public static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    return {
      error: nextProps.error || prevState.error,
      hasError: prevState.error || Boolean(nextProps.error),
    };
  }

  public state: State = {
    error: this.props.error,
    hasError: Boolean(this.props.error),
  };

  public componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.warn(error, info);
  }

  public render() {
    const { children, ...rest } = this.props;
    const { hasError, error } = this.state;

    if (hasError) {
      return <DisplayError {...rest} error={error as Error} />;
    }

    return children;
  }
}

export default ErrorBoundary;
