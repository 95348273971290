import Chip from "@natera/material/lib/chip";
import cn from "classnames";
import React, { FC } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import "./TimeSlotPlaceholder.scss";

interface TimeSlotPlaceholderProps {
  mobile?: boolean;
  index: number;
}

export const messages = defineMessages({
  timeslotsPlaceholderMessage: {
    id: "mobilePhlebotomy.timeslots.placeholder",
    defaultMessage: "Time slot #{number}",
    description: "Timeslots placeholder message",
  },
});

const TimeSlotPlaceholder: FC<TimeSlotPlaceholderProps> = ({
  mobile,
  index,
}) => {
  return (
    <Chip
      tabIndex={-1}
      className={cn("mobile-phlebotomy__timeslots__placeholder-slot", {
        "mobile-phlebotomy__timeslots__placeholder-slot--mobile": mobile,
      })}
      key={index}
      disabled
      aria-hidden
    >
      <FormattedMessage
        {...messages.timeslotsPlaceholderMessage}
        values={{ number: index + 1 }}
      />
    </Chip>
  );
};

export default TimeSlotPlaceholder;
