"use client";

import { closest, matches } from "@material/dom/ponyfill";
import { MDCListAdapter, MDCListFoundation } from "@material/list";
import classnames from "classnames";
import * as R from "ramda";
import * as React from "react";
import "./list.scss";

export interface ListProps extends React.HTMLProps<HTMLUListElement> {
  twoLines?: boolean;
  dense?: boolean;
  singleSelection?: boolean;
}

interface ListItemController {
  getElement: () => HTMLLIElement;
  handleAction: () => void;
}

interface ListController {
  registerItem: (controller: ListItemController) => void;
}

export const ListContext = React.createContext<ListController>({
  registerItem: R.always(undefined),
});

export const List = React.forwardRef<HTMLUListElement, ListProps>(
  (
    {
      children,
      className,
      twoLines,
      dense = false,
      singleSelection = false,
      ...props
    },
    ref: React.MutableRefObject<HTMLUListElement>
  ) => {
    const listRef = React.useRef<HTMLUListElement>();
    const foundationRef = React.useRef<MDCListFoundation>();
    const itemControllersRef = React.useRef(
      new WeakMap<HTMLElement, ListItemController>()
    );

    const getDomItems = React.useCallback((): HTMLElement[] => {
      if (!listRef.current) {
        return [];
      }
      const items = listRef.current.querySelectorAll<HTMLElement>(
        `.mdc-deprecated-list-item:not([aria-hidden="true"])`
      );

      return Array.from(items);
    }, []);

    const getDomItemAtIndex = React.useCallback((index: number):
      | HTMLElement
      | undefined => {
      const items = getDomItems();

      return items[index];
    }, []);

    const listController: ListController = React.useMemo(
      () => ({
        registerItem: (itemController) => {
          itemControllersRef.current.set(
            itemController.getElement(),
            itemController
          );
        },
      }),
      []
    );

    const createAdapter = React.useCallback(
      (listElement: HTMLUListElement): MDCListAdapter => ({
        addClassForElementIndex: (index, className$) => {
          getDomItemAtIndex(index)?.classList.add(className$);
        },
        focusItemAtIndex: (index) => {
          getDomItemAtIndex(index)?.focus();
        },
        getAttributeForElementIndex: (index, attr) => {
          return getDomItemAtIndex(index)?.getAttribute(attr) || null;
        },
        getFocusedElementIndex: () => {
          return getDomItems().indexOf(document.activeElement as HTMLElement);
        },
        getListItemCount: () => getDomItems().length,
        hasCheckboxAtIndex: (index) => {
          return !!getDomItemAtIndex(index)?.querySelector(
            MDCListFoundation.strings.CHECKBOX_SELECTOR
          );
        },
        hasRadioAtIndex: (index) => {
          return !!getDomItemAtIndex(index)?.querySelector(
            MDCListFoundation.strings.RADIO_SELECTOR
          );
        },
        isCheckboxCheckedAtIndex: (index) => {
          const toggleEl = getDomItemAtIndex(index)?.querySelector<
            HTMLInputElement
          >(MDCListFoundation.strings.CHECKBOX_SELECTOR);
          return Boolean(toggleEl?.checked);
        },
        isFocusInsideList: () => {
          return listElement?.contains(document.activeElement) || false;
        },
        isRootFocused: () => document.activeElement === listElement,
        listItemAtIndexHasClass: (index, className$) => {
          return (
            getDomItemAtIndex(index)?.classList.contains(className$) || false
          );
        },
        notifyAction: (index) => {
          const domItem = getDomItemAtIndex(index);
          if (!domItem) {
            return;
          }
          const itemController = itemControllersRef.current.get(domItem);
          itemController?.handleAction();
        },
        removeClassForElementIndex: (index, className$) => {
          getDomItemAtIndex(index)?.classList.remove(className$);
        },
        setAttributeForElementIndex: (index, attr, value) => {
          getDomItemAtIndex(index)?.setAttribute(attr, value);
        },
        setCheckedCheckboxOrRadioAtIndex: (index, isChecked) => {
          const listItem = getDomItemAtIndex(index);
          const toggleEl = listItem?.querySelector<HTMLInputElement>(
            MDCListFoundation.strings.CHECKBOX_RADIO_SELECTOR
          );
          if (!toggleEl) {
            return;
          }
          toggleEl.checked = isChecked;

          const event = document.createEvent("Event");
          event.initEvent("change", true, true);
          toggleEl.dispatchEvent(event);
        },
        setTabIndexForListItemChildren: (index, tabIndexValue) => {
          const listItem = getDomItemAtIndex(index);
          const listItemChildren: Element[] = [].slice.call(
            listItem?.querySelectorAll(
              MDCListFoundation.strings.CHILD_ELEMENTS_TO_TOGGLE_TABINDEX
            )
          );

          listItemChildren.forEach((el) =>
            el.setAttribute("tabindex", tabIndexValue)
          );
        },
        getPrimaryTextAtIndex: (index) =>
          getDomItemAtIndex(index)?.textContent || "",
      }),
      []
    );

    const initFoundation = React.useCallback(() => {
      if (!listRef.current) {
        return;
      }

      if (foundationRef.current) {
        return;
      }

      const foundation = new MDCListFoundation(createAdapter(listRef.current));
      foundation.init();
      foundationRef.current = foundation;
      foundation.setVerticalOrientation(true);
      foundation.setSingleSelection(singleSelection);
    }, [singleSelection]);

    React.useEffect(() => {
      initFoundation();
      return () => {
        foundationRef.current?.destroy();
        foundationRef.current = undefined;
      };
    }, []);

    const createListRef = React.useCallback((element: HTMLUListElement) => {
      listRef.current = element;
      if (ref) {
        if (ref instanceof Function) {
          ref(element);
        } else {
          ref.current = element;
        }
      }
      initFoundation();
    }, []);

    const getListItemIndex = (evt: Event) => {
      const eventTarget = evt.target as Element;
      const nearestParent = closest(
        eventTarget,
        `.mdc-deprecated-list-item, .mdc-deprecated-list`
      );

      // Get the index of the element if it is a list item.
      if (
        nearestParent &&
        matches(nearestParent, `.mdc-deprecated-list-item`)
      ) {
        return getDomItems().indexOf(nearestParent as HTMLElement);
      }

      return -1;
    };

    const keyDownHandler: React.KeyboardEventHandler<HTMLElement> = (event) => {
      const target = event.target as Element;
      const isRoot = target?.classList.contains("mdc-deprecated-list-item");
      const listIndex = getListItemIndex(event.nativeEvent);

      foundationRef.current?.handleKeydown(
        event.nativeEvent,
        isRoot,
        listIndex
      );
    };

    const clickHandler: React.MouseEventHandler<HTMLElement> = (event) => {
      const listIndex = getListItemIndex(event.nativeEvent);
      foundationRef.current?.handleClick(listIndex, false);
    };

    const focusHandler = (event: React.FocusEvent) => {
      const listIndex = getListItemIndex(event.nativeEvent);
      foundationRef.current?.handleFocusIn(listIndex);
    };

    const blurHandler = (event: React.FocusEvent) => {
      const listIndex = getListItemIndex(event.nativeEvent);
      foundationRef.current?.handleFocusOut(listIndex);
    };

    return (
      <ListContext.Provider value={listController}>
        <ul
          className={classnames(className, "mdc-deprecated-list", {
            "mdc-deprecated-list--two-line": twoLines,
            "mdc-deprecated-list--one-line": !twoLines,
            "mdc-deprecated-list--dense": dense,
          })}
          ref={createListRef}
          {...props}
          onKeyDown={keyDownHandler}
          onClick={clickHandler}
          onFocus={focusHandler}
          onBlur={blurHandler}
        >
          {children}
        </ul>
      </ListContext.Provider>
    );
  }
);

export default List;
