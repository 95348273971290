"use client";

import classnames from "classnames";
import * as R from "ramda";
import * as React from "react";
import { ListContext } from "../list";

export interface ListItemProps extends React.HTMLProps<HTMLLIElement> {
  activated?: boolean;
  selected?: boolean;
  divided?: boolean;
  onItemAction?: (element: HTMLLIElement) => void;
}

export const ListItem = React.forwardRef<HTMLLIElement, ListItemProps>(
  (
    {
      children,
      className,
      disabled,
      activated = false,
      selected = false,
      divided,
      tabIndex = -1,
      onClick,
      onKeyPress,
      onItemAction = R.always(undefined),
      ...props
    },
    ref: React.MutableRefObject<HTMLLIElement>
  ) => {
    const itemRef = React.useRef<HTMLLIElement>();
    const listController = React.useContext(ListContext);

    const KeyPressHandler: React.KeyboardEventHandler<HTMLLIElement> = React.useCallback(
      (event) => {
        if (disabled) {
          return undefined;
        }

        if (onKeyPress) {
          onKeyPress(event);
        }
      },
      [disabled, onKeyPress]
    );

    const clickHandler: React.MouseEventHandler<HTMLLIElement> = React.useCallback(
      (event) => {
        if (disabled) {
          return undefined;
        }

        if (onClick) {
          onClick(event);
        }
      },
      [disabled, onClick]
    );

    const registerItem = React.useCallback(() => {
      const element = itemRef.current;

      if (element) {
        listController.registerItem({
          getElement: () => element,
          handleAction: () => onItemAction(element),
        });
      }
    }, [onItemAction]);

    React.useEffect(() => {
      registerItem();
    }, [onItemAction]);

    const createListItemRef = React.useCallback((element: HTMLLIElement) => {
      itemRef.current = element;
      if (ref) {
        if (ref instanceof Function) {
          ref(element);
        } else {
          ref.current = element;
        }
      }

      registerItem();
    }, []);

    return (
      <>
        <li
          onClick={clickHandler}
          onKeyPress={KeyPressHandler}
          className={classnames("mdc-deprecated-list-item", className, {
            "mdc-deprecated-list-item--disabled": disabled,
            "mdc-deprecated-list-item--activated": activated,
            "mdc-deprecated-list-item--selected": selected,
          })}
          tabIndex={tabIndex}
          ref={createListItemRef}
          {...props}
        >
          <span className="mdc-deprecated-list-item__ripple" />
          {children}
        </li>
        {divided && (
          <li role="separator" className="mdc-deprecated-list-divider"></li>
        )}
      </>
    );
  }
);

export default ListItem;
