interface HttpCollectionInterface<T> {
  getItems: () => T[];
  getTotal: () => number;
}

class HttpCollection<T> implements HttpCollectionInterface<T> {
  constructor(private items: T[], private total: number) {}

  public getItems() {
    return this.items;
  }

  public getTotal() {
    return this.total;
  }

  public map<K>(f: (item: T) => K) {
    return new HttpCollection<K>(this.items.map(f), this.total);
  }
}

export default HttpCollection;
