import Svg from "@natera/material/lib/svg";
import React, { FC } from "react";

export interface LeadIconProps {
  icon?: React.ReactNode;
}

const LeadIcon: FC<LeadIconProps> = ({ icon }) => {
  if (icon) {
    if (typeof icon === "string") {
      return <Svg content={icon} />;
    }
    return <>{icon}</>;
  }
  return null;
};

export default React.memo(LeadIcon);
