import * as React from "react";

import "./lifecycle.scss";

interface LifecycleController {
  itemWidth: number;
  activeItem: (index: number) => void;
  getItemIndex: () => number;
}

interface Props extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactNode;
}

const ITEM_PADDING = 8;

export const LifecycleContext = React.createContext<LifecycleController>({
  itemWidth: 20,
  activeItem: () => undefined,
  getItemIndex: () => 0,
});

export const Lifecycle: React.FunctionComponent<Props> = ({
  children,
  ...rest
}) => {
  const root = React.useRef<HTMLDivElement>(null);
  const activeIndex = React.useRef(0);

  const [width, setWidth] = React.useState<number>(0);
  const [itemWidth, setItemWidth] = React.useState<number>(20);
  const count = React.Children.count(children);

  React.useEffect(() => {
    const contanerWidth = root.current?.offsetWidth;
    if (!contanerWidth) {
      return;
    }

    const dWidth = window.innerWidth;
    const iWidth = (contanerWidth - ITEM_PADDING * count) / count;
    setItemWidth((iWidth / dWidth) * 100);
  }, [count]);

  const getItemIndex = React.useCallback(() => {
    const current = activeIndex.current;
    activeIndex.current = activeIndex.current + 1;
    return current;
  }, []);

  const activeItem = React.useCallback((index: number) => {
    setWidth(index * (1 / (count - 1)) * 100);
  }, []);

  const controller = React.useMemo<LifecycleController>(
    () => ({
      itemWidth,
      getItemIndex,
      activeItem,
    }),
    [count, itemWidth]
  );

  return (
    <LifecycleContext.Provider value={controller}>
      <div {...rest} ref={root} className="lifecycle">
        <div className="lifecycle-container">
          <div className="lifecycle-bar" />
          <div
            className="lifecycle-bar-active"
            style={{ width: `${width}%` }}
          />
          <div className="lifecycle-items">{children}</div>
        </div>
      </div>
    </LifecycleContext.Provider>
  );
};
