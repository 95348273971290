import { MenuItem, MenuItemProps } from "@natera/material/lib/menu";

import classnames from "classnames";
import * as React from "react";
import { getTypeAheadContext } from "./typeAheadContext";

export interface TypeAheadOptionProps extends MenuItemProps {
  optionValue: object;
}

export const TypeAheadOption: React.FunctionComponent<TypeAheadOptionProps> = ({
  className,
  optionValue,
  children,
  disabled,
  ...props
}) => {
  const typeahead = React.useContext(getTypeAheadContext());

  const itemSelectHandler = () => {
    typeahead.selectOption(optionValue);
  };

  return (
    <MenuItem
      onItemSelect={itemSelectHandler}
      disabled={disabled}
      className={classnames(className, "typeahead-item", {
        "typeahead-item--disabled": disabled,
      })}
      aria-hidden={false}
      {...props}
    >
      {children}
    </MenuItem>
  );
};
