"use client";

import * as R from "ramda";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import MenuWrapper, {
  Corner,
  MenuContext,
  MenuController,
} from "@natera/material/lib/menu";
import { DayRangePicker, DayRangePickerProps, DateRange } from "../";
import Button from "../../button";

export interface DayRangePickerWrapperProps extends DayRangePickerProps {
  children: (controller: MenuController) => React.ReactNode;
  onSelectRange?: (date: DateRange | undefined) => void;
  onReset?: () => void;
  disableAutoOpen?: boolean;
}

export const messages = defineMessages({
  clear: {
    id: "material.range.clear",
    defaultMessage: "Clear",
  },
  cancel: {
    id: "material.range.cancel",
    defaultMessage: "Cancel",
  },
  ok: {
    id: "material.range.ok",
    defaultMessage: "Ok",
  },
});

export const DayRangePickerWrapper: React.FunctionComponent<DayRangePickerWrapperProps> = ({
  children,
  range,
  disableAutoOpen = true,
  onSelectRange = R.always(undefined),
  ...props
}) => {
  const intl = useIntl();
  const menuController = React.useRef<MenuController>();

  const [range$, setRange$] = React.useState<DateRange | undefined>(range);

  React.useEffect(() => {
    setRange$(range);
  }, [range?.to, range?.from]);

  const onSelectRange$ = () => {
    onSelectRange(range$?.from && range$?.to ? range$ : undefined);
    if (menuController?.current) {
      menuController.current.closeMenu();
    }
  };

  const onReset$ = () => {
    onSelectRange(undefined);
    if (menuController?.current) {
      menuController.current.closeMenu();
    }
  };

  const onKeydownHandlerMenu = (e: React.KeyboardEvent) => {
    if (e.key === "Tab") {
      e.stopPropagation();
    }
  };

  const onOpen = React.useCallback(() => {
    setRange$(range);
  }, [range?.to, range?.from]);

  return (
    <MenuWrapper
      className="date-field-menu"
      corner={Corner.TOP_START}
      onOpen={onOpen}
      onKeyDown={onKeydownHandlerMenu}
      disableAutoOpen={disableAutoOpen}
      menu={
        <MenuContext.Consumer>
          {(menu: MenuController) => {
            menuController.current = menu;
            return (
              <>
                <DayRangePicker
                  {...props}
                  header={true}
                  range={range$}
                  onDateRangeChange={setRange$}
                />
                <div className="rdp-range-buttons">
                  <Button type="reset" onClick={onReset$}>
                    {intl.formatMessage(messages.clear)}
                  </Button>
                  <Button
                    type="button"
                    className="rdp-range-buttons--right"
                    onClick={menu.closeMenu}
                  >
                    {intl.formatMessage(messages.cancel)}
                  </Button>
                  <Button
                    type="submit"
                    disabled={
                      (range$?.from || range$?.to) &&
                      (!range$?.from || !range$?.to)
                    }
                    onClick={onSelectRange$}
                  >
                    {intl.formatMessage(messages.ok)}
                  </Button>
                </div>
              </>
            );
          }}
        </MenuContext.Consumer>
      }
    >
      {children}
    </MenuWrapper>
  );
};
