import { Link, LinkProps } from "@natera/platform/lib/components/link";
import classnames from "classnames";
import * as React from "react";
import Button from "./button";

interface Props extends LinkProps {
  raised?: boolean;
  unelevated?: boolean;
  outlined?: boolean;
  icon?: string;
  disabled?: boolean;
}

// TODO: do we need this component within form?
export const LinkButton: React.FunctionComponent<Props> = ({
  children,
  raised,
  unelevated,
  outlined,
  icon,
  disabled,
  className,
  ...props
}) => (
  <Link
    {...props}
    disabled={disabled}
    className={classnames("link-button", className)}
  >
    <Button type="button" {...{ raised, unelevated, outlined, icon, disabled }}>
      {children}
    </Button>
  </Link>
);

export default LinkButton;
