import { Spinner } from "@natera/material/lib/progress";
import * as React from "react";

import "./loading.scss";

const Loading = ({ component: Component = "div", ...rest }: LoadingProps) => (
  <Component {...rest} className="loading">
    <Spinner />
  </Component>
);

interface LoadingProps extends React.HTMLProps<HTMLDivElement> {
  component?: React.ElementType;
}

interface Props extends React.HTMLProps<HTMLDivElement> {
  isLoading: boolean;
  children?: React.ReactNode;
  component?: React.ElementType;
}

export const LoadingContext = ({ isLoading, children, ...rest }: Props) => (
  <React.Fragment>
    {isLoading ? <Loading {...rest} /> : children}
  </React.Fragment>
);

export default LoadingContext;
