import { LinearProgress } from "@natera/material/lib/progress";
import React from "react";
import { useIntl } from "react-intl";
import StepHeader from "./header";
import { StepState } from "./typings";
import { messages } from "./stepper";

interface StepperHeaderProps {
  title?: React.ReactNode;
  progressStepper?: boolean;
  progress: number;
  numberOfResolvedSteps: number;
  steps: StepState[];
}

const onKeydownHandlerSteps = (e: React.KeyboardEvent) => {
  let focusedStep;
  const availableSteps = Array.from(e.currentTarget.children).filter(
    (step) => !(step.firstChild as HTMLElement).className.includes("--disabled")
  );

  const focusedStepIndex = availableSteps.findIndex(
    (step) => (step as HTMLElement).querySelector(":focus") !== null
  );

  if (e.key === "ArrowUp") {
    focusedStep = availableSteps[focusedStepIndex - 1];
  } else if (e.key === "ArrowDown") {
    focusedStep = availableSteps[focusedStepIndex + 1];
  }
  if (focusedStep) (focusedStep?.firstChild as HTMLElement).focus();
};

const StepperHeader: React.FunctionComponent<StepperHeaderProps> = ({
  progressStepper,
  title,
  numberOfResolvedSteps,
  progress,
  steps,
}) => {
  const intl = useIntl();

  return (
    <header
      className={
        progressStepper ? "progress-stepper__header" : "stepper__header"
      }
    >
      {title && (
        <div
          className={
            progressStepper
              ? "progress-stepper__header__title"
              : "stepper__header__title"
          }
        >
          {title}
        </div>
      )}
      {progressStepper && (
        <div className="progress-stepper__header__progress">
          {intl.formatMessage(messages.stepCount, {
            resolvedSteps: numberOfResolvedSteps,
            total: steps.length,
          })}
        </div>
      )}
      {progressStepper && (
        <div className="progress-stepper__header__progress-steps">
          <LinearProgress indeterminate={false} progress={progress} />
        </div>
      )}
      {!progressStepper && Boolean(steps.length) && (
        <div
          className="stepper__header__steps"
          onKeyDown={onKeydownHandlerSteps}
        >
          {steps.map((step, idx) => (
            <div className="stepper__header__steps__unit" key={step.stepId}>
              <StepHeader stepId={step.stepId} index={idx + 1} />
            </div>
          ))}
        </div>
      )}
    </header>
  );
};

export default StepperHeader;
