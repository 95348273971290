import * as React from "react";
import { FormContext } from "../";
import { ButtonProps, FormButton } from "./button";

export const ResetButton: React.FunctionComponent<ButtonProps> = ({
  children,
  disabled,
  ...props
}) => {
  const { isChanged } = React.useContext(FormContext);

  return (
    <FormButton type="reset" disabled={disabled || !isChanged()} {...props}>
      {children}
    </FormButton>
  );
};

export default ResetButton;
