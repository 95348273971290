import { MenuContext } from "@natera/material/lib/menu";
import classnames from "classnames";
import * as R from "ramda";
import * as React from "react";
import { Textfield, TextfieldProps } from "../textfield";
import { getTypeAheadContext } from "./typeAheadContext";
import { useKeydownHandler } from "./inputKeydownHandler";

export interface TypeAheadInputProps extends TextfieldProps {
  allowType?: boolean;
}

export const TypeAheadInput = React.forwardRef<
  HTMLInputElement,
  TypeAheadInputProps
>(
  (
    {
      disabled,
      allowType = true,
      onChange = R.always(undefined),
      onKeyDown = R.always(undefined),
      onClear = R.always(undefined),
      selectable = true,
      readOnly = false,
      clearable = true,
      ...props
    },
    ref
  ) => {
    const menuController = React.useContext(MenuContext);
    const typeahead = React.useContext(getTypeAheadContext());

    const changeHandler: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
      (event) => {
        if (!allowType) {
          return undefined;
        }

        onChange(event);

        typeahead.setInputValue(event.currentTarget.value);
      },
      [allowType, onChange, typeahead]
    );

    const clearHandler = React.useCallback(() => {
      onClear();
      typeahead.selectOption(undefined);
      typeahead.clear();
    }, [onClear, typeahead]);

    const keydownHandler = useKeydownHandler(
      allowType,
      R.always(onkeydown),
      typeahead,
      menuController
    );

    return (
      <Textfield
        className={classnames("typeahead-input", {
          "typeahead-input--open": menuController.isOpen(),
        })}
        ref={ref}
        selectable={selectable}
        value={typeahead.getInputValue()}
        onChange={changeHandler}
        onKeyDown={
          typeahead.getOptionsCollection().isLoading()
            ? undefined
            : keydownHandler
        }
        disabled={disabled}
        clearable={clearable}
        onClear={clearHandler}
        readOnly={!allowType || readOnly}
        {...props}
      />
    );
  }
);
