import { RefObject } from "react";
import {
  MDCTextFieldAdapter,
  MDCTextFieldNativeInputElement,
} from "@material/textfield";
import { MDCLineRippleFoundation } from "@material/line-ripple";
import { MDCFloatingLabelFoundation } from "@material/floating-label";
import { MDCNotchedOutlineFoundation } from "@material/notched-outline";

type Props = {
  textfieldRef: RefObject<HTMLDivElement | null>;
  inputRef: RefObject<HTMLInputElement | HTMLTextAreaElement | null>;
  label?: string;
  outline?: boolean;
  lineFoundation: MDCLineRippleFoundation;
  labelFoundation: MDCFloatingLabelFoundation;
  notchFoundation: MDCNotchedOutlineFoundation;
  validate?: (value: string) => boolean;
};

type ValidateProps = (props: {
  validate?: (value: string) => boolean;
  inputRef: RefObject<HTMLInputElement | HTMLTextAreaElement | null>;
}) => boolean;

const validateInputValue: ValidateProps = ({ inputRef, validate }) => {
  if (inputRef.current?.required && !inputRef.current.value) {
    return false;
  }

  if (validate) {
    return validate(inputRef.current?.value || "");
  }

  return true;
};

export const createTextfieldAdapter: (props: Props) => MDCTextFieldAdapter = ({
  textfieldRef,
  inputRef,
  label,
  outline,
  lineFoundation,
  labelFoundation,
  notchFoundation,
  validate,
}) => ({
  addClass: (className: string) =>
    textfieldRef.current?.classList.add(className),
  removeClass: (className: string) =>
    textfieldRef.current?.classList.remove(className),
  hasClass: (className: string) =>
    textfieldRef.current
      ? textfieldRef.current.classList.contains(className)
      : false,
  registerTextFieldInteractionHandler: (eventType, handler) => {
    textfieldRef.current?.addEventListener(eventType, handler);
  },
  deregisterTextFieldInteractionHandler: (eventType, handler) => {
    textfieldRef.current?.removeEventListener(eventType, handler);
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  registerValidationAttributeChangeHandler: () => void 0,
  deregisterValidationAttributeChangeHandler: () => void 0,
  getNativeInput: () => {
    if (!inputRef.current) {
      return null;
    }

    const isValid = validateInputValue({
      validate,
      inputRef,
    });

    const nativeInput: MDCTextFieldNativeInputElement = {
      disabled: inputRef.current.disabled,
      maxLength: inputRef.current.maxLength,
      type: inputRef.current.type,
      value: inputRef.current.value,
      required: inputRef.current.required,
      validity: {
        badInput: false,
        valid: isValid,
      },
    };

    return nativeInput;
  },
  setInputAttr: (attr, value) => {
    inputRef.current?.setAttribute(attr, value);
  },
  removeInputAttr: (attr) => {
    inputRef.current?.removeAttribute(attr);
  },
  isFocused: () => document.activeElement == inputRef.current,
  registerInputInteractionHandler: (eventType, handler) => {
    inputRef.current?.addEventListener(eventType, handler);
  },
  deregisterInputInteractionHandler: (eventType, handler) => {
    inputRef.current?.removeEventListener(eventType, handler);
  },
  shakeLabel: (shouldShake) => labelFoundation.shake(shouldShake),
  floatLabel: (shouldFloat) => labelFoundation.float(shouldFloat),
  hasLabel: () => !!label,
  getLabelWidth: () => labelFoundation.getWidth(),
  setLabelRequired: (isRequired) => labelFoundation.setRequired(isRequired),
  activateLineRipple: () => lineFoundation.activate(),
  deactivateLineRipple: () => lineFoundation.deactivate(),
  setLineRippleTransformOrigin: (normalizedX) =>
    lineFoundation.setRippleCenter(normalizedX),
  hasOutline: () => outline || false,
  notchOutline: (labelWidth) => notchFoundation.notch(labelWidth),
  closeOutline: () => notchFoundation.closeNotch(),
});
