import * as R from "ramda";
import * as React from "react";
import {
  Index,
  RecordIndex,
  StorageController,
  UpdateRecord,
  useStorage,
} from "../useStorage";

export type Denormalize<T, N> = (record: N) => T | undefined;
export type Normalize<T, N> = (record: T) => N;

export interface ProxyRecord<P> {
  index: Index;
  data: P;
}

interface Props<T, P> {
  initialRecords?: T[];
  recordIndex?: RecordIndex<T>;
  normalize: Normalize<T, P>;
  denormalize: Denormalize<T, P>;
}

export const useProxyStorage = <P, T>({
  initialRecords = [],
  recordIndex = R.propOr("", "id"),
  normalize,
  denormalize,
}: Props<T, P>): StorageController<T> => {
  // Writer must be a pure function, so that we enforce it instance
  const writer$ = React.useCallback(
    (record: T): ProxyRecord<P> => ({
      index: recordIndex(record),
      data: normalize(record),
    }),
    []
  );

  const initialRecords$: Array<ProxyRecord<P>> = React.useMemo(() => {
    return R.map(writer$, initialRecords);
  }, []);

  const proxyStorage = useStorage<ProxyRecord<P>>({
    recordIndex: R.prop("index"),
    initialRecords: initialRecords$,
  });

  const addRecords = R.compose(proxyStorage.addRecords, R.map(writer$));

  const removeRecord = proxyStorage.removeRecord;

  const removeRecords = proxyStorage.removeRecords;

  const addRecord = R.compose(proxyStorage.addRecord, writer$);

  const getRecord = React.useCallback(
    R.pipe(
      proxyStorage.getRecord,
      (proxyRecord) => proxyRecord?.data,
      R.unless(R.isNil, denormalize)
    ),
    [proxyStorage.getRecord, denormalize]
  );

  const getRecords = React.useCallback(
    R.pipe(
      proxyStorage.getRecords,
      R.map(R.compose(denormalize, R.prop("data"))),
      (records) => records.filter((record: T): record is T => !R.isNil(record))
    ),
    [proxyStorage.getRecords, denormalize]
  );

  const updateRecord: UpdateRecord<T> = React.useCallback(
    (index, update) => {
      const record = getRecord(index);
      if (record) {
        addRecord(update(record));
      }
    },
    [getRecord]
  );

  return React.useMemo<StorageController<T>>(
    () => ({
      recordIndex,
      reset: proxyStorage.reset,
      clear: proxyStorage.clear,
      addRecords,
      removeRecord,
      removeRecords,
      addRecord,
      updateRecord,
      getRecords,
      getRecord,
    }),
    [getRecord, getRecords]
  );
};
