"use client";

import * as React from "react";
import classnames from "classnames";

import { MDCRipple } from "@material/ripple";
import Svg from "@natera/material/lib/svg";
import { Spinner } from "@natera/material/lib/progress";

import "./button.scss";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  raised?: boolean;
  unelevated?: boolean;
  outlined?: boolean;
  error?: boolean;
  icon?: string;
  trailingIcon?: string;
  loading?: boolean;
  ripple?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      raised = false,
      unelevated = false,
      outlined = false,
      error = false,
      disabled,
      icon,
      trailingIcon,
      loading,
      ripple,
      id,
      ...props
    },
    ref
  ) => {
    const createRef = React.useCallback((element: HTMLButtonElement) => {
      if (ref) {
        if (ref instanceof Function) {
          ref(element);
        } else {
          ref.current = element;
        }
      }

      if (ripple && element) {
        new MDCRipple(element);
      }
    }, []);

    return (
      <button
        ref={createRef}
        id={id}
        className={classnames(className, "mdc-button", {
          "mdc-button--outlined": outlined,
          "mdc-button--raised": raised,
          "mdc-button--unelevated": unelevated,
          "mdc-button--error": error,
          "mdc-button--disabled": disabled || loading,
        })}
        disabled={disabled || loading}
        {...props}
      >
        <div className="mdc-button__ripple" />
        {icon && !loading && (
          <Svg
            className="material-icons mdc-button__icon"
            aria-hidden="true"
            content={icon}
          />
        )}
        {loading && <Spinner className="mdc-button__spinner" />}
        <span className="mdc-button__label">{children}</span>
        {trailingIcon && !loading && (
          <Svg
            className="material-icons mdc-button__icon"
            aria-hidden="true"
            content={trailingIcon}
          />
        )}
      </button>
    );
  }
);

Button.displayName = "Button";

export default Button;
