(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("classnames"), require("@natera/material/lib/svg"), require("@natera/material/lib/progress"), require("@natera/material/lib/common"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "classnames", "@natera/material/lib/svg", "@natera/material/lib/progress", "@natera/material/lib/common"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("classnames"), require("@natera/material/lib/svg"), require("@natera/material/lib/progress"), require("@natera/material/lib/common")) : factory(root["react"], root["classnames"], root["@natera/material/lib/svg"], root["@natera/material/lib/progress"], root["@natera/material/lib/common"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, function(__WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__8310__, __WEBPACK_EXTERNAL_MODULE__6289__, __WEBPACK_EXTERNAL_MODULE__1094__, __WEBPACK_EXTERNAL_MODULE__510__) {
return 