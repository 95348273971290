"use client";

import { MDCMenuSurfaceFoundation } from "@material/menu-surface";
import classnames from "classnames";
import * as React from "react";
import { MenuContext } from "./controller";

export interface MenuSurfaceAnchorProps
  extends React.HTMLProps<HTMLDivElement> {
  className?: string;
  children?: React.ReactNode;
}

export const MenuSurfaceAnchor = React.forwardRef<
  HTMLDivElement,
  MenuSurfaceAnchorProps
>(({ className, children, role = "button", ...props }, ref) => {
  const parentMenuController = React.useContext(MenuContext);

  const isExpanded = parentMenuController.isOpen();
  return (
    <div
      className={classnames(
        MDCMenuSurfaceFoundation.cssClasses.ANCHOR,
        className
      )}
      ref={ref}
      role={role}
      aria-haspopup={true}
      aria-expanded={isExpanded}
      {...props}
    >
      {children}
    </div>
  );
});
