import classnames from "classnames";
import * as React from "react";

interface Props extends React.HTMLProps<HTMLSpanElement> {
  type?: "primary" | "secondary";
}

export const ListItemText: React.FunctionComponent<Props> = ({
  children,
  className,
  type,
  ...props
}) => {
  return (
    <>
      <span
        className={classnames(className, {
          "mdc-deprecated-list-item__text": type === undefined,
          "mdc-deprecated-list-item__primary-text": type === "primary",
          "mdc-deprecated-list-item__secondary-text": type === "secondary",
        })}
        {...props}
      >
        {children}
      </span>
    </>
  );
};

export default ListItemText;
