type FileUrl = string;
type ViewFileObject = File | FileUrl;

class FileService {
  public download(blobObj: File, fileName: string) {
    // IE
    // @ts-ignore
    if (navigator && navigator.msSaveBlob) {
      // @ts-ignore
      navigator.msSaveBlob(
        blobObj,
        blobObj.name || fileName + this.getExtension(blobObj.type)
      );
      return;
    }

    const url = window.URL.createObjectURL(blobObj);
    const a = document.createElement("a");
    a.href = url;
    a.download = blobObj.name || fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  public viewOnNewTab(fileObj: ViewFileObject) {
    if (typeof fileObj === "string") {
      window.open(`/pdfViewer?url=${fileObj}`, "_blank");
      return;
    }

    // IE
    // @ts-ignore
    if (navigator && navigator.msSaveOrOpenBlob) {
      // TODO: try to open GET request directly on IE tab (on backend should be Content-Disposition with 'inline;' instead of 'attachment;')
      // @ts-ignore
      navigator.msSaveOrOpenBlob(fileObj);
      return;
    }

    // Safari
    if (navigator.userAgent.toLowerCase().includes("safari")) {
      const urlSafari = window.URL.createObjectURL(fileObj);
      const tab = window.open("", "_blank");
      setTimeout(() => {
        if (tab) {
          tab.location.href = urlSafari;
          tab.onload = () => window.URL.revokeObjectURL(urlSafari);
        }
      }, 100);
      return;
    }

    const a = document.createElement("a");
    const url = window.URL.createObjectURL(fileObj);
    a.onclick = () => window.open(url, "_blank");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  private getExtension(mimeType: string): string {
    let fileExtension = "";
    if (mimeType.indexOf("application") === 0) {
      fileExtension = `.${mimeType.substr(12)}`;
    }
    return fileExtension;
  }
}

export default FileService;
