(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("classnames"), require("react"), require("react-intl"), require("ramda"), require("@natera/material/lib/progress"), require("@natera/material/lib/svg"), require("@natera/material/lib/card"));
	else if(typeof define === 'function' && define.amd)
		define(["classnames", "react", "react-intl", "ramda", "@natera/material/lib/progress", "@natera/material/lib/svg", "@natera/material/lib/card"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("classnames"), require("react"), require("react-intl"), require("ramda"), require("@natera/material/lib/progress"), require("@natera/material/lib/svg"), require("@natera/material/lib/card")) : factory(root["classnames"], root["react"], root["react-intl"], root["ramda"], root["@natera/material/lib/progress"], root["@natera/material/lib/svg"], root["@natera/material/lib/card"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, function(__WEBPACK_EXTERNAL_MODULE__310__, __WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__767__, __WEBPACK_EXTERNAL_MODULE__288__, __WEBPACK_EXTERNAL_MODULE__94__, __WEBPACK_EXTERNAL_MODULE__289__, __WEBPACK_EXTERNAL_MODULE__747__) {
return 