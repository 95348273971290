import classnames from "classnames";
import * as React from "react";
import Label from "../label";

import "./radioButton.scss";

export interface RadioButtonProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: React.ReactNode;
}

export const RadioButton: React.FunctionComponent<RadioButtonProps> = ({
  id,
  className,
  label,
  disabled,
  required,
  ...props
}) => {
  return (
    <div className={classnames(className, "mdc-radio-field")}>
      <div
        className={classnames("mdc-radio", {
          "mdc-radio--disabled": disabled,
        })}
      >
        <input
          id={id}
          type="radio"
          className="mdc-radio__native-control"
          disabled={disabled}
          required={required}
          {...props}
        />
        <div className="mdc-radio__background">
          <div className="mdc-radio__outer-circle" />
          <div className="mdc-radio__inner-circle" />
        </div>
        <div className="mdc-radio__ripple" />
      </div>
      {label && <Label htmlFor={!disabled ? id : undefined}>{label}</Label>}
    </div>
  );
};

export default RadioButton;
