import React from "react";

interface BaseAnimationProps {
  expandable: boolean;
  expanded: boolean;
  heightRightSide: number;
  heightRightSideExpanded: number;
}

interface CardStyleProps extends BaseAnimationProps {
  transitionAnimation: boolean;
}

interface RightSideProps extends BaseAnimationProps {
  boundsWidth: number;
  widthRightSide: number;
}

const useCardStyle = ({
  expandable,
  expanded,
  transitionAnimation,
  heightRightSide,
  heightRightSideExpanded,
}: CardStyleProps) =>
  React.useMemo(() => {
    if (!expandable) {
      return;
    }

    if (expanded) {
      return {
        height: transitionAnimation
          ? `${heightRightSide}px`
          : `${heightRightSideExpanded}px`,
      };
    }

    return {
      height: heightRightSide ? `${heightRightSide}px` : "auto",
    };
  }, [
    expandable,
    expanded,
    transitionAnimation,
    heightRightSide,
    heightRightSideExpanded,
  ]);

const useRightSideExtendedStyle = ({
  expandable,
  expanded,
  boundsWidth,
  heightRightSideExpanded,
  widthRightSide,
  heightRightSide,
}: RightSideProps) =>
  React.useMemo(() => {
    if (!expandable) {
      return;
    }

    if (expanded) {
      return {
        width: `${boundsWidth}px`,
        height: `${heightRightSideExpanded}px`,
      };
    }

    return {
      width: widthRightSide ? `${widthRightSide}px` : "auto",
      height: heightRightSide ? `${heightRightSide}px` : "auto",
    };
  }, [
    expandable,
    expanded,
    heightRightSide,
    heightRightSideExpanded,
    boundsWidth,
    widthRightSide,
  ]);

export { useCardStyle, useRightSideExtendedStyle };
