"use client";

import React from "react";
import ReactDOM from "react-dom";

export type ClientPortalProps = {
  children: React.ReactNode;
  container?: HTMLElement;
};

export const ClientPortal: React.FC<ClientPortalProps> = ({
  children,
  container,
}) => {
  const ref = React.useRef<HTMLElement | null>(null);
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    ref.current = container || document?.body;
    setMounted(true);
  }, []);

  return mounted && ref.current
    ? ReactDOM.createPortal(children, ref.current)
    : null;
};
