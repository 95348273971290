"use client";

import {
  ListItem,
  ListItemGraphic,
  ListItemMeta,
  ListItemProps,
} from "@natera/material/lib/list";
import Svg from "@natera/material/lib/svg";
import * as R from "ramda";
import * as React from "react";
import { MenuContext } from "./controller";

export interface MenuItemProps extends ListItemProps {
  icon?: string;
  trailingIcon?: string;
  arrowIcon?: string;
  onItemSelect?: (element: HTMLLIElement) => void;
  closeOnSelect?: boolean;
}

export const MenuItem: React.FunctionComponent<React.PropsWithChildren<
  MenuItemProps
>> = ({
  children,
  icon,
  className,
  trailingIcon,
  arrowIcon,
  disabled,
  closeOnSelect = true,
  onItemSelect = R.always(undefined),
  ...props
}) => {
  const menuItemRef = React.useRef<HTMLLIElement>(null);
  const menuController = React.useContext(MenuContext);

  React.useEffect(() => {
    const element = menuItemRef.current;

    if (element) {
      menuController.registerItem({
        getElement: () => element,
        handleItemSelect: () => onItemSelect(element),
      });
    }
  }, [onItemSelect]);

  const handleItemAction = React.useCallback(
    (item: HTMLLIElement) => {
      menuController.onItemAction(item, closeOnSelect);
    },
    [menuController, closeOnSelect]
  );

  const keyHandler = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Escape") {
        menuController.closeMenu();
      }
    },
    [menuController]
  );

  return (
    <ListItem
      className={className}
      role="menuitem"
      onItemAction={handleItemAction}
      disabled={disabled}
      onKeyDown={keyHandler}
      {...props}
      ref={menuItemRef}
    >
      {icon && (
        <ListItemGraphic>
          <Svg content={icon} />
        </ListItemGraphic>
      )}
      {children}
      {trailingIcon && (
        <ListItemMeta>
          <Svg content={trailingIcon} />
        </ListItemMeta>
      )}
      {arrowIcon && (
        <ListItemMeta>
          <Svg content={arrowIcon} />
        </ListItemMeta>
      )}
    </ListItem>
  );
};

export default MenuItem;
