import * as R from "ramda";
import * as React from "react";
import * as Actions from "./actions";
import StepError from "./error";

import {
  StepId,
  StepState,
  StepStatus,
  IsLoading,
  GetSteps,
  GetCurrentStep,
  GetData,
  GetStep,
} from "./typings";

export interface StepperController {
  createStep: Actions.CreateStep;
  removeStep: Actions.RemoveStep;
  updateStep: Actions.UpdateStep;
  goAt: Actions.goAt;
  goBack: Actions.goBack;
  resolve: Actions.Resolve;
  reject: Actions.Reject;
  isLoading: IsLoading;
  getSteps: GetSteps;
  getCurrentStep: GetCurrentStep;
  getNextStep: GetStep;
  getPreviousStep: GetStep;
  getStep: GetStep;
  getData: GetData;
  setLoading: Actions.SetLoading;
  resetStep: Actions.ResetStep;
  reset: Actions.Reset;
  skipCurrentStep: Actions.SkipCurrentStep;
  getNumberOfResolvedSteps: () => number;
  getProgress: () => number;
}

const contextFallback = () => {
  throw new Error("createStep invoked outside of Stepper scope");
};

export const Context = React.createContext<StepperController>({
  createStep: contextFallback,
  getCurrentStep: () => undefined,
  getNextStep: () => undefined,
  getPreviousStep: () => undefined,
  getData: () => undefined,
  getStep: () => undefined,
  getSteps: () => [],
  goAt: contextFallback,
  goBack: () => undefined,
  isLoading: () => false,
  reject: contextFallback,
  removeStep: contextFallback,
  resolve: contextFallback,
  updateStep: contextFallback,
  setLoading: contextFallback,
  resetStep: contextFallback,
  reset: contextFallback,
  skipCurrentStep: contextFallback,
  getNumberOfResolvedSteps: () => 0,
  getProgress: () => 0,
});

interface Props {
  children: (context: StepperController) => React.ReactNode;
  contextRef?: React.MutableRefObject<StepperController>;
  initialStep: StepId;
}

const StepperProvider: React.FunctionComponent<Props> = ({
  initialStep,
  contextRef,
  children,
}) => {
  const [current, setCurrent] = React.useState<StepId>(initialStep);
  const [steps, setSteps] = React.useState<Record<StepId, StepState>>({});

  const initialStepId = 0;
  const [resolvedStepIds, setResolvedStepIds] = React.useState<StepId[]>([
    initialStepId,
  ]);

  const getIndex = (stepId: StepId) => steps[stepId].index || 0;

  const getSteps: GetSteps = () => {
    return R.values(steps).sort((a, b) => (a.index || 0) - (b.index || 0));
  };

  const getNumberOfResolvedSteps = () => resolvedStepIds.length;

  const getProgress = () => {
    const progressСalculation =
      (100 / getSteps().length) * getNumberOfResolvedSteps();
    return progressСalculation;
  };

  const getNextStep = (stepId: StepId) => {
    if (resolvedStepIds.length !== getSteps().length) {
      setResolvedStepIds([...resolvedStepIds, stepId]);
    }
    const index = getIndex(stepId);
    const nextStep =
      getSteps().find((step) => (step.index as number) > index) ||
      steps[current];
    return nextStep;
  };

  const getPreviousStep = (stepId: StepId) => {
    const index = getIndex(stepId);
    const prevStep =
      R.findLast((step) => (step.index as number) < index, getSteps()) ||
      steps[current];
    return prevStep;
  };

  const createStep: Actions.CreateStep = (stepId, config) => {
    const stepState = steps[stepId] || config;

    setSteps((steps$) => ({
      ...steps$,
      [stepId]: {
        index: R.keys(steps$).length,
        ...stepState,
        status: stepState.status || StepStatus.UNTREATED,
        loading: false,
        stepId,
      },
    }));
  };

  const removeStep: Actions.RemoveStep = (stepId) => {
    setSteps(R.dissoc(String(stepId)));
  };

  const updateStep: Actions.UpdateStep = (
    stepId,
    { loading = false, description, index, data }
  ) =>
    setSteps((steps$) => ({
      ...steps$,
      [stepId]: {
        ...steps$[stepId],
        loading,
        description: description || steps$[stepId].description,
        index: index !== undefined ? index : steps$[stepId].index,
        data: data || steps$[stepId].data,
      },
    }));

  const goAt: Actions.goAt = (stepId) => setCurrent(stepId);

  const goBack: Actions.goBack = () => {
    setCurrent(getPreviousStep(current).stepId);
    setResolvedStepIds(resolvedStepIds.slice(0, -1));
  };

  const isLoading = () => getSteps().some((step) => step.loading);

  const setLoading: Actions.SetLoading = (stepId, loading) => {
    setSteps((steps$) => ({
      ...steps$,
      [stepId]: {
        ...steps$[stepId],
        loading,
      },
    }));
  };

  const resetStep: Actions.ResetStep = (stepId) => {
    setSteps((steps$) => ({
      ...steps$,
      [stepId]: {
        ...steps$[stepId],
        status: StepStatus.UNTREATED,
        error: undefined,
      },
    }));
  };

  const getStep: GetStep = (stepId: StepId) => steps[stepId];

  const getCurrentStep: GetCurrentStep = () => getStep(current);

  const getData: GetData = (stepId: StepId, fallback) => {
    const stepState = getStep(stepId);
    return (stepState && stepState.data) || fallback;
  };

  const resolve: Actions.Resolve = (data) => {
    setSteps((steps$) => ({
      ...steps$,
      [current]: {
        ...steps$[current],
        status: StepStatus.COMPLETED,
        data,
        error: undefined,
      },
    }));
    setCurrent((current$) => getNextStep(current$).stepId);

    if (resolvedStepIds.length !== getSteps().length) {
      setResolvedStepIds([...resolvedStepIds, current]);
    }
  };

  const reject: Actions.Reject = (message) => {
    setSteps((steps$) => ({
      ...steps$,
      [current]: {
        ...steps[current],
        status: StepStatus.UNTREATED,
        error: new StepError(message),
      },
    }));
  };

  const reset: Actions.Reset = () => {
    setSteps((steps$) => ({
      ...R.mapObjIndexed(
        (step) => ({
          ...step,
          status: StepStatus.UNTREATED,
          error: undefined,
          data: {},
        }),
        steps$
      ),
    }));
    setCurrent(initialStep);
    setResolvedStepIds([initialStepId]);
  };

  const skipCurrentStep: Actions.SkipCurrentStep = () => {
    setCurrent((current$) => getNextStep(current$).stepId);
  };

  const context = React.useMemo(
    () => ({
      createStep,
      getCurrentStep,
      getNextStep,
      getPreviousStep,
      getData,
      getStep,
      getSteps,
      goAt,
      goBack,
      isLoading,
      reject,
      removeStep,
      resolve,
      updateStep,
      setLoading,
      resetStep,
      reset,
      skipCurrentStep,
      getNumberOfResolvedSteps,
      getProgress,
    }),
    [current, steps]
  );

  if (contextRef) {
    React.useEffect(() => {
      contextRef.current = context;
    }, [context]);
  }

  return (
    <Context.Provider value={context}>{children(context)}</Context.Provider>
  );
};

export default StepperProvider;
