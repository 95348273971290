import { IconButton } from "@natera/material/lib/button";
import Label from "@natera/material/lib/label";
import { LinearProgress } from "@natera/material/lib/progress";
import Svg from "@natera/material/lib/svg";
import ErrorIcon from "@natera/platform/assets/svg/icons/error.svg";
import FileIcon from "@natera/platform/assets/svg/icons/file.svg";
import TrashIcon from "@natera/platform/assets/svg/icons/trash.svg";
import { Input } from "@natera/platform/lib/components/form";
import classnames from "classnames";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import "./file.scss";

export interface FileInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode;
  isLoading?: boolean;
  progress?: number;
  error?: string;
  onReset?: () => void;
}

export const messages = defineMessages({
  byte: {
    id: "platform.file.byte",
    defaultMessage: `{size} {size, plural,
        one {byte}
        other {bytes}
      }`,
  },
  kilobyte: {
    id: "platform.file.kilobyte",
    defaultMessage: "{size} KB",
  },
  megabyte: {
    id: "platform.file.megabyte",
    defaultMessage: "{size} MB",
  },
});

export const FileInput = React.forwardRef<HTMLInputElement, FileInputProps>(
  (
    {
      id,
      className,
      label,
      isLoading = false,
      progress,
      error,
      onChange = () => undefined,
      onReset = () => undefined,
      ...props
    },
    ref: React.RefObject<HTMLInputElement>
  ) => {
    const intl = useIntl();
    const ref$ = ref || React.useRef<HTMLInputElement>();
    const [file, setFile] = React.useState<File>();

    const changeHandler: React.ChangeEventHandler<HTMLInputElement> = (
      event
    ) => {
      if (event.currentTarget.files) {
        setFile(event.currentTarget.files[0]);
        onChange(event);
      }
    };

    const clearFile: React.MouseEventHandler<HTMLButtonElement> = (event) => {
      event.stopPropagation();
      event.preventDefault();

      if (ref$.current) {
        ref$.current.value = "";
      }
      setFile(undefined);
      onReset();
    };

    const getFileSizeLabel = (size: number): string => {
      return size < 1024
        ? intl.formatMessage(messages.byte, { size })
        : size < 1024 * 1024
        ? intl.formatMessage(messages.kilobyte, {
            size: (size / 1024).toFixed(),
          })
        : intl.formatMessage(messages.megabyte, {
            size: (size / (1024 * 1024)).toPrecision(3),
          });
    };

    const fileItem = file && (
      <div id={id} className="file">
        <Svg
          content={error ? ErrorIcon : FileIcon}
          className={classnames("file-icon", {
            "file-icon-error": error,
          })}
        />
        <div className="file-content">
          <div className="file-name">{file.name}</div>
          {isLoading && (
            <LinearProgress
              className="file-loading"
              indeterminate={typeof progress === "undefined"}
              progress={progress}
            />
          )}
          {!isLoading && error && <div className="file-error">{error}</div>}
          {!isLoading && !error && (
            <div className="file-size">{getFileSizeLabel(file.size)}</div>
          )}
        </div>
        <div className="file-tools">
          {!isLoading && (
            <IconButton onClick={clearFile}>{TrashIcon}</IconButton>
          )}
        </div>
      </div>
    );

    return (
      <div className="mdc-form-field file-field">
        <Label className="file-field-label">
          <Input
            {...props}
            ref={ref$}
            type="file"
            disabled={isLoading}
            onChange={changeHandler}
            className={classnames(className, "file-input")}
          />
          {file ? fileItem : <span className="label">{label}</span>}
        </Label>
      </div>
    );
  }
);

export default FileInput;
