"use client";

import * as React from "react";
import { readDate, writeDate } from "../utils";
import { DateInput } from "./date";
import { BaseDateInputProps } from "./inputMask";

export interface DateStringInputProps extends BaseDateInputProps {
  value?: string;
  defaultValue?: string;
  onDateChange?: (dateString: string) => void;
}

export const DateStringInput: React.FunctionComponent<DateStringInputProps> = ({
  value,
  defaultValue,
  onDateChange,
  ...props
}) => {
  const initialDate = readDate(value);
  const initialDefaultDate = readDate(defaultValue);

  const dateChangeHandler = (date: Date | undefined) => {
    if (onDateChange) {
      onDateChange(date ? writeDate(date) : "");
    }
  };

  return (
    <DateInput
      date={initialDate}
      defaultDate={initialDefaultDate}
      onDateChange={dateChangeHandler}
      {...props}
    />
  );
};

export default DateStringInput;
