import { ListItemGraphic, ListItemText } from "@natera/material/lib/list";
import Svg from "@natera/material/lib/svg";
import { Link, LinkProps } from "@natera/platform/lib/components/link";
import * as React from "react";

export interface DrawerItemProps extends LinkProps {
  icon: string;
}

const DrawerItem: React.FunctionComponent<DrawerItemProps> = ({
  icon,
  children,
  ...props
}) => (
  <Link
    {...props}
    className="mdc-deprecated-list-item"
    activeClassName="mdc-deprecated-list-item--activated"
  >
    <ListItemGraphic aria-hidden="true">
      <Svg content={icon} />
    </ListItemGraphic>
    <ListItemText>{children}</ListItemText>
  </Link>
);

export default DrawerItem;
