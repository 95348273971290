import Svg from "@natera/material/lib/svg";
import classnames from "classnames";
import * as React from "react";

import "./floatingButton.scss";

export interface FloatingButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: string;
  label?: string;
  className?: string;
}

export const FloatingButton = React.forwardRef<
  HTMLButtonElement,
  FloatingButtonProps
>(({ children, label, className, disabled, ...props }, ref) => {
  return (
    <button
      className={classnames("mdc-fab", className, {
        "mdc-fab--extended": label,
        "mdc-fab--disabled": disabled,
      })}
      ref={ref}
      {...props}
    >
      <div className="mdc-fab__ripple" />
      <Svg className="mdc-fab__icon" content={children} />
      {label && <span className="mdc-fab__label">{label}</span>}
    </button>
  );
});
export default FloatingButton;
