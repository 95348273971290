import React from "react";
import { TypeAheadController } from "./typeAheadContext";
import { MenuController } from "../../../menu";

export const useKeydownHandler = (
  allowType: boolean,
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void,
  typeahead: TypeAheadController<object>,
  menuController: MenuController
): React.KeyboardEventHandler<HTMLInputElement> => {
  return React.useCallback(
    (event) => {
      if (
        !menuController.isOpen() &&
        (event.key === "ArrowDown" ||
          event.key === "ArrowUp" ||
          event.key === "Enter")
      ) {
        menuController.openMenu();
      }
      if (menuController.isOpen() && event.key === "Escape") {
        menuController.closeMenu();
      }
      if (event.key === "ArrowDown" || event.key === "ArrowUp") {
        menuController.focus();
        event.preventDefault();
      }

      if (event.key === "Enter" && event.currentTarget.value) {
        onKeyDown(event);
        typeahead.selectOption(typeahead.getOptionsCollection().getItems()[0]);
        menuController.closeMenu();
      }
    },
    [allowType, onKeyDown, typeahead, menuController]
  );
};
