import {
  ListItem,
  ListItemGraphic,
  ListItemText,
} from "@natera/material/lib/list";
import { Spinner } from "@natera/material/lib/progress";
import { ScrollContext } from "@natera/platform/lib/components/scrollbar";
import { HighlightedTextProvider } from "@natera/platform/lib/provider/highlightedText";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { getTypeAheadContext } from "./typeAheadContext";

export interface TypeAheadResultListProps<T> {
  className?: string;
  children: (option: T[], search?: string) => React.ReactNode;
  lastText?: React.ReactNode;
  notFoundText?: React.ReactNode;
}

export const messages = defineMessages({
  notFoundText: {
    id: "platform.typeahead.notFoundText",
    defaultMessage: "No matches found",
  },
  pageLimitAlert: {
    id: "platform.typeahead.pageLimitAlert",
    defaultMessage: `Only {pageLimit} first items displayed. You could narrow down results by typing matching value`,
  },
  loading: {
    id: "platform.typeahead.loading",
    defaultMessage: "loading",
  },
});

export const TypeAheadResultList = <T extends object>({
  className,
  children,
  lastText,
  notFoundText,
}: TypeAheadResultListProps<T>): React.ReactElement | null => {
  const intl = useIntl();
  const typeaheadController = React.useContext(getTypeAheadContext<T>());
  const optionsCollection = typeaheadController.getOptionsCollection();
  const pageLimit = typeaheadController.getPageLimit();
  const searchValue = typeaheadController.getInputValue();

  return (
    <ScrollContext component="menu" className={className} isStatic={false}>
      <ul className="typeahead-menu__items">
        {!optionsCollection.getResource().hasResource() && (
          <ListItem className="typeahead-item__message">
            <ListItemGraphic>
              <Spinner />
            </ListItemGraphic>
            <ListItemText>{intl.formatMessage(messages.loading)}</ListItemText>
          </ListItem>
        )}
        {optionsCollection.getResource().hasResource() &&
          !optionsCollection.isLoading() &&
          Boolean(optionsCollection.getItems().length) && (
            <>
              <HighlightedTextProvider text={searchValue}>
                {children(optionsCollection.getItems(), searchValue)}
              </HighlightedTextProvider>
              {optionsCollection.getItems().length >= pageLimit && (
                <ListItem className="typeahead-item__last-row">
                  <ListItemText>
                    {intl.formatMessage(messages.pageLimitAlert, {
                      pageLimit,
                    })}
                  </ListItemText>
                </ListItem>
              )}
              {optionsCollection.getItems().length < pageLimit && lastText && (
                <ListItem className="typeahead-item__last-row">
                  <ListItemText>{lastText}</ListItemText>
                </ListItem>
              )}
            </>
          )}
        {optionsCollection.getResource().hasResource() &&
          !optionsCollection.isLoading() &&
          !optionsCollection.getItems().length && (
            <ListItem className="typeahead-item__message">
              <ListItemText>
                {notFoundText || intl.formatMessage(messages.notFoundText)}
              </ListItemText>
            </ListItem>
          )}
      </ul>
    </ScrollContext>
  );
};
