import React, { FC } from "react";
import { Svg } from "../svg";

export interface LoadIconProps {
  icon?: React.ReactNode;
}

const LeadIcon: FC<LoadIconProps> = ({ icon }) => {
  if (icon) {
    if (typeof icon === "string") {
      return (
        <Svg
          className="mdc-text-field__icon mdc-text-field__icon--leading"
          content={icon}
        />
      );
    } else {
      return (
        <div
          className="mdc-text-field__icon mdc-text-field__icon--leading"
          role="button"
        >
          {icon}
        </div>
      );
    }
  }
  return null;
};

export default React.memo(LeadIcon);
