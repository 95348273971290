import { MenuContext } from "@natera/material/lib/menu";
import Svg from "@natera/material/lib/svg";
import { Input } from "@natera/platform/lib/components/form";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import "../attachment.scss";

import ImportIcon from "@natera/platform/assets/svg/icons/import.svg";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  isDisabled?: boolean;
  changeHandler: React.ChangeEventHandler<HTMLInputElement>;
  ref: React.MutableRefObject<HTMLInputElement>;
}

export const messages = defineMessages({
  upload: {
    id: "platform.attachment.upload",
    defaultMessage: "Upload from computer",
    description: "Upload from computer",
  },
});

const UploadButton: React.FunctionComponent<Props> = ({
  ref,
  isDisabled,
  changeHandler,
  ...props
}) => {
  const intl = useIntl();
  const { closeMenu } = React.useContext(MenuContext);
  const inputRef = React.useRef<HTMLInputElement>();

  const createInputRef = React.useCallback((element: HTMLInputElement) => {
    inputRef.current = element;
    if (ref) {
      if (ref instanceof Function) {
        ref(element);
      } else {
        ref.current = element;
      }
    }
  }, []);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    closeMenu();
    changeHandler(event);
  };

  const onKeydownHandlerFileUpload = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter" || e.key === " " || e.key === "Spacebar") {
        inputRef.current?.click();
      }
    },
    [inputRef.current]
  );

  return (
    <li
      className="mdc-deprecated-list-item"
      role="menuitem"
      tabIndex={-1}
      onKeyDown={onKeydownHandlerFileUpload}
    >
      <label className="attachment-menu-item-file-container attchment-button">
        <span className="mdc-deprecated-list-item__ripple"></span>
        <Svg content={ImportIcon} className="attachment-menu-item-icon" />
        <span className="attachment-menu-item-label">
          {intl.formatMessage(messages.upload)}
        </span>
        <Input
          {...props}
          ref={createInputRef}
          type="file"
          hidden={true}
          onChange={onChange}
          className="attachment-button-input"
          disabled={isDisabled}
        />
      </label>
    </li>
  );
};

export default UploadButton;
