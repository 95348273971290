import * as R from "ramda";
import * as React from "react";
import { getTypeAheadContext } from "../../typeAhead";

export interface MultiSelectGroupController<T> {
  getGroups: () => string[];
  getGroupOptions: (group: string) => T[];
  getUngroupedOptions: () => T[];
}

export interface MultiSelectGroupContextProps<T> {
  groupBy?: (item: T) => string | undefined;
  sortGroups?: (a: string, b: string) => number;
  sortItems?: (a: T, b: T) => number;
}

const MultiSelectGroupContext = React.createContext<
  MultiSelectGroupController<unknown>
>({
  getGroups: R.always([]),
  getGroupOptions: R.always([]),
  getUngroupedOptions: R.always([]),
});

export function getMultiSelectGroupContext<T extends object>() {
  return MultiSelectGroupContext as React.Context<
    MultiSelectGroupController<T>
  >;
}

export const MultiSelectGroupProvider = <T extends object>({
  groupBy,
  sortGroups,
  sortItems,
  children,
}: React.PropsWithChildren<
  MultiSelectGroupContextProps<T>
>): React.ReactElement | null => {
  const [groups, setGroups] = React.useState<{ [index: string]: T[] }>({});
  const typeaheadController = React.useContext(getTypeAheadContext<T>());
  const optionsCollection = typeaheadController.getOptionsCollection();

  React.useEffect(() => {
    if (groupBy) {
      const groupedItems = R.groupBy<T>((item) => {
        const group = groupBy(item);
        return group || "undefined";
      })(optionsCollection.getItems());
      setGroups(groupedItems);
    }
  }, [groupBy, optionsCollection.getItems]);

  const getGroups = React.useCallback(() => {
    const groupNames = Object.keys(groups).filter((key) => key !== "undefined");
    if (sortGroups) {
      groupNames.sort(sortGroups);
    }
    return groupNames;
  }, [groups]);

  const getGroupOptions = React.useCallback(
    (group: string) => {
      const groupOptions = groups[group] || [];
      if (sortItems) {
        groupOptions.sort(sortItems);
      }
      return groupOptions;
    },
    [groups, sortItems]
  );

  const getUngroupedOptions = React.useCallback(() => {
    const options = groups["undefined"] || [];
    if (sortItems) {
      options.sort(sortItems);
    }
    return options;
  }, [groups, sortItems]);

  const controller: MultiSelectGroupController<T> = React.useMemo(
    () => ({
      getGroups,
      getGroupOptions,
      getUngroupedOptions,
    }),
    [getGroups, getGroupOptions, getUngroupedOptions]
  );

  return (
    <MultiSelectGroupContext.Provider value={controller}>
      {children}
    </MultiSelectGroupContext.Provider>
  );
};
