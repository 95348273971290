import { DateRange, SlotTime } from "./definitions";

export const formatSlot = (
  dayStart: number | Date,
  slotTimeStart: number,
  slotTimeEnd: number
): DateRange => ({
  start: setDayTime(dayStart, slotTimeStart),
  end: setDayTime(dayStart, slotTimeEnd),
});

export const isDisabled = (
  slot: DateRange,
  selectedSlotsLength: number,
  maxSlots: number,
  availableFrom: number | Date,
  disabledSlots: DateRange[]
): boolean =>
  selectedSlotsLength >= maxSlots ||
  new Date(availableFrom).getTime() > slot.start.getTime() ||
  disabledSlots.some(
    (disabledSlot) => disabledSlot.start.getTime() === slot.start.getTime()
  );

interface DisabledDayProps {
  day: Date;
  slotTimes: SlotTime[];
  availableFrom: number | Date;
  disabledSlots: DateRange[];
}

export const isDisabledDay = ({
  day,
  slotTimes,
  availableFrom,
  disabledSlots,
}: DisabledDayProps): boolean =>
  slotTimes.every((slotTime) => {
    const slot = {
      start: setDayTime(day, slotTime.start),
      end: setDayTime(day, slotTime.end),
    };
    return isDisabled(slot, 0, 5, availableFrom, disabledSlots);
  });

export const isSelected = (
  selectedSlots: DateRange[],
  slot: DateRange
): boolean =>
  selectedSlots.some(
    (selectedSlot) => selectedSlot.start.getTime() === slot.start.getTime()
  );

export const addDays = (date: number | Date, days: number): Date => {
  const d = new Date(date);
  d.setUTCDate(d.getUTCDate() + days);
  return d;
};

export const setDayTime = (
  d: Date | number,
  hour: number,
  min = 0,
  sec = 0,
  ms = 0
): Date => {
  const res = new Date(d);
  res.setUTCHours(hour, min, sec, ms);
  return res;
};
