import * as React from "react";
import "./infoCard.scss";
import Svg from "@natera/material/lib/svg";

export interface InfoCardProps extends React.HTMLProps<HTMLDivElement> {
  icon: string;
  contentAside?: React.ReactNode;
  vertical?: boolean;
}
export const InfoCard: React.FC<InfoCardProps> = ({
  icon,
  vertical,
  contentAside,
  children,
  ...props
}) => (
  <div className="block-info-card" {...props}>
    <Svg className="block-info-card__icon" content={icon} />
    <div
      className={`block-info-card__content ${
        (vertical && "block-info-card__content--vertical") || ""
      }`}
    >
      {children}
    </div>
    {contentAside}
  </div>
);
