import classnames from "classnames";
import * as React from "react";

import "./header.scss";

export interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  tools?: React.ReactNode;
  breadcrumbs?: React.ReactNode;
}

export const Header: React.FunctionComponent<HeaderProps> = ({
  children,
  tools,
  breadcrumbs,
  className,
  ...props
}) => (
  <div
    {...props}
    className={classnames("layout-header", className, {
      "layout-header--with-breadcrumbs": Boolean(breadcrumbs),
    })}
  >
    {breadcrumbs && <div className="header-breadcrumbs">{breadcrumbs}</div>}
    <div className="header-content">
      {children}
      {tools && <div className="header-tools">{tools}</div>}
    </div>
  </div>
);

export default Header;
