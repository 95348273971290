import { AppUser, UserProvider } from "@natera/platform/lib/provider/user";
import {
  Credentials,
  CredentialsIdentityProvider,
  OktaTransactionWrapper,
  SessionServiceInterface,
} from "@natera/platform/lib/service";
import * as R from "ramda";
import * as React from "react";
import { fromEvent } from "rxjs";
import { filter } from "rxjs/operators";
import { useResource } from "../../hooks";

type Login = (credentials: Credentials) => Promise<OktaTransactionWrapper>;
type LoginIdentityProvider = (
  credentialsIdentityProvider: CredentialsIdentityProvider
) => Promise<void>;
type Logout = () => Promise<void>;
type IsLoading = () => boolean;

export interface AuthController {
  login: Login;
  loginIdentityProvider: LoginIdentityProvider;
  logout: Logout;
  isLoading: IsLoading;
}

export const AuthContext = React.createContext<AuthController>({
  login: Promise.reject,
  loginIdentityProvider: Promise.reject,
  logout: Promise.reject,
  isLoading: R.always(false),
});

interface Props<U extends AppUser> {
  sessionService: SessionServiceInterface<U>;
}

export const AuthProvider = <U extends AppUser>({
  sessionService,
  children,
}: React.PropsWithChildren<Props<U>>): React.ReactElement | null => {
  const oktaConfig = sessionService.getOktaConfig();

  const currentUserResource = useResource({
    load: sessionService.getUserData,
  });

  React.useEffect(() => {
    sessionService.start();
    return () => {
      sessionService.stop();
    };
  }, []);

  React.useEffect(() => {
    const storage = fromEvent<StorageEvent>(window, "storage")
      .pipe(
        filter((e) => e.key === oktaConfig.tokenManager?.storageKey),
        filter((e) => !e.newValue)
      )
      .subscribe(currentUserResource.clear);

    const subscription = sessionService
      .getTokenSubject()
      .subscribe(updateUserData);

    updateUserData();

    return () => {
      storage.unsubscribe();
      subscription.unsubscribe();
    };
  }, []);

  const updateUserData = async () => {
    currentUserResource.load();
  };

  const login: Login = async (credentials) => {
    return await sessionService.login(credentials);
  };

  const loginIdentityProvider: LoginIdentityProvider = async (
    credentialsIdentityProvider
  ) => {
    return await sessionService.loginIdentityProvider(
      credentialsIdentityProvider
    );
  };

  const logout: Logout = async () => {
    await sessionService.logout();
    currentUserResource.clear();
  };

  const isLoading: IsLoading = currentUserResource.isLoading;

  const controller: AuthController = {
    isLoading,
    loginIdentityProvider,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={controller}>
      <UserProvider user={currentUserResource.getResource() || undefined}>
        {children}
      </UserProvider>
    </AuthContext.Provider>
  );
};
