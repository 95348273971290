import {
  ListItem,
  ListItemMeta,
  ListItemText,
} from "@natera/material/lib/list";
import { Svg } from "@natera/material/lib/svg";
import ArrowDown from "@natera/platform/assets/svg/icons/arrow_down.svg";
import ArrowUp from "@natera/platform/assets/svg/icons/arrow_up.svg";
import classnames from "classnames";
import * as React from "react";
import "./expansionPanel.scss";

export interface ExpansionPanelProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "label" | "onClick"> {
  label?: string | React.ReactNode;
  defaultExpanded?: boolean;
  children?: React.ReactNode;
  onClick?: (expanded: boolean) => void;
  onIconClick?: (expanded: boolean) => void;
}

export const ExpansionPanel: React.FunctionComponent<ExpansionPanelProps> = ({
  defaultExpanded = false,
  label,
  children,
  onClick,
  onIconClick,
  ...props
}) => {
  const [expanded, setExpanded] = React.useState(defaultExpanded);

  React.useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  React.useEffect(() => {
    if (onIconClick) {
      return onIconClick(expanded);
    }

    if (onClick) {
      onClick(expanded);
    }
  }, [expanded]);

  const handleChange = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setExpanded((prevValue) => !prevValue);
  };

  const onPanelKeyPress = (e: React.KeyboardEvent<HTMLLIElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      handleChange(e);
    }
  };

  return (
    <div {...props} className="expansion-panel">
      <ListItem
        tabIndex={0}
        onKeyDown={onPanelKeyPress}
        onClick={
          onIconClick ? () => onClick && onClick(expanded) : handleChange
        }
      >
        <ListItemText>{label}</ListItemText>
        <ListItemMeta
          className="expansion-panel__icon"
          role={onIconClick ? "button" : "img"}
          onClick={onIconClick ? handleChange : undefined}
        >
          <Svg content={expanded ? ArrowUp : ArrowDown} />
        </ListItemMeta>
      </ListItem>
      <div
        className={classnames("expansion-panel__content", {
          "expansion-panel__content-expanded": expanded,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default ExpansionPanel;
