import { Button } from "@natera/platform/lib/components/form";
import classNames from "classnames";
import * as React from "react";

import "./tabSwitch.scss";

interface SwitchButton {
  active: boolean;
  icon?: string;
  checked?: boolean;
  title?: string;
  onClick: () => void;
}

interface Props extends React.HTMLProps<HTMLDivElement> {
  buttons: SwitchButton[];
}

/**
 * @deprecated The component should not be used. Better use SegmentedButton from @natera/material.
 */
export const TabSwitch: React.FC<Props> = ({ buttons, ...rest }) => {
  return (
    <div {...rest} className="tab-switch">
      {buttons.map(
        ({ active, icon, checked, title, onClick }: SwitchButton, index) => (
          <Button
            className={classNames("switch-button", { active })}
            icon={icon}
            outlined={true}
            aria-checked={checked}
            onClick={onClick}
            title={title}
            key={index}
          />
        )
      )}
    </div>
  );
};

export default TabSwitch;
