import classnames from "classnames";
import * as React from "react";

import "./drawer.scss";

export interface DrawerProps extends React.HTMLAttributes<HTMLElement> {
  header?: React.ReactNode;
}

const Drawer = React.forwardRef<HTMLElement, DrawerProps>(
  ({ header, className, children, ...props }, ref) => (
    <aside className={classnames("mdc-drawer", className)} {...props}>
      {header && <div className="mdc-drawer__header">{header}</div>}
      <div className="mdc-drawer__content">
        <nav ref={ref} className="mdc-deprecated-list">
          {children}
        </nav>
      </div>
    </aside>
  )
);

export default Drawer;
