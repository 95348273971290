"use client";

import { closest } from "@material/dom/ponyfill";
import {
  DefaultFocusState,
  MDCMenuAdapter,
  MDCMenuFoundation,
  MDCMenuItemEventDetail,
} from "@material/menu";
import { MDCMenuSurfaceFoundation } from "@material/menu-surface";
import { Corner } from "@material/menu-surface/constants";
import { List, VirtualizedList } from "@natera/material/lib/list";
import classnames from "classnames";
import * as R from "ramda";
import * as React from "react";
import {
  MenuContext,
  MenuController,
  MenuItemController,
  MenuProvider,
} from "./controller";
import MenuSurface, {
  MenuSurfaceProps,
  MenuSurfaceContext,
} from "./menuSurface";
import { MenuSurfaceAnchor } from "./menuSurfaceAnchor";

import "./menu.scss";

export { Corner };

const VIRTUALIZED_MENU_PADDING_TOP_AND_BOTTOM = 8;

export interface MenuProps extends Omit<MenuSurfaceProps, "children"> {
  menu: React.ReactNode;
  menuButtonRef?: React.MutableRefObject<HTMLDivElement | null>;
  children: (controller: MenuController) => React.ReactNode;
  className?: string;
  anchorClassName?: string;
  corner?: Corner;
  onClose?: () => void;
  onOpen?: () => void;
  autoFocus?: boolean;
  floating?: boolean;
  fixed?: boolean;
  quickOpen?: boolean;
  lazy?: boolean;
  twoLines?: boolean;
  dense?: boolean;
  menuCloseDelay?: number;
  singleSelection?: boolean;
  virtualized?: boolean;
  anchorAriaHidden?: boolean;
  disableAutoOpen?: boolean;
}

type MenuElement = HTMLDivElement;

const isTouchDevice = () =>
  Boolean(typeof window !== "undefined" && "ontouchstart" in window) ||
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Boolean(navigator?.maxTouchPoints || navigator?.msMaxTouchPoints);

export const Menu = React.forwardRef<MenuElement, MenuProps>(
  (
    {
      children,
      className,
      anchorClassName,
      corner,
      menu,
      autoFocus = true,
      lazy = true,
      twoLines = false,
      dense = false,
      singleSelection = false,
      virtualized = false,
      onOpen = R.always(undefined),
      onClose = R.always(undefined),
      menuCloseDelay = 150,
      anchorAriaHidden = false,
      disableAutoOpen = false,
      style,
      menuButtonRef,
      ...surfaceProps
    },
    ref: React.MutableRefObject<MenuElement>
  ) => {
    const parentMenuController = React.useContext(MenuContext);
    const isSubmenu = Boolean(parentMenuController.getParentController());
    const menuRef = React.useRef<MenuElement>();
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const listRef = React.useRef<HTMLUListElement>(null);
    const menuFoundationRef = React.useRef<MDCMenuFoundation>();
    const surfaceFoundationRef = React.useRef<MDCMenuSurfaceFoundation>();
    const itemControllersRef = React.useRef(
      new WeakMap<HTMLElement, MenuItemController>()
    );
    const timeoutRef = React.useRef<number>();
    const getDomItems = React.useCallback((): HTMLElement[] => {
      if (!listRef.current) {
        return [];
      }
      const items = listRef.current.querySelectorAll<HTMLElement>(
        '.mdc-deprecated-list-item[role="menuitem"]'
      );

      return Array.from(items);
    }, []);

    const clearMenuCloseDelay = React.useCallback(() => {
      clearTimeout(timeoutRef.current);
    }, []);
    const getDomItemAtIndex = React.useCallback((index: number):
      | HTMLElement
      | undefined => {
      const items = getDomItems();

      return items[index];
    }, []);

    const focusListRoot = React.useCallback(() => {
      listRef.current?.focus();
    }, []);

    const createAdapter = React.useCallback(
      (): MDCMenuAdapter => ({
        addClassToElementAtIndex: (index: number, className$: string) => {
          getDomItemAtIndex(index)?.classList.add(className$);
        },
        removeClassFromElementAtIndex: (index: number, className$: string) => {
          getDomItemAtIndex(index)?.classList.remove(className$);
        },
        addAttributeToElementAtIndex: (
          index: number,
          attr: string,
          value: string
        ) => {
          getDomItemAtIndex(index)?.setAttribute(attr, value);
        },
        getAttributeFromElementAtIndex: (index: number, attr: string) => {
          const element = getDomItemAtIndex(index);
          if (element) {
            return element.getAttribute(attr);
          }
          return null;
        },
        removeAttributeFromElementAtIndex: (index: number, attr: string) => {
          getDomItemAtIndex(index)?.removeAttribute(attr);
        },
        elementContainsClass: (element: Element, className$: string) =>
          element.classList.contains(className$),
        closeSurface: (skipRestoreFocus: boolean) => {
          surfaceFoundationRef.current?.close(skipRestoreFocus);
        },
        getElementIndex: (element: HTMLLIElement) =>
          getDomItems().indexOf(element),
        notifySelected: (evtData: MDCMenuItemEventDetail) => {
          const domItem = getDomItemAtIndex(evtData.index);
          if (!domItem) {
            return;
          }
          const itemController = itemControllersRef.current.get(domItem);
          itemController?.handleItemSelect();
        },
        getMenuItemCount: () => getDomItems().length,
        focusItemAtIndex: (index: number) => {
          getDomItemAtIndex(index)?.focus();
        },
        focusListRoot,
        isSelectableItemAtIndex: (index: number) => {
          const item = getDomItemAtIndex(index);
          if (!item) {
            return false;
          }

          return Boolean(
            closest(
              item,
              `.${MDCMenuFoundation.cssClasses.MENU_SELECTION_GROUP}`
            )
          );
        },
        getSelectedSiblingOfItemAtIndex: (index: number) => {
          const item = getDomItemAtIndex(index);
          if (!item) {
            return -1;
          }

          const selectionGroupEl = closest(
            item,
            `.${MDCMenuFoundation.cssClasses.MENU_SELECTION_GROUP}`
          ) as HTMLElement;
          const selectedItemEl = selectionGroupEl.querySelector<HTMLElement>(
            `.${MDCMenuFoundation.cssClasses.MENU_SELECTED_LIST_ITEM}`
          );
          return selectedItemEl ? getDomItems().indexOf(selectedItemEl) : -1;
        },
      }),
      []
    );

    const surfaceOpenHandler = React.useCallback(() => {
      menuFoundationRef.current?.handleMenuSurfaceOpened();
      onOpen();

      if (isSubmenu) {
        getDomItemAtIndex(0)?.focus();
      }
    }, [onOpen]);

    const surfaceCloseHandler = React.useCallback(
      (menuController: MenuController) => () => {
        onClose();
        menuController.closeMenu();
        menuButtonRef?.current?.focus();
      },
      [onClose]
    );

    const surfaceKeydownHandler = React.useCallback(
      (menuController: MenuController) => (evt: React.KeyboardEvent) => {
        if (evt.code === "Escape") {
          menuController.closeMenu();
          menuButtonRef?.current?.focus();
        }
        menuFoundationRef.current?.handleKeydown(evt.nativeEvent);
      },
      []
    );

    React.useEffect(() => {
      const foundation = new MDCMenuFoundation(createAdapter());
      menuFoundationRef.current = foundation;

      if (!autoFocus) {
        foundation.setDefaultFocusState(DefaultFocusState.NONE);
      }

      foundation.init();

      return () => {
        menuFoundationRef.current?.destroy();
        menuFoundationRef.current = undefined;
      };
    }, []);

    const itemActionHandler = React.useCallback((element: HTMLElement) => {
      menuFoundationRef.current?.handleItemAction(element);
    }, []);

    const hasItem = React.useCallback((element: HTMLElement) => {
      return getDomItems().indexOf(element) >= 0;
    }, []);

    const closeHandler = React.useCallback(() => {
      if (surfaceFoundationRef?.current?.isOpen()) {
        timeoutRef.current = window.setTimeout(
          () => surfaceFoundationRef?.current?.close(),
          menuCloseDelay
        );
      }
    }, []);

    const openHandler = React.useCallback(() => {
      clearMenuCloseDelay();
      if (!surfaceFoundationRef?.current?.isOpen()) {
        surfaceFoundationRef?.current?.open();
      }
    }, []);

    const focusHandler = React.useCallback(() => {
      getDomItemAtIndex(0)?.focus();
    }, []);

    const createMenuRef = React.useCallback((element: HTMLDivElement) => {
      menuRef.current = element;
      if (ref) {
        if (ref instanceof Function) {
          ref(element);
        } else {
          ref.current = element;
        }
      }
    }, []);

    const registerItem = React.useCallback((controller: MenuItemController) => {
      if (getDomItems().indexOf(controller.getElement()) === 0) {
        controller.getElement().tabIndex = 0;
      }

      itemControllersRef.current.set(controller.getElement(), controller);
    }, []);

    const touchDevice = React.useRef<boolean>();

    React.useEffect(() => {
      touchDevice.current = isTouchDevice();
    }, []);

    const getAnchorClickCaptureHandler = React.useCallback(
      (controller: MenuController) => (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
      ) => {
        if (!controller.isOpen()) {
          event.stopPropagation();
          controller.openMenu();
        }
      },
      []
    );

    const mouseEnterHandler = React.useCallback(
      (controller: MenuController) => () => {
        if (controller.isOpen()) {
          clearMenuCloseDelay();
        }
        controller.openMenu();
      },
      []
    );

    const anchorKeydownHandler = React.useCallback(
      (controller: MenuController) => (e: React.KeyboardEvent) => {
        if (e.key === "ArrowLeft") {
          controller.closeMenu();
          parentMenuController.focus();
          e.stopPropagation();
        }

        if (e.key === "ArrowRight") {
          if (controller.isOpen()) {
            clearMenuCloseDelay();
          }
          controller.openMenu();
        }
      },
      []
    );

    return (
      <MenuProvider
        onItemAction={itemActionHandler}
        hasItem={hasItem}
        onOpen={openHandler}
        onClose={closeHandler}
        registerItem={registerItem}
        focus={focusHandler}
      >
        <MenuContext.Consumer>
          {(menuController) => (
            <MenuSurfaceAnchor
              ref={anchorRef}
              onKeyDown={
                isSubmenu && !disableAutoOpen
                  ? anchorKeydownHandler(menuController)
                  : undefined
              }
              onMouseEnter={
                isSubmenu && !touchDevice.current && !disableAutoOpen
                  ? mouseEnterHandler(menuController)
                  : undefined
              }
              onMouseLeave={
                isSubmenu && !touchDevice.current && !disableAutoOpen
                  ? closeHandler
                  : undefined
              }
              onClickCapture={
                isSubmenu && touchDevice?.current
                  ? getAnchorClickCaptureHandler(menuController)
                  : undefined
              }
              style={style}
              aria-hidden={anchorAriaHidden}
              className={anchorClassName}
            >
              {children(menuController)}
              {(!lazy || menuController.isOpen()) && (
                <MenuSurface
                  className={classnames(
                    MDCMenuFoundation.cssClasses.ROOT,
                    className,
                    {
                      "mdc-menu--submenu": isSubmenu,
                      "menu-virtualized": virtualized,
                    }
                  )}
                  defaultOpen={menuController.isOpen()}
                  corner={corner}
                  anchorRef={anchorRef}
                  surfaceFoundationRef={surfaceFoundationRef}
                  onOpen={surfaceOpenHandler}
                  onClose={surfaceCloseHandler(menuController)}
                  onKeyDown={surfaceKeydownHandler(menuController)}
                  {...surfaceProps}
                  ref={createMenuRef}
                >
                  {virtualized ? (
                    <MenuSurfaceContext.Consumer>
                      {(menuSurfaceContext) => (
                        <VirtualizedList
                          role="menu"
                          aria-label="expanded"
                          aria-hidden="true"
                          aria-orientation="vertical"
                          tabIndex={0}
                          ref={listRef}
                          twoLines={twoLines}
                          dense={dense}
                          singleSelection={singleSelection}
                          height={menuSurfaceContext.maxHeight}
                          minimizeHeight={true}
                          paddingTopFirstElement={
                            VIRTUALIZED_MENU_PADDING_TOP_AND_BOTTOM
                          }
                          paddingBottomLastElement={
                            VIRTUALIZED_MENU_PADDING_TOP_AND_BOTTOM
                          }
                        >
                          {menu}
                        </VirtualizedList>
                      )}
                    </MenuSurfaceContext.Consumer>
                  ) : (
                    <List
                      role="menu"
                      aria-label="expanded"
                      aria-hidden="true"
                      aria-orientation="vertical"
                      tabIndex={0}
                      ref={listRef}
                      twoLines={twoLines}
                      dense={dense}
                      singleSelection={singleSelection}
                    >
                      {menu}
                    </List>
                  )}
                </MenuSurface>
              )}
            </MenuSurfaceAnchor>
          )}
        </MenuContext.Consumer>
      </MenuProvider>
    );
  }
);

export default Menu;
