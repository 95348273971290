import * as React from "react";

import "./highlightedText.scss";

export const HIGHLIGHTED_TEXT_PREV_CLASS = "highlighted-text-prev";
export const HIGHLIGHTED_TEXT_POST_CLASS = "highlighted-text-post";
export const HIGHLIGHTED_TEXT_CLASS = "highlighted-text";
const SYMBOLS_REGEXP = "[\\^\\+|\\(|\\)|\\_\\s]*?";

export const highlightText = (
  text?: string,
  highlightedText?: string
): JSX.Element => {
  const startIndexValue =
    text && highlightedText
      ? text.toLowerCase().indexOf(highlightedText.toLowerCase())
      : -1;

  if (highlightedText && text && startIndexValue > -1) {
    const current = text.substr(startIndexValue, highlightedText.length);
    const prev = text.slice(0, startIndexValue);
    const post = text.slice(startIndexValue + current.length);
    return (
      <>
        <span className={HIGHLIGHTED_TEXT_PREV_CLASS}>{prev}</span>
        <span className={HIGHLIGHTED_TEXT_CLASS}>{current}</span>
        <span className={HIGHLIGHTED_TEXT_POST_CLASS}>{post}</span>
      </>
    );
  } else {
    return <span>{text}</span>;
  }
};

interface TextArrayItem {
  text: string;
  type: "highlighted" | "common";
}

export const highlightTextAllEntries = (
  text?: string,
  highlightedText?: string
): JSX.Element => {
  if (!(highlightedText && text)) {
    return <span>{text}</span>;
  }

  const textArray: TextArrayItem[] = [];
  let index = 0;

  do {
    const currentIndex = text
      .toLowerCase()
      .indexOf(highlightedText.toLowerCase(), index);
    if (currentIndex > -1) {
      textArray.push({
        text: text.substring(index, currentIndex),
        type: "common",
      });
      textArray.push({
        text: text.substr(currentIndex, highlightedText.length),
        type: "highlighted",
      });
      index = currentIndex + highlightedText.length;
    } else {
      textArray.push({
        text: text.substring(index, text.length),
        type: "common",
      });
      index = text.length;
    }
  } while (index !== text.length);

  return (
    <>
      {textArray
        .filter((part) => part.text.length > 0)
        .map((part) => {
          const classForText =
            part.type === "highlighted"
              ? HIGHLIGHTED_TEXT_CLASS
              : HIGHLIGHTED_TEXT_PREV_CLASS;
          return <span className={classForText}>{part.text}</span>;
        })}
    </>
  );
};

export const highlightTextContainingCharacters = (
  text?: string,
  highlightedText?: string
): JSX.Element => {
  const maskedHighlightedText = highlightedText
    ?.split("")
    .map((i) => i.replace(/([()[*+?.^$\\|])/, "\\$1"))
    .join(SYMBOLS_REGEXP);
  const reg = new RegExp("^(.*?)(" + maskedHighlightedText + ")(.*)$");
  const match = text?.match(reg);

  if (match) {
    return (
      <>
        <span className={HIGHLIGHTED_TEXT_PREV_CLASS}>{match[1]}</span>
        <span className={HIGHLIGHTED_TEXT_CLASS}>{match[2]}</span>
        <span className={HIGHLIGHTED_TEXT_POST_CLASS}>{match[3]}</span>
      </>
    );
  } else {
    return <span>{text}</span>;
  }
};

export const highlightDate = (
  text?: string,
  highlightedText?: string
): JSX.Element => {
  const formattedDate = highlightedText?.replace(/^0/, "").replace(/\/0/, "/");
  if (text === formattedDate) {
    return <span className={HIGHLIGHTED_TEXT_CLASS}>{text}</span>;
  } else {
    return <span>{text}</span>;
  }
};

interface HighlightedTextController {
  highlightTextAllEntries: (text?: string) => JSX.Element;
  highlightText: (text?: string) => JSX.Element;
  highlightTextContainingCharacters: (text?: string) => JSX.Element;
  highlightDate: (text?: string) => JSX.Element;
}

interface Props {
  text?: string;
  children?: React.ReactNode;
}

export const HighlightedTextContext = React.createContext<
  HighlightedTextController
>({
  highlightTextAllEntries: (value) => <>{value}</>,
  highlightText: (value) => <>{value}</>,
  highlightTextContainingCharacters: (value) => <>{value}</>,
  highlightDate: (value) => <>{value}</>,
});

export const HighlightedTextProvider: React.FC<Props> = ({
  text: highlightedText,
  ...props
}) => {
  const controller: HighlightedTextController = React.useMemo(
    () => ({
      highlightTextAllEntries: (text) =>
        highlightTextAllEntries(text, highlightedText),
      highlightText: (text) => highlightText(text, highlightedText),
      highlightTextContainingCharacters: (text) =>
        highlightTextContainingCharacters(text, highlightedText),
      highlightDate: (text) => highlightDate(text, highlightedText),
    }),
    [highlightedText]
  );

  return <HighlightedTextContext.Provider value={controller} {...props} />;
};

export const useHighlightedSearch = () => {
  return React.useContext(HighlightedTextContext);
};
