"use client";

import * as React from "react";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import { MDCLineRippleFoundation } from "@material/line-ripple";
import { MDCNotchedOutlineFoundation } from "@material/notched-outline";
import {
  MDCFloatingLabelFoundation,
  cssClasses as floatingLabelCssClasses,
} from "@material/floating-label";
import {
  cssClasses,
  iconCssClasses,
  helperTextCssClasses,
  characterCountCssClasses,
} from "@material/textfield";
import { createTextfieldAdapter } from "./adapter";
import { createLineRippleAdapter } from "../../lineRipple/adapter";
import { createFloatingLabelAdapter } from "../../floatingLabel/adapter";
import { createNotchedOutlineAdapter } from "../../notchedOutline/adapter";
import TrailingIcon from "../trailingIcon";
import { TextFieldFoundation } from "./foundation";

import "./_index.scss";

export interface TextfieldProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>,
    "onClick"
  > {
  label?: string;
  outline?: boolean;
  selectable?: boolean;
  clearable?: boolean;
  leadingIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  disabled?: boolean;
  fullWidth?: boolean;
  filled?: boolean;
  helperText?: React.ReactNode;
  helperPersistent?: boolean;
  validate?: (value: string) => boolean;
  dense?: boolean;
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
  internalHelperLine?: boolean;
  reverseHelperLine?: boolean;
  forceActiveFocus?: boolean;
  trailingIconTabIndex?: number;
  onClear?: () => void;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const Textfield: React.FC<TextfieldProps> = ({
  id,
  outline,
  label,
  leadingIcon,
  trailingIcon,
  className,
  disabled,
  selectable,
  clearable,
  fullWidth,
  filled,
  helperText,
  helperPersistent = true,
  maxLength,
  onChange,
  onClick,
  validate,
  required,
  dense,
  multiline,
  minRows = 1,
  maxRows,
  internalHelperLine,
  reverseHelperLine,
  forceActiveFocus,
  trailingIconTabIndex = undefined,
  onClear,
  ...rest
}) => {
  const [uuid, setUuid] = React.useState(id);
  const labelWidth = React.useRef(0);
  const notchRef = React.useRef<HTMLDivElement | null>(null);
  const notchRootRef = React.useRef<HTMLDivElement | null>(null);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);
  const shadowRef = React.useRef<HTMLTextAreaElement | null>(null);
  const textfieldRef = React.useRef<HTMLDivElement | null>(null);
  const lineRippleRef = React.useRef<HTMLDivElement | null>(null);
  const labelRef = React.useRef<HTMLLabelElement | null>(null);
  const labelFoundationRef: React.MutableRefObject<MDCFloatingLabelFoundation | null> = React.useRef(
    null
  );
  const foundationRef: React.MutableRefObject<TextFieldFoundation | null> = React.useRef(
    null
  );
  const [currentValueLength, setCurrentValueLength] = React.useState(
    rest.value || rest.defaultValue
      ? String(rest.value || rest.defaultValue).length
      : 0
  );

  React.useEffect(() => {
    if (!uuid) {
      setUuid(uuidv4());
    }
  }, []);

  React.useEffect(() => {
    if (labelRef.current) {
      labelWidth.current = Math.ceil(
        labelRef.current?.getBoundingClientRect().width || 0
      );

      if (required) {
        labelWidth.current = labelWidth.current + 10;
      }
    }
  }, [label, required]);

  React.useEffect(() => {
    if (!required) {
      labelRef.current?.classList.remove(
        floatingLabelCssClasses.LABEL_REQUIRED
      );
    }
  }, [required]);

  React.useEffect(() => {
    foundationRef.current?.setValue(rest.value ? String(rest.value) : "");
  }, [rest.value]);

  React.useEffect(() => {
    const lineAdapter = createLineRippleAdapter(lineRippleRef);
    const lineFoundation = new MDCLineRippleFoundation(lineAdapter);
    lineFoundation.init();

    const labelAdapter = createFloatingLabelAdapter(labelRef, labelWidth);
    const labelFoundation = new MDCFloatingLabelFoundation(labelAdapter);
    labelFoundation.init();
    labelFoundationRef.current = labelFoundation;

    const notchAdapter = createNotchedOutlineAdapter({
      root: notchRootRef,
      notch: notchRef,
    });
    const notchFoundation = new MDCNotchedOutlineFoundation(notchAdapter);
    notchFoundation.init();

    const adapter = createTextfieldAdapter({
      textfieldRef,
      inputRef: multiline ? textareaRef : inputRef,
      label,
      outline,
      notchFoundation,
      lineFoundation,
      labelFoundation,
      validate,
    });
    const foundation = new TextFieldFoundation(adapter);
    foundation.init();

    foundationRef.current = foundation;

    return function cleanup() {
      notchFoundation.destroy();
      labelFoundation.destroy();
      lineFoundation.destroy();
      labelFoundationRef.current = null;
      foundation.destroy();
      foundationRef.current = null;
    };
  }, [outline, filled, required]);

  React.useEffect(() => {
    if (forceActiveFocus) {
      foundationRef.current?.activatePermanentFocus();
      return () => foundationRef.current?.deactivatePermanentFocus();
    }
  }, [forceActiveFocus]);

  const calculateTextareaStyles = React.useCallback(() => {
    if (!textareaRef.current?.offsetWidth) {
      return {
        outerHeightStyle: 0,
      };
    }

    const inputShallow = shadowRef.current!;

    inputShallow.style.width = (textareaRef.current?.offsetWidth || 0) + "px";
    inputShallow.value = textareaRef.current?.value || "x";

    let outerHeight = inputShallow.scrollHeight;

    // Measure height of a textarea with a single row
    inputShallow.value = "x";
    inputShallow.rows = 1;
    const singleRowHeight = inputShallow.scrollHeight;
    inputShallow.rows = minRows;

    if (minRows) {
      outerHeight = Math.max(Number(minRows) * singleRowHeight, outerHeight);
    }
    if (maxRows) {
      outerHeight = Math.min(Number(maxRows) * singleRowHeight, outerHeight);
    }
    outerHeight = Math.max(outerHeight, singleRowHeight);

    return { outerHeight };
  }, [maxRows, minRows]);

  const syncHeight = React.useCallback(() => {
    const textareaStyles = calculateTextareaStyles();

    if (!textareaRef.current || !textareaStyles.outerHeight) {
      return;
    }

    textareaRef.current.style.height = `${textareaStyles.outerHeight}px`;
  }, [calculateTextareaStyles]);

  const handleClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(
    (event) => {
      if (onClick) {
        onClick(event);
      }
      if (event.target === textfieldRef.current) {
        multiline ? textareaRef.current?.focus() : inputRef.current?.focus();
      }
    },
    []
  );

  const handleTextAreaChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      syncHeight();

      if (maxLength) {
        setCurrentValueLength(event.target.value.length);
      }

      if (onChange) {
        onChange(event);
      }
    },
    [maxLength, onChange]
  );

  const isClearable = React.useCallback(() => {
    if (!clearable) {
      return false;
    }
    if (disabled) {
      return false;
    }

    return Boolean(rest.value);
  }, [clearable, disabled, rest.value]);

  const trailingTabIndex: number | undefined = React.useMemo(() => {
    if (isClearable()) {
      return 0;
    }
    return trailingIconTabIndex;
  }, [isClearable, trailingIconTabIndex]);

  const hasTrailingIcon = trailingIcon || selectable || isClearable();

  return (
    <div
      className={classnames(className, "textfield-v2__container", {
        "textfield-v2__container--fullwidth": fullWidth,
        "textfield-v2__container--internal-helper-line": internalHelperLine,
      })}
    >
      <div
        ref={textfieldRef}
        onClick={handleClick}
        className={classnames("textfield-v2", cssClasses.ROOT, {
          "textfield-v2--dense": dense,
          [cssClasses.DISABLED]: disabled,
          [cssClasses.OUTLINED]: outline,
          [cssClasses.WITH_LEADING_ICON]: !!leadingIcon,
          [cssClasses.WITH_TRAILING_ICON]: !!hasTrailingIcon,
          [cssClasses.TEXTAREA]: multiline,
          "mdc-text-field--fullwidth": fullWidth,
          "mdc-text-field--filled": filled,
        })}
      >
        {leadingIcon && (
          <div className={iconCssClasses.ROOT}>{leadingIcon}</div>
        )}
        {hasTrailingIcon && (
          <div
            className={classnames(iconCssClasses.ROOT, {
              "mdc-text-field__icon--selectable":
                selectable && !isClearable() && !trailingIcon,
            })}
            tabIndex={trailingTabIndex}
          >
            <TrailingIcon
              trailingIcon={trailingIcon}
              isClearable={isClearable()}
              onClear={onClear}
              selectable={selectable}
              dropDownActive={forceActiveFocus}
            />
          </div>
        )}

        {!multiline && (
          <input
            id={uuid}
            ref={inputRef}
            required={required}
            disabled={disabled}
            maxLength={maxLength}
            className="mdc-text-field__input"
            onChange={(event) => {
              if (maxLength) {
                setCurrentValueLength(event.target.value.length);
              }

              if (onChange) {
                onChange(event);
              }
            }}
            {...rest}
          />
        )}

        {multiline && (
          <>
            <textarea
              id={uuid}
              ref={textareaRef}
              required={required}
              disabled={disabled}
              maxLength={maxLength}
              className="mdc-text-field__input"
              onChange={handleTextAreaChange}
              aria-label={label}
              rows={minRows}
              {...rest}
            />
            <textarea
              readOnly
              aria-hidden
              rows={minRows}
              tabIndex={-1}
              ref={shadowRef}
              className="mdc-text-field__input mdc-text-field__input--hidden"
            />
          </>
        )}
        {!outline && (
          <>
            <div ref={lineRippleRef} className="mdc-line-ripple" />
            <label
              htmlFor={uuid}
              className={floatingLabelCssClasses.ROOT}
              ref={labelRef}
            >
              {label}
            </label>
          </>
        )}
        {outline && (
          <div className="mdc-notched-outline" ref={notchRootRef}>
            <div className="mdc-notched-outline__leading"></div>
            <div className="mdc-notched-outline__notch" ref={notchRef}>
              <label
                htmlFor={uuid}
                className={floatingLabelCssClasses.ROOT}
                ref={labelRef}
              >
                {label}
              </label>
            </div>
            <div className="mdc-notched-outline__trailing"></div>
          </div>
        )}
      </div>
      <div
        className={classnames(cssClasses.HELPER_LINE, {
          "mdc-text-field-helper-line--reversed": reverseHelperLine,
        })}
        style={{ maxWidth: textfieldRef.current?.offsetWidth }}
      >
        {helperText && (
          <div
            className={classnames(helperTextCssClasses.ROOT, {
              [helperTextCssClasses.HELPER_TEXT_VALIDATION_MSG]: helperPersistent,
              [helperTextCssClasses.HELPER_TEXT_PERSISTENT]: helperPersistent,
              "mdc-text-field-helper-text--with-counter": !!maxLength,
            })}
          >
            {helperText}
          </div>
        )}

        {Number.isInteger(maxLength) && (
          <div
            className={classnames(
              characterCountCssClasses.ROOT,
              helperTextCssClasses.HELPER_TEXT_VALIDATION_MSG
            )}
          >
            {currentValueLength} / {maxLength}
          </div>
        )}
      </div>
    </div>
  );
};

export default Textfield;
