import { HttpCollection } from "@natera/platform/lib/service";
import * as R from "ramda";
import * as React from "react";
import { empty, Observable } from "rxjs";
import { PaginationController } from "../usePagination";
import { useProxyStorage } from "../useProxyStorage";
import {
  defaultResourceController,
  ResourceController,
  useResource,
} from "../useResource";
import { Index, StorageController } from "../useStorage";

type GetItems<T> = () => T[];
type LoadCollection = () => void;
type IsLoading = () => boolean;
type GetResource<T> = () => ResourceController<T>;
type AsObservable<T> = () => Observable<HttpCollection<T>>;
type DelayTime = number;

export interface CollectionController<T> {
  isLoading: IsLoading;
  getItems: GetItems<T>;
  load: LoadCollection;
  getResource: GetResource<HttpCollection<T>>;
  asObservable: AsObservable<T>;
  delayTime?: DelayTime;
}

export const defaultCollectionController: CollectionController<unknown> = {
  isLoading: R.always(false),
  getItems: R.always([]),
  load: R.always(undefined),
  getResource: R.always(defaultResourceController),
  asObservable: R.always(empty()),
  delayTime: 75,
};

interface Props<T> {
  load: () => Promise<HttpCollection<T>>;
  storage?: StorageController<T>;
  pagination?: PaginationController<string>;
  delayTime?: DelayTime;
  initialRecords?: T[];
}

export const useCollection = <T>({
  load,
  storage,
  delayTime,
  initialRecords,
}: Props<T>): CollectionController<T> => {
  interface InternalState {
    index: Index | undefined;
    record: T;
  }

  const denormalize = React.useCallback(
    (collection: HttpCollection<InternalState>) => {
      const mappedCollection = collection.map((item) => {
        if (storage) {
          return item.index ? storage.getRecord(item.index) : item.record;
        }

        return item.record;
      });

      return new HttpCollection(
        mappedCollection
          .getItems()
          .filter((value): value is T => Boolean(value)),
        mappedCollection.getTotal()
      );
    },
    [storage?.getRecord]
  );

  const normalize = React.useCallback(
    (httpCollection: HttpCollection<T>) =>
      httpCollection.map((item) => ({
        index: storage && storage.recordIndex(item),
        record: item,
      })),
    []
  );

  const proxyStorage = useProxyStorage<
    HttpCollection<InternalState>,
    HttpCollection<T>
  >({
    recordIndex: R.always("collection"),
    normalize,
    denormalize,
  });

  const resourceController = useResource<HttpCollection<T>>({
    load,
    storage: proxyStorage,
    delayTime,
    initialValue:
      initialRecords && new HttpCollection(initialRecords, Infinity),
  });

  // Sync up collection to storage
  React.useLayoutEffect(() => {
    const subscription = resourceController
      .asObservable()
      .subscribe((collection) => {
        if (storage) {
          const items = collection.getItems();
          storage.addRecords(items);
        }
      });

    return () => subscription.unsubscribe();
  }, []);

  const defaultItems: T[] = React.useMemo(() => [], []);
  const getItems: GetItems<T> = React.useCallback(() => {
    return resourceController.maybe(defaultItems, (collection) =>
      collection.getItems()
    );
  }, [resourceController.maybe]);

  return React.useMemo<CollectionController<T>>(
    () => ({
      getItems,
      load: resourceController.load,
      isLoading: resourceController.isLoading,
      asObservable: resourceController.asObservable,
      getResource: R.always(resourceController),
      delayTime,
    }),
    [getItems, resourceController]
  );
};
