import { AccessToken } from "@natera/platform/lib/service";
import * as React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import ViewPdfError, { Props as ErrorProps } from "./viewError";
import "./viewPdf.scss";

export interface Props extends React.HTMLProps<HTMLDivElement> {
  url: string;
  authToken?: AccessToken | null;
  pageNumber?: number;
  errorPage: ErrorProps;
}

export const PdfViewer: React.FunctionComponent<Props> = ({
  url,
  authToken,
  pageNumber = 1,
  errorPage = {},
  ...rest
}) => {
  const [scale, setScale] = React.useState<number>(devicePixelRatio);

  React.useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  React.useLayoutEffect(() => {
    const updateScale = () => setScale(devicePixelRatio);
    addEventListener("resize", updateScale);
    return () => removeEventListener("resize", updateScale);
  }, []);

  const file = {
    url,
    httpHeaders: {
      Authorization: `Bearer ${authToken ? authToken.accessToken : ""}`,
    },
  };

  return (
    <div {...rest} className="public-page pdf-document">
      <Document file={file} error={<ViewPdfError {...errorPage} />}>
        <Page
          scale={scale}
          pageNumber={pageNumber}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      </Document>
    </div>
  );
};

export default PdfViewer;
