"use client";

import Icon from "@natera/material/assets/svg/dropdown.svg";
import Svg from "@natera/material/lib/svg";
import classnames from "classnames";
import * as React from "react";
import "./dropdownicon.scss";

interface Props extends React.HTMLAttributes<HTMLElement> {
  focused?: boolean;
}

export const DropDownIcon: React.FunctionComponent<Props> = ({
  className,
  onClick,
  focused,
  ...rest
}) => (
  <Svg
    {...rest}
    className={classnames("dropdown-icon", className, {
      "dropdown-icon--focused": focused,
    })}
    content={Icon}
    onClick={onClick}
  />
);

export default DropDownIcon;
