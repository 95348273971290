import { MDCFloatingLabelAdapter } from "@material/floating-label";

export const createFloatingLabelAdapter = (
  labelRef: React.RefObject<HTMLLabelElement | null | undefined>,
  labelWidth: React.RefObject<number>
): MDCFloatingLabelAdapter => ({
  addClass: (className) => {
    labelRef.current?.classList.add(className);
  },
  removeClass: (className) => {
    labelRef.current?.classList.remove(className);
  },
  registerInteractionHandler: (eventType, handler) => {
    labelRef.current?.addEventListener(eventType, handler);
  },
  deregisterInteractionHandler: (eventType, handler) => {
    labelRef.current?.removeEventListener(eventType, handler);
  },
  getWidth: () => labelWidth.current || 0,
});
