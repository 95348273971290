import * as React from "react";
import "./empty.scss";

interface Props extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
  isEmpty: boolean;
  title: string;
  description?: string | React.ReactNode;
  actions?: React.ReactNode;
  component?: React.ElementType;
}

export const EmptyContext: React.FunctionComponent<Props> = ({
  children,
  title,
  description,
  actions,
  isEmpty,
  component: Component = "div",
  ...rest
}) => {
  return (
    <>
      {isEmpty ? (
        <Component {...rest} className="empty-content">
          <h2 className="empty-title">{title}</h2>
          {description && (
            <div className="empty-description">{description}</div>
          )}
          {actions && <div className="empty-actions">{actions}</div>}
        </Component>
      ) : (
        children
      )}
    </>
  );
};

export default EmptyContext;
