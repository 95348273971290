import * as React from "react";
import "./breadcrumbs.scss";

interface Props extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactNode;
}

const Breadcrumbs: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <div {...rest} className="breadcrumbs">
      {children}
    </div>
  );
};

export default Breadcrumbs;
