import * as R from "ramda";
import * as React from "react";

type Component<S extends React.ComponentType> =
  | React.ComponentType
  | [S, React.ComponentProps<S>];

type ComposeType = <
  T1 extends React.ComponentType,
  T2 extends React.ComponentType,
  T3 extends React.ComponentType,
  T4 extends React.ComponentType,
  T5 extends React.ComponentType,
  T6 extends React.ComponentType,
  T7 extends React.ComponentType,
  T8 extends React.ComponentType,
  T9 extends React.ComponentType,
  T10 extends React.ComponentType,
  T11 extends React.ComponentType,
  T12 extends React.ComponentType,
  T13 extends React.ComponentType,
  T14 extends React.ComponentType,
  T15 extends React.ComponentType
>({
  components,
  children,
}: {
  components:
    | [Component<T1>]
    | [Component<T1>, Component<T2>]
    | [Component<T1>, Component<T2>, Component<T3>]
    | [Component<T1>, Component<T2>, Component<T3>, Component<T4>]
    | [
        Component<T1>,
        Component<T2>,
        Component<T3>,
        Component<T4>,
        Component<T5>
      ]
    | [
        Component<T1>,
        Component<T2>,
        Component<T3>,
        Component<T4>,
        Component<T5>,
        Component<T6>
      ]
    | [
        Component<T1>,
        Component<T2>,
        Component<T3>,
        Component<T4>,
        Component<T5>,
        Component<T6>,
        Component<T7>
      ]
    | [
        Component<T1>,
        Component<T2>,
        Component<T3>,
        Component<T4>,
        Component<T5>,
        Component<T6>,
        Component<T7>,
        Component<T8>
      ]
    | [
        Component<T1>,
        Component<T2>,
        Component<T3>,
        Component<T4>,
        Component<T5>,
        Component<T6>,
        Component<T7>,
        Component<T8>,
        Component<T9>
      ]
    | [
        Component<T1>,
        Component<T2>,
        Component<T3>,
        Component<T4>,
        Component<T5>,
        Component<T6>,
        Component<T7>,
        Component<T8>,
        Component<T9>,
        Component<T10>
      ]
    | [
        Component<T1>,
        Component<T2>,
        Component<T3>,
        Component<T4>,
        Component<T5>,
        Component<T6>,
        Component<T7>,
        Component<T8>,
        Component<T9>,
        Component<T10>,
        Component<T11>
      ]
    | [
        Component<T1>,
        Component<T2>,
        Component<T3>,
        Component<T4>,
        Component<T5>,
        Component<T6>,
        Component<T7>,
        Component<T8>,
        Component<T9>,
        Component<T10>,
        Component<T11>,
        Component<T12>
      ]
    | [
        Component<T1>,
        Component<T2>,
        Component<T3>,
        Component<T4>,
        Component<T5>,
        Component<T6>,
        Component<T7>,
        Component<T8>,
        Component<T9>,
        Component<T10>,
        Component<T11>,
        Component<T12>,
        Component<T13>
      ]
    | [
        Component<T1>,
        Component<T2>,
        Component<T3>,
        Component<T4>,
        Component<T5>,
        Component<T6>,
        Component<T7>,
        Component<T8>,
        Component<T9>,
        Component<T10>,
        Component<T11>,
        Component<T12>,
        Component<T13>
      ]
    | [
        Component<T1>,
        Component<T2>,
        Component<T3>,
        Component<T4>,
        Component<T5>,
        Component<T6>,
        Component<T7>,
        Component<T8>,
        Component<T9>,
        Component<T10>,
        Component<T11>,
        Component<T12>,
        Component<T13>,
        Component<T14>
      ]
    | [
        Component<T1>,
        Component<T2>,
        Component<T3>,
        Component<T4>,
        Component<T5>,
        Component<T6>,
        Component<T7>,
        Component<T8>,
        Component<T9>,
        Component<T10>,
        Component<T11>,
        Component<T12>,
        Component<T13>,
        Component<T14>,
        Component<T15>
      ];
  children: React.ReactNode;
}) => React.ReactElement;

export const Compose: ComposeType = ({ components, children }) => (
  <>
    {R.reduceRight(
      (currentValue, accumulator) => {
        const [Component$, props] = Array.isArray(currentValue)
          ? [currentValue[0], currentValue[1]]
          : [currentValue, {}];

        // @ts-ignore
        return <Component$ {...props}>{accumulator}</Component$>;
      },
      children,
      components
    )}
  </>
);

export default Compose;
