import classnames from "classnames";
import * as React from "react";

import "./svg.scss";

interface SvgProps extends React.HTMLAttributes<HTMLInputElement> {
  content: string;
  role?: string;
}

export const Svg: React.FC<SvgProps> = ({ content, className, ...props }) => (
  <>
    <i
      {...props}
      aria-labelledby={props.title ? "icon-title" : undefined}
      className={classnames("svg", className)}
      dangerouslySetInnerHTML={{ __html: content }}
    />
    {props.title && (
      <span id="icon-title" hidden={true}>
        {props.title}
      </span>
    )}
  </>
);

export default Svg;
