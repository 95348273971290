(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("classnames"), require("@natera/material/lib/layout"), require("@natera/material/lib/checkbox"), require("ramda"), require("@natera/platform/lib/components/form/field"), require("react-intl"), require("@natera/platform/lib/components/link"), require("@natera/material/lib/svg"), require("@natera/platform/lib/components/form"), require("@natera/material/lib/card"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "classnames", "@natera/material/lib/layout", "@natera/material/lib/checkbox", "ramda", "@natera/platform/lib/components/form/field", "react-intl", "@natera/platform/lib/components/link", "@natera/material/lib/svg", "@natera/platform/lib/components/form", "@natera/material/lib/card"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("classnames"), require("@natera/material/lib/layout"), require("@natera/material/lib/checkbox"), require("ramda"), require("@natera/platform/lib/components/form/field"), require("react-intl"), require("@natera/platform/lib/components/link"), require("@natera/material/lib/svg"), require("@natera/platform/lib/components/form"), require("@natera/material/lib/card")) : factory(root["react"], root["classnames"], root["@natera/material/lib/layout"], root["@natera/material/lib/checkbox"], root["ramda"], root["@natera/platform/lib/components/form/field"], root["react-intl"], root["@natera/platform/lib/components/link"], root["@natera/material/lib/svg"], root["@natera/platform/lib/components/form"], root["@natera/material/lib/card"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, function(__WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__310__, __WEBPACK_EXTERNAL_MODULE__724__, __WEBPACK_EXTERNAL_MODULE__399__, __WEBPACK_EXTERNAL_MODULE__288__, __WEBPACK_EXTERNAL_MODULE__844__, __WEBPACK_EXTERNAL_MODULE__767__, __WEBPACK_EXTERNAL_MODULE__939__, __WEBPACK_EXTERNAL_MODULE__289__, __WEBPACK_EXTERNAL_MODULE__437__, __WEBPACK_EXTERNAL_MODULE__747__) {
return 