import * as React from "react";
import { DateRange } from "../../definitions";
import { addDays } from "../../helpers";

interface Props {
  focusDay: Date;
  focusWeek: Date;
  endWeek?: Date;
  children?: React.ReactNode;
  renderSelectedSlotTime?: (slot: DateRange) => React.ReactNode;
  renderSlotChipTime?: (slot: DateRange) => React.ReactNode;
  setRemoveTimeslotAriaLabel?: (slot: DateRange) => string;
}

export interface TimeslotsController {
  focusDay: Date;
  focusWeek: Date;
  updateDay: (date: Date) => void;
  updateWeek: (date: Date) => void;
  renderSelectedSlotTime?: (slot: DateRange) => React.ReactNode;
  renderSlotChipTime?: (slot: DateRange) => React.ReactNode;
  setRemoveTimeslotAriaLabel?: (slot: DateRange) => string;
}

export const TimeslotsContext = React.createContext<TimeslotsController>({
  focusDay: new Date(),
  focusWeek: new Date(),
  updateDay: () => undefined,
  updateWeek: () => undefined,
  renderSelectedSlotTime: undefined,
  renderSlotChipTime: undefined,
  setRemoveTimeslotAriaLabel: undefined,
});

export const TimeslotsProvider: React.FunctionComponent<Props> = ({
  focusDay,
  focusWeek,
  endWeek,
  children,
  renderSlotChipTime,
  renderSelectedSlotTime,
  setRemoveTimeslotAriaLabel,
}) => {
  const [week, setWeek] = React.useState<Date>(focusWeek);
  const [day, setDay] = React.useState<Date>(focusDay);

  const updateDay = React.useCallback(
    (date: Date) => {
      if (endWeek && date <= addDays(endWeek, 6)) {
        setDay(date);
      }
    },
    [endWeek]
  );

  const updateWeek = React.useCallback(
    (date: Date) => {
      if (endWeek && date <= endWeek) {
        setWeek(date);
      }
    },
    [endWeek]
  );

  const controller: TimeslotsController = React.useMemo(
    () => ({
      focusDay: day,
      focusWeek: week,
      updateDay,
      updateWeek,
      renderSelectedSlotTime: renderSelectedSlotTime,
      renderSlotChipTime: renderSlotChipTime,
      setRemoveTimeslotAriaLabel: setRemoveTimeslotAriaLabel,
    }),
    [
      day,
      week,
      updateDay,
      updateWeek,
      renderSelectedSlotTime,
      renderSlotChipTime,
      setRemoveTimeslotAriaLabel,
    ]
  );

  return (
    <TimeslotsContext.Provider value={controller}>
      {children}
    </TimeslotsContext.Provider>
  );
};

export const useTimeslots = (): TimeslotsController => {
  return React.useContext<TimeslotsController>(TimeslotsContext);
};
