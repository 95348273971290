"use client";

import MenuWrapper, {
  Corner,
  MenuContext,
  MenuController,
} from "@natera/material/lib/menu";
import * as R from "ramda";
import * as React from "react";
import { DayPicker, DayPickerProps } from "../";

export interface DayPickerWrapperProps extends DayPickerProps {
  children: (controller: MenuController) => React.ReactNode;
  disableAutoOpen?: boolean;
}

export const DayPickerWrapper: React.FunctionComponent<DayPickerWrapperProps> = ({
  children,
  disableAutoOpen = true,
  onDateChange = R.always(undefined),
  ...props
}) => {
  const onKeydownHandlerMenu = (e: React.KeyboardEvent) => {
    if (e.key === "Tab") {
      e.stopPropagation();
    }
  };

  return (
    <MenuWrapper
      className="date-field-menu"
      corner={Corner.BOTTOM_START}
      autoFocus={false}
      onKeyDown={onKeydownHandlerMenu}
      anchorAriaHidden={true}
      disableAutoOpen={disableAutoOpen}
      menu={
        <MenuContext.Consumer>
          {(menu) => {
            const onDateChange$ = (date: Date) => {
              onDateChange(date);
              menu.closeMenu();
            };
            return <DayPicker {...props} onDateChange={onDateChange$} />;
          }}
        </MenuContext.Consumer>
      }
    >
      {children}
    </MenuWrapper>
  );
};
