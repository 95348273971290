"use client";

import {
  MDCTabBar,
  MDCTabBarActivatedEvent,
  MDCTabBarFoundation,
} from "@material/tab-bar";
import { MDCTabScroller } from "@material/tab-scroller";
import { IconButton } from "@natera/material/lib/button";
import classnames from "classnames";
import * as R from "ramda";
import * as React from "react";

import ArrowLeft from "@natera/material/assets/svg/arrow_leftward.svg";
import ArrowRight from "@natera/material/assets/svg/arrow_rightward.svg";
import "./bar.scss";

export type TabBarProps = React.HTMLAttributes<HTMLElement> & {
  useArrows?: boolean;
};

export const TabBar: React.FunctionComponent<TabBarProps> = ({
  children,
  className,
  useArrows = true,
  ...props
}) => {
  const tabBarRef = React.useRef<HTMLDivElement>(null);
  const scrollerRef = React.useRef<HTMLDivElement>(null);
  const mdcTabScrollerRef = React.useRef<MDCTabScroller>();
  const mdcTabBarRef = React.useRef<MDCTabBar>();

  const [position, setPosition] = React.useState<number>();
  const [isRightButtonDisabled, setRightButtonDisabled] = React.useState<
    boolean
  >(true);
  const [isLeftButtonDisabled, setLeftButtonDisabled] = React.useState<boolean>(
    true
  );
  const [isButtonScrollEnabled, setButtonScrollEnabled] = React.useState<
    boolean
  >(true);

  const handlePositionChange = (
    event: React.TransitionEvent<HTMLDivElement>
  ) => {
    if (event.propertyName !== "transform") {
      return;
    }
    const position$ =
      mdcTabScrollerRef.current &&
      Math.round(mdcTabScrollerRef.current.getScrollPosition());

    if (
      !R.isNil(position$) &&
      scrollerRef?.current &&
      mdcTabScrollerRef.current
    ) {
      setPosition(position$);

      setRightButtonDisabled(
        position$ + scrollerRef?.current.clientWidth >=
          mdcTabScrollerRef.current.getScrollContentWidth()
      );
      setLeftButtonDisabled(position$ <= 0);
    }
  };

  const scrollActiveTabIntoView = (event: MDCTabBarActivatedEvent) =>
    mdcTabBarRef.current?.scrollIntoView(event.detail.index);

  React.useEffect(() => {
    if (!tabBarRef.current || !scrollerRef.current) {
      return undefined;
    }

    const scroller = new MDCTabScroller(scrollerRef.current);
    mdcTabScrollerRef.current = scroller;
    const tabBar = new MDCTabBar(tabBarRef.current);
    mdcTabBarRef.current = tabBar;

    tabBar.listen(
      MDCTabBarFoundation.strings.TAB_ACTIVATED_EVENT,
      scrollActiveTabIntoView
    );

    const position$ = Math.round(scroller.getScrollPosition());
    setPosition(position$);

    setRightButtonDisabled(
      position$ + scrollerRef?.current.clientWidth >=
        scroller.getScrollContentWidth()
    );
    setLeftButtonDisabled(position$ <= 0);
    setButtonScrollEnabled(
      scroller.getScrollContentWidth() > scrollerRef.current.clientWidth
    );

    const showLeftRightButtonsOnResize = () => {
      if (mdcTabScrollerRef.current && scrollerRef?.current) {
        setButtonScrollEnabled(
          mdcTabScrollerRef.current.getScrollContentWidth() >
            scrollerRef.current.clientWidth
        );
        if (!R.isNil(position)) {
          setRightButtonDisabled(
            position + scrollerRef?.current.clientWidth >=
              mdcTabScrollerRef.current.getScrollContentWidth()
          );
          setLeftButtonDisabled(position <= 0);
        }
      }
    };

    window.addEventListener("resize", showLeftRightButtonsOnResize);

    return () => {
      window.removeEventListener("resize", showLeftRightButtonsOnResize);
      tabBar.unlisten(
        MDCTabBarFoundation.strings.TAB_ACTIVATED_EVENT,
        scrollActiveTabIntoView
      );
      mdcTabScrollerRef.current = undefined;
      mdcTabBarRef.current = undefined;
    };
  }, [scrollerRef]);

  const onLeftArrowClick = () => {
    const tabBarWidth = scrollerRef?.current?.clientWidth;
    if (tabBarWidth) {
      mdcTabScrollerRef.current?.incrementScroll(-tabBarWidth);
    }
  };

  const onRightArrowClick = () => {
    const tabBarWidth = scrollerRef?.current?.clientWidth;

    if (tabBarWidth) {
      mdcTabScrollerRef.current?.incrementScroll(tabBarWidth);
    }
  };

  return (
    <div className="mdc-tab-bar--container">
      {isButtonScrollEnabled && useArrows && (
        <IconButton onClick={onLeftArrowClick} disabled={isLeftButtonDisabled}>
          {ArrowLeft}
        </IconButton>
      )}
      <nav
        {...props}
        className={classnames(className, "mdc-tab-bar")}
        role="tablist"
        ref={tabBarRef}
      >
        <div className="mdc-tab-scroller" ref={scrollerRef}>
          <div className="mdc-tab-scroller__scroll-area">
            <div
              className="mdc-tab-scroller__scroll-content"
              onTransitionEnd={handlePositionChange}
            >
              {children}
            </div>
          </div>
        </div>
      </nav>
      {isButtonScrollEnabled && useArrows && (
        <IconButton
          onClick={onRightArrowClick}
          disabled={isRightButtonDisabled}
        >
          {ArrowRight}
        </IconButton>
      )}
    </div>
  );
};

export default TabBar;
