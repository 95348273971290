import classnames from "classnames";
import * as React from "react";

type CellAlign = "top" | "bottom" | "middle";

export const ALIGN_TOP: CellAlign = "top";
export const ALIGN_MIDDLE: CellAlign = "middle";
export const ALIGN_BOTTOM: CellAlign = "bottom";

export interface GridCellProps extends React.HTMLAttributes<HTMLDivElement> {
  span?: number;
  order?: number;
  align?: CellAlign;
}

export const GridCell: React.FunctionComponent<GridCellProps> = ({
  children,
  className,
  span = 0,
  order = 0,
  align,
  ...props
}) => (
  <div
    {...props}
    className={classnames([
      "mdc-layout-grid__cell",
      {
        [`mdc-layout-grid__cell--span-${span}`]: span,
        [`mdc-layout-grid__cell--order-${order}`]: order,
        [`mdc-layout-grid__cell--align-${align}`]: align,
      },
      className,
    ])}
  >
    {children}
  </div>
);

export default GridCell;
