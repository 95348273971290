import { HttpError } from "@natera/platform/lib/service";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import Error from "./error";

interface Props extends React.HTMLProps<HTMLDivElement> {
  error: HttpError;
}

export const messages = defineMessages({
  serverTitle: {
    id: "platform.serverError.title",
    defaultMessage: "Server error",
  },
});

const ServerError: React.FunctionComponent<Props> = ({ error, ...rest }) => {
  const intl = useIntl();
  const title = <>{intl.formatMessage(messages.serverTitle)}</>;

  return (
    <Error
      {...rest}
      className="http-error"
      title={title}
      subtitle={error.message}
    >
      {
        <pre style={{ whiteSpace: "pre-wrap" }}>
          {JSON.stringify(error.body)}
        </pre>
      }
    </Error>
  );
};

export default ServerError;
