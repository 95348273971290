import classnames from "classnames";
import * as React from "react";

import { LifecycleContext } from "./lifecycle";

import "./lifecycle.scss";

interface LifecycleItemProp
  extends Omit<React.HTMLProps<HTMLDivElement>, "label"> {
  active?: boolean;
  done?: boolean;
  error?: boolean;
  label?: React.ReactNode;
  additionalLabel?: string;
  className?: string;
}

export const LifecycleItem = React.forwardRef<
  HTMLDivElement,
  LifecycleItemProp
>(
  (
    {
      active = false,
      done = false,
      error = false,
      label,
      additionalLabel,
      className,
      ...rest
    },
    ref
  ) => {
    const { itemWidth, getItemIndex, activeItem } = React.useContext(
      LifecycleContext
    );
    const index = React.useMemo(() => getItemIndex(), []);

    React.useEffect(() => {
      if (active || error) {
        activeItem(index);
      }
    }, [active || error]);

    const width = itemWidth;
    const left = -(itemWidth / 2);

    return (
      <div
        {...rest}
        ref={ref}
        className={classnames("lifecycle-item", className, {
          "lifecycle-item-active": active,
          "lifecycle-item-done": done,
          "lifecycle-item-error": error,
        })}
      >
        <div className="lifecycle-item-circle" />
        {label && (
          <div
            className="lifecycle-item-label"
            style={{ width: `${width}vw`, left: `${left}vw` }}
          >
            {label}
          </div>
        )}
        {additionalLabel && (
          <div className="lifecycle-item-label-additional">
            {additionalLabel}
          </div>
        )}
      </div>
    );
  }
);
