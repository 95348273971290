import classnames from "classnames";
import * as React from "react";
import { Context } from "./context";
import { StepId, StepStatus } from "./typings";

import Svg from "@natera/material/lib/svg";
import doneIcon from "@natera/stepper/assets/svg/icons/done.svg";

interface Props {
  index: number;
  stepId: StepId;
}

const CLASS_NAME = "stepper__header__steps__unit__head";

const Header: React.FunctionComponent<Props> = ({ index, stepId }) => {
  const { isLoading, goAt, getStep, getCurrentStep, getPreviousStep } =
    React.useContext(Context);
  const current = getCurrentStep();
  const step = getStep(stepId);
  const previous = getPreviousStep(stepId);

  if (!step) {
    return null;
  }

  const active = Boolean(current && current.stepId === stepId);
  const disabled$ =
    step.disabled ||
    isLoading() ||
    !(
      active ||
      step.status === StepStatus.COMPLETED ||
      previous?.status === StepStatus.COMPLETED ||
      step.error
    );

  const onClick = () => goAt(stepId);

  return (
    <button
      tabIndex={index === 1 ? 0 : -1}
      disabled={disabled$}
      onClick={onClick}
      className={classnames(step.className, CLASS_NAME, {
        [`${CLASS_NAME}--loading`]: step.loading,
        [`${CLASS_NAME}--completed`]: step.status === StepStatus.COMPLETED,
        [`${CLASS_NAME}--error`]: step.error,
        [`${CLASS_NAME}--active`]: active,
        [`${CLASS_NAME}--disabled`]: disabled$,
      })}
    >
      <span className={`${CLASS_NAME}__ripple`} />
      <span className={`${CLASS_NAME}__index`}>
        {step.error && "!"}
        {!step.error && step.status === StepStatus.COMPLETED && (
          <Svg className={`${CLASS_NAME}__icon`} content={doneIcon} />
        )}
        {!step.error && step.status !== StepStatus.COMPLETED && index}
      </span>
      <span className={`${CLASS_NAME}__title`}>
        {step.title}
        {(step.error || step.description) && (
          <span className={`${CLASS_NAME}__description`}>
            {(step.error && step.error.msg) || step.description}
          </span>
        )}
      </span>
    </button>
  );
};

export default React.memo(Header);
