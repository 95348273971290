import { Link } from "@natera/platform/lib/components/link";
import * as React from "react";

interface Props extends React.HTMLProps<HTMLDivElement> {
  to?: string;
  title?: string;
  children?: React.ReactNode;
}

const getTitleFrom = (node: React.ReactNode) =>
  typeof node === "string" ? node : undefined;

export const BreadcrumbItem: React.FC<Props> = ({
  children,
  title,
  to,
  ...rest
}) => (
  <div
    {...rest}
    className="breadcrumbs-item"
    title={title || getTitleFrom(children)}
  >
    {to ? <Link to={to}>{children}</Link> : children}
  </div>
);
