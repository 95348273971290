"use client";

import { util } from "@natera/material/lib/common";
import Svg from "@natera/material/lib/svg";
import classnames from "classnames";
import * as React from "react";

import "./iconButton.scss";

export interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: string;
  className?: string;
}

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, className, disabled, ...props }, ref) => {
    const [isIE, setIsIE] = React.useState<boolean>(false);

    React.useEffect(() => {
      setIsIE(util.isIE());
    }, []);

    return (
      <button
        type={props.type || "button"}
        ref={ref}
        className={classnames("mdc-icon-button", className, {
          "mdc-icon-button--ie-support-fix": isIE,
          "mdc-icon-button--disabled": disabled,
        })}
        disabled={disabled}
        {...props}
      >
        <div className="mdc-icon-button__ripple"></div>
        <Svg className="mdc-icon-button__icon" content={children} />
      </button>
    );
  }
);

export default IconButton;
