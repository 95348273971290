import { Error } from "@natera/platform/lib/components/context";
import { Button } from "@natera/platform/lib/components/form";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import "./viewPdf.scss";

export interface Props extends React.HTMLProps<HTMLDivElement> {
  title?: string;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  buttonText?: string;
}

export const messages = defineMessages({
  title: {
    id: "platform.pdfViewer.defaultErrorTitle",
    defaultMessage: "Failed to load PDF page.",
  },
  close: {
    id: "platform.pdfViewer.errorClose",
    defaultMessage: "Close",
  },
});

export const PdfViewError: React.FC<Props> = ({
  title,
  onClose = () => close(),
  buttonText,
  ...rest
}) => {
  const intl = useIntl();

  return (
    <Error {...rest} title={title || intl.formatMessage(messages.title)}>
      <p>
        <Button onClick={onClose} raised={true}>
          {buttonText || intl.formatMessage(messages.close)}
        </Button>
      </p>
    </Error>
  );
};

export default PdfViewError;
