import { MDCTextFieldFoundation } from "@material/textfield";

function debounce<T extends Array<never>>(
  func: (...args: T) => void,
  timeout = 100
) {
  let timer: ReturnType<typeof setTimeout>;
  return function (...args: T) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

// due to react components' redundant render calls we have to debounce value updates
// to avoid redundant element status transitions
const setDebounceValue = debounce(MDCTextFieldFoundation.prototype.setValue);

export class TextFieldFoundation extends MDCTextFieldFoundation {
  private permanentFocusActive = false;

  deactivateFocus(): void {
    if (this.permanentFocusActive) {
      return;
    }
    super.deactivateFocus();
  }

  activatePermanentFocus(): void {
    this.permanentFocusActive = true;
    this.activateFocus();
  }

  deactivatePermanentFocus(): void {
    this.permanentFocusActive = false;
    this.deactivateFocus();
  }

  setValue(value: string): void {
    setDebounceValue.call(this, value);
  }
}
