import * as React from "react";
import { Context } from "./context";
import { StepConfig, StepData, StepId, StepStatus } from "./typings";

interface Props extends StepConfig {
  stepId: StepId;
  loading?: boolean;
  disabled?: boolean;
}

interface StatusWithData extends Props {
  data: StepData;
  status?: StepStatus;
}

interface NoStatusNoData extends Props {
  status?: never;
  data?: never;
}

type StepProps = StatusWithData | NoStatusNoData;

const Step: React.FunctionComponent<StepProps> = ({ stepId, ...props }) => {
  const { createStep, removeStep, updateStep } = React.useContext(Context);

  React.useEffect(() => {
    createStep(stepId, props);

    return () => removeStep(stepId);
  }, []);

  React.useEffect(() => {
    const { description, index } = props;
    updateStep(stepId, {
      description,
      index,
    });
  }, [props.description, props.index]);

  return null;
};

export default React.memo(Step);
