import PersonIcon from "@natera/patient/assets/svg/icons/person.svg";
import { PatientLink } from "@natera/patient/lib/patientLink";
import Svg from "@natera/material/lib/svg";
import React from "react";
import { BasePatient } from "@natera/patient/lib/types";
import { defineMessages, useIntl } from "react-intl";
import "./patientInfo.scss";

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  patient: BasePatient;
}

export const messages = defineMessages({
  fullName: {
    id: "patient.info.fullName",
    defaultMessage: "Patient:",
  },
  id: {
    id: "patient.info.id",
    defaultMessage: " (ID: {id})",
  },
  dob: {
    id: "patient.info.dob",
    defaultMessage: "DOB:",
  },
  clinic: {
    id: "patient.info.clinic",
    defaultMessage: "Clinic:",
  },
});

export const PatientInfo: React.FC<Props> = ({ patient, ...props }) => {
  const intl = useIntl();

  return (
    <div {...props} className="patient-info-block">
      <Svg className="patient-info-block__lead-icon" content={PersonIcon} />
      <div className="patient-info-block__section">
        <span className="section-label">
          {intl.formatMessage(messages.fullName)}
        </span>
        <div className="ellipsis">
          <PatientLink patient={patient} />
          {intl.formatMessage(messages.id, { id: patient.id })}
        </div>
      </div>
      <div className="patient-info-block__section">
        <span className="section-label">
          {intl.formatMessage(messages.dob)}
        </span>
        <div className="ellipsis">
          {patient.dateOfBirth ? patient.dateOfBirth.toString() : "—"}
        </div>
      </div>
      <div className="patient-info-block__section">
        <span className="section-label">
          {intl.formatMessage(messages.clinic)}
        </span>
        <div className="ellipsis">
          {patient.clinic.name}
          {intl.formatMessage(messages.id, { id: patient.clinic.limsId })}
        </div>
      </div>
    </div>
  );
};

export default PatientInfo;
