import classnames from "classnames";
import React, { FunctionComponent } from "react";
import "./label.scss";

export interface LabelProps
  extends React.LabelHTMLAttributes<HTMLLabelElement> {
  floating?: boolean;
  required?: boolean;
}

export const Label: FunctionComponent<LabelProps> = ({
  className,
  children,
  floating = false,
  required = false,
  ...props
}) => (
  <label
    {...props}
    className={classnames("form-field-label", className, {
      "form-field-label--required": required,
      "form-field-label--floating": floating,
    })}
  >
    {children}
    {required && <span className="form-field-label--asterisk">&nbsp;*</span>}
  </label>
);

export default Label;
