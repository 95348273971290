"use client";

import React from "react";
import classnames from "classnames";

import {
  CustomComponents,
  DateFormatter,
  DateRange,
  DayPicker,
  DayPickerBase,
} from "react-day-picker";
import { useIntl } from "react-intl";
import NavBarElement from "./navBar";
import { DateInputContext, DateInputController } from "./context";
import { getLocalizedMonthTitle, isFuture, isPast } from "../utils";

import "./../date.scss";

export interface BaseDayPickerProps
  extends Omit<DayPickerBase, "months" | "weekdaysShort"> {
  minDate?: Date;
  maxDate?: Date;
  defaultMonthYear?: Date;
  bottomElement?: React.ReactNode;
  enableOutsideDaysClick?: boolean;
  dayPickerComponents?: CustomComponents;
  formatWeekdayName?: DateFormatter;
  formatMonthTitle?: DateFormatter;
  formatMonthInMenu?: (date: Date) => string;
}

export const BaseDayPicker: React.FunctionComponent<BaseDayPickerProps> = ({
  minDate,
  maxDate,
  className,
  numberOfMonths,
  selected,
  bottomElement,
  defaultMonthYear,
  modifiersStyles,
  enableOutsideDaysClick,
  dayPickerComponents,
  formatWeekdayName = (date: Date) =>
    new Intl.DateTimeFormat("default", {
      weekday: "short",
    }).format(date),
  formatMonthTitle,
  formatMonthInMenu,
  ...props
}) => {
  const intl = useIntl();

  const [monthYear, setMonthYear] = React.useState<Date | undefined>(
    defaultMonthYear
  );

  const defaultMonthTitleFormat = (date: Date) =>
    getLocalizedMonthTitle(date, intl.locale);

  const defaultMonthFormat = (date: Date) =>
    new Intl.DateTimeFormat(intl.locale, { month: "long" }).format(date);

  React.useEffect(() => {
    if (defaultMonthYear) {
      setMonthYear(defaultMonthYear);
    }
  }, [defaultMonthYear]);

  React.useEffect(() => {
    const selectedMonth =
      selected instanceof Date
        ? selected
        : ((selected as DateRange[]) || [])[1]?.from;
    if (selectedMonth) {
      setMonthYear(selectedMonth);
    } else if (minDate && isFuture(minDate)) {
      setMonthYear(minDate);
    } else if (maxDate && isPast(maxDate)) {
      setMonthYear(maxDate);
    }
  }, []);

  const disabledDates = React.useMemo(() => {
    if (maxDate && minDate) {
      return {
        after: maxDate,
        before: minDate,
      };
    }

    if (maxDate) {
      return { after: maxDate };
    }

    if (minDate) {
      return { before: minDate };
    }

    return undefined;
  }, [maxDate?.getTime(), minDate?.getTime()]);

  const changeMonthYear = React.useCallback((monthYearDate: Date) => {
    setMonthYear(monthYearDate);
  }, []);

  const defaultMinDate = React.useMemo(() => {
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() - 100);
    return currentDate;
  }, []);

  const defaultMaxDate = React.useMemo(() => {
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() + 1);
    return currentDate;
  }, []);

  const dateInputController: DateInputController = React.useMemo(
    () => ({
      minDate: minDate || defaultMinDate,
      maxDate: maxDate || defaultMaxDate,
      changeMonthYear,
      numberOfMonths,
      formatMonthTitle: formatMonthTitle || defaultMonthTitleFormat,
      formatMonthInMenu: formatMonthInMenu || defaultMonthFormat,
    }),
    [
      minDate?.getTime(),
      maxDate?.getTime(),
      changeMonthYear,
      numberOfMonths,
      formatMonthTitle,
      formatMonthInMenu,
    ]
  );

  return (
    <DateInputContext.Provider value={dateInputController}>
      <div className="rdp-base-wrapper">
        <NavBarElement month={monthYear || new Date()} />
        <DayPicker
          className={classnames(className, "rdp-base")}
          components={{
            ...dayPickerComponents,
            Caption: () => null,
          }}
          formatters={{ formatWeekdayName }}
          selected={selected}
          numberOfMonths={numberOfMonths}
          month={monthYear}
          disabled={disabledDates}
          fixedWeeks={true}
          modifiersStyles={{
            outside: {
              pointerEvents: enableOutsideDaysClick ? "all" : "none",
              cursor: enableOutsideDaysClick ? "pointer" : "default",
            },
            ...modifiersStyles,
          }}
          {...props}
        />
        {bottomElement}
      </div>
    </DateInputContext.Provider>
  );
};

export default DayPicker;
