import { Checkbox } from "@natera/material/lib/checkbox";
import { ListItemGraphic } from "@natera/material/lib/list";
import { TypeAheadOption, TypeAheadOptionProps } from "../typeAhead";

import classnames from "classnames";
import * as React from "react";
import { getMultiSelectContext } from "./multiSelectContext";

export interface MultiSelectOptionProps extends TypeAheadOptionProps {
  selectable?: boolean;
}

export const MultiSelectOption: React.FunctionComponent<MultiSelectOptionProps> = ({
  className,
  optionValue,
  children,
  disabled,
  selectable = true,
  ...props
}) => {
  const multiSelectContext = React.useContext(getMultiSelectContext());

  const itemSelectHandler = React.useCallback(() => {
    multiSelectContext.handleSelect(optionValue);
  }, [multiSelectContext, optionValue]);

  return (
    <TypeAheadOption
      closeOnSelect={false}
      optionValue={optionValue}
      onItemAction={itemSelectHandler}
      disabled={disabled}
      className={classnames(className, "multiselect-item", {
        "multiselect-item--disabled": disabled,
      })}
      key={optionValue.toString()}
      {...props}
    >
      {selectable && (
        <ListItemGraphic>
          <Checkbox
            checked={Boolean(
              multiSelectContext.getSelection().isSelected(optionValue)
            )}
            readOnly
          />
        </ListItemGraphic>
      )}
      {children}
    </TypeAheadOption>
  );
};
