import { LinkButton } from "@natera/platform/lib/components/form";
import { HttpResourceError } from "@natera/platform/lib/service";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import Error from "./error";

interface Props extends React.HTMLProps<HTMLDivElement> {
  error: HttpResourceError;
}

export const messages = defineMessages({
  resourceTitle: {
    id: "platform.resourceNotFound",
    defaultMessage: "{resourceName} not found",
  },
  resourceMessage: {
    id: "platform.resourceNotFound.message",
    defaultMessage: "{resourceName} not found",
  },
  resourceMessageWithId: {
    id: "platform.resourceNotFound.messageWithId",
    defaultMessage: "{resourceName} with id#{resourceId} not found",
  },
  resourceBack: {
    id: "platform.resourceNotFound.back",
    defaultMessage: "Back Home",
  },
});

const ResourceNotFound: React.FunctionComponent<Props> = ({
  error,
  ...rest
}) => {
  const intl = useIntl();
  const title = (
    <>
      {intl.formatMessage(messages.resourceTitle, {
        resourceName: error.resourceName,
      })}
    </>
  );
  const message = error.resourceId
    ? intl.formatMessage(messages.resourceMessageWithId, {
        resourceName: error.resourceName,
        resourceId: error.resourceId,
      })
    : intl.formatMessage(messages.resourceMessage, {
        resourceName: error.resourceName,
      });

  return (
    <Error {...rest} className="error-page" title={title}>
      <p>{message}</p>
      <LinkButton to="/">
        {intl.formatMessage(messages.resourceBack)}
      </LinkButton>
    </Error>
  );
};

export default ResourceNotFound;
