import CalendarIcon from "@natera/material/assets/svg/calendar_today.svg";
import { DateRange, Utils } from "@natera/material/lib/date";
import { DateRangeInput } from "@natera/platform/lib/components/form/field";
import { DateRangeInputProps } from "@natera/platform/lib/components/form/field/date";
import { FilterAPI } from "@natera/platform/lib/hooks";
import classnames from "classnames";
import * as React from "react";
import "./dateRangeFilter.scss";

export interface DateRangeFilterProps extends DateRangeInputProps {
  fromFilter: FilterAPI;
  toFilter: FilterAPI;
  useTimezone?: boolean;
}

const DateRangeFilter: React.FunctionComponent<DateRangeFilterProps> = ({
  fromFilter,
  toFilter,
  className,
  useTimezone = false,
  ...props
}) => {
  const fromString = fromFilter.getValue();
  const toString = toFilter.getValue();
  const from =
    useTimezone && fromString
      ? new Date(fromString)
      : Utils.readDate(fromFilter.getValue());
  const to =
    useTimezone && toString
      ? new Date(toString)
      : Utils.readDate(toFilter.getValue());

  const dateRange = from && to ? { from, to } : undefined;
  const onChange = React.useCallback((range: DateRange) => {
    const from$ =
      range &&
      range.from &&
      (useTimezone
        ? Utils.getBeginningOfDay(range.from).toISOString()
        : Utils.writeDate(range.from));
    const to$ =
      range &&
      range.to &&
      (useTimezone
        ? Utils.getEndOfDay(range.to).toISOString()
        : Utils.writeDate(range.to));

    if (range && from$ && to$) {
      fromFilter.setValue(from$);
      toFilter.setValue(to$);
    } else {
      onClear();
    }
  }, []);

  const onClear = React.useCallback(() => {
    fromFilter.clearValue();
    toFilter.clearValue();
  }, []);
  return (
    <DateRangeInput
      {...props}
      className={classnames("date-range-input", className)}
      range={dateRange}
      onDateRangeChange={onChange}
      onClear={onClear}
      leadIcon={CalendarIcon}
    />
  );
};

export default DateRangeFilter;
