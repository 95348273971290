import * as R from "ramda";
import React from "react";
import { useStorage } from "../useStorage";

type Selection = string;

export interface MultiSelectProps<I> {
  initialSelection?: I[];
  recordIndex?: (item: I) => Selection;
}

type GetSelection = () => Selection[];
type SetSelection = (selection: Selection[]) => void;
type IsSelected<I> = (item: I) => boolean;
type GetSelectedItems<I> = (items: I[]) => I[];
type IsAllSelected<I> = (items?: I[]) => boolean;
type HasSelection = () => boolean;
type Clear = () => void;
type Reset = () => void;
type HandleItemSelect<I> = (item: I) => void;
type HandleSelectAll<I> = (items: I[]) => (selected: boolean) => void;

export interface SelectionController<I> {
  getSelection: GetSelection;
  setSelection: SetSelection;
  isSelected: IsSelected<I>;
  getSelectedItems: GetSelectedItems<I>;
  isAllSelected: IsAllSelected<I>;
  hasSelection: HasSelection;
  clear: Clear;
  reset: Reset;
  handleItemSelect: HandleItemSelect<I>;
  handleSelectAll: HandleSelectAll<I>;
}

export const defaultSelectionController: SelectionController<unknown> = {
  getSelection: R.always([]),
  setSelection: R.always(undefined),
  isSelected: R.always(false),
  getSelectedItems: R.always([]),
  isAllSelected: R.always(false),
  hasSelection: R.always(false),
  clear: R.always(undefined),
  reset: R.always(undefined),
  handleItemSelect: R.always(undefined),
  handleSelectAll: R.always(R.always(undefined)),
};

export const useMultiSelection = <I>({
  initialSelection = [],
  recordIndex = R.propOr("", "id"),
}: MultiSelectProps<I>): SelectionController<I> => {
  const initialRecords = initialSelection.map(recordIndex);

  const selectionStorage = useStorage({
    initialRecords,
    recordIndex: R.identity,
  });

  const getSelection: GetSelection = React.useCallback(
    () => selectionStorage.getRecords(),
    [selectionStorage]
  );

  const setSelection: SetSelection = React.useCallback(
    (selection) => {
      return selectionStorage.addRecords(selection);
    },
    [selectionStorage]
  );

  const isSelected: IsSelected<I> = React.useCallback(
    (item) => {
      return !R.isNil(selectionStorage.getRecord(recordIndex(item)));
    },
    [selectionStorage]
  );

  const getSelectedItems: GetSelectedItems<I> = React.useCallback(
    (items) => {
      return items.filter(isSelected);
    },
    [isSelected]
  );

  const isAllSelected: IsAllSelected<I> = React.useCallback(
    (items = []) => {
      return (
        getSelection() &&
        R.allPass(items.map((item) => () => isSelected(item)))()
      );
    },
    [getSelection, isSelected]
  );

  const handleItemSelect: HandleItemSelect<I> = React.useCallback(
    (item) => {
      if (isSelected(item)) {
        selectionStorage.removeRecord(recordIndex(item));
      } else {
        selectionStorage.addRecord(recordIndex(item));
      }
    },
    [selectionStorage, isSelected]
  );

  const handleSelectAll: HandleSelectAll<I> = React.useCallback(
    (items) => (selected) => {
      if (selected) {
        selectionStorage.addRecords(items.map(recordIndex));
      } else {
        selectionStorage.removeRecords(items.map(recordIndex));
      }
    },
    [selectionStorage]
  );

  const hasSelection: HasSelection = React.useCallback(() => {
    return R.not(R.isEmpty(getSelection()));
  }, [getSelection]);

  const clear: Clear = React.useCallback(() => {
    selectionStorage.clear();
  }, [selectionStorage]);

  const reset: Reset = React.useCallback(() => {
    selectionStorage.clear();
    selectionStorage.addRecords(initialRecords);
  }, [selectionStorage, initialRecords]);

  return React.useMemo(
    () => ({
      getSelection,
      setSelection,
      isSelected,
      getSelectedItems,
      isAllSelected,
      hasSelection,
      clear,
      reset,
      handleItemSelect,
      handleSelectAll,
    }),
    [
      getSelection,
      setSelection,
      isSelected,
      getSelectedItems,
      isAllSelected,
      hasSelection,
      clear,
      reset,
      handleItemSelect,
      handleSelectAll,
    ]
  );
};
