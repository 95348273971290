import Svg from "@natera/material/lib/svg";
import ErrorIcon from "@natera/platform/assets/svg/icons/error_outline.svg";
import { ScrollContext } from "@natera/platform/lib/components/scrollbar";
import classnames from "classnames";
import * as R from "ramda";
import * as React from "react";
import { Subject } from "rxjs";
import { throttleTime } from "rxjs/operators";

import "./form.scss";

const THROTTLE_TIME = 75;

type SubmitResult = boolean | void;

interface Props extends React.FormHTMLAttributes<HTMLFormElement> {
  buttons?: React.ReactNode;
  isEditable?: boolean;
  disabled?: boolean;
  useStaticScroll?: boolean;
  error?: string;
  errorIcon?: boolean;
  scrollbarRef?: React.RefObject<HTMLDivElement> | undefined;
  onSubmit?:
    | ((event: React.FormEvent<HTMLFormElement>) => SubmitResult)
    | ((event: React.FormEvent<HTMLFormElement>) => Promise<SubmitResult>);
}

export interface FormController {
  isDisabled: () => boolean;
  isEditable: () => boolean;
  isChanged: () => boolean;
  setFormChanged: () => void;
  scrollToError: (ref: HTMLElement) => void;
}

export const FormContext = React.createContext<FormController>({
  isDisabled: () => false,
  isEditable: () => true,
  isChanged: () => false,
  setFormChanged: () => undefined,
  scrollToError: () => undefined,
});

export const getRadioGroupValue = (group: HTMLInputElement[]) => {
  return (
    group &&
    R.head(
      R.range(0, group.length)
        .map((key) => R.prop(key, group))
        .filter((input) => input.checked)
        .map((input) => input.value)
    )
  );
};

export const getCheckboxGroupValue = (group: HTMLInputElement[]) => {
  return (
    group &&
    R.range(0, group.length)
      .map((key) => R.prop(key, group))
      .filter((input) => input.checked)
      .map((input) => input.value)
  );
};

export const Form = React.forwardRef<HTMLFormElement, Props>(
  (
    {
      className,
      children,
      buttons,
      isEditable = true,
      disabled = false,
      useStaticScroll = true,
      error,
      onReset,
      onChange,
      onSubmit = R.always(true),
      errorIcon,
      scrollbarRef,
      ...props
    },
    ref
  ) => {
    const [formKey, setFormKey] = React.useState(0);

    const [useScroll, setUseScroll] = React.useState(false);
    const [isChanged, setIsChanged] = React.useState(false);
    const isEditable$ = React.useCallback(() => isEditable, [isEditable]);
    const isDisabled = React.useCallback(() => disabled, [disabled]);
    const internalScrollbarRef = React.createRef<HTMLElement>();

    const invalidFields$ = React.useRef<Subject<HTMLElement>>();

    React.useEffect(() => {
      invalidFields$.current = new Subject();

      const subscription = invalidFields$.current
        .pipe(throttleTime(THROTTLE_TIME))
        .subscribe((element) => {
          element.scrollIntoView();
        });

      return () => subscription.unsubscribe();
    }, []);

    React.useEffect(() => {
      const scrollBar = scrollbarRef || internalScrollbarRef;

      setUseScroll(
        Boolean(
          scrollBar &&
            scrollBar.current &&
            scrollBar.current.classList.contains("ps--active-y")
        )
      );
    }, [children]);

    const scrollToErrorHandler = (element: HTMLElement) => {
      invalidFields$.current?.next(element);
    };

    const getScrollBarRef = () => {
      if (scrollbarRef) {
        return scrollbarRef;
      } else {
        return internalScrollbarRef;
      }
    };

    const formChangeHandler: React.FormEventHandler<HTMLFormElement> = (
      event
    ) => {
      if (onChange) {
        onChange(event);
      }
      setIsChanged(true);
    };

    const formResetHandler: React.FormEventHandler<HTMLFormElement> = (e) => {
      if (onReset) {
        onReset(e);
      }
      setFormKey(R.add(1));
      setIsChanged(false);
    };

    const formSubmitHandler: React.FormEventHandler<HTMLFormElement> = async (
      event
    ) => {
      const success = await onSubmit(event);

      if (success) {
        setIsChanged(false);
      }
    };

    return (
      <FormContext.Provider
        key={formKey}
        value={{
          isEditable: isEditable$,
          isDisabled,
          isChanged: () => isChanged,
          setFormChanged: () => setIsChanged(true),
          scrollToError: (element) => scrollToErrorHandler(element),
        }}
      >
        <form
          {...props}
          ref={ref}
          className={classnames("form", className, {
            "form--scroll": useScroll,
          })}
          onReset={formResetHandler}
          onChange={formChangeHandler}
          onSubmit={formSubmitHandler}
        >
          {error && (
            <div className="form-error">
              {errorIcon && <Svg content={ErrorIcon} />}
              {error}
            </div>
          )}
          <ScrollContext
            component="div"
            className="form-content"
            isStatic={useStaticScroll}
            ref={getScrollBarRef()}
          >
            {children}
          </ScrollContext>
          {buttons && isEditable && (
            <div className="form-buttons">{buttons}</div>
          )}
        </form>
      </FormContext.Provider>
    );
  }
);

export default Form;
