import { Corner, MenuController, MenuWrapper } from "@natera/material/lib/menu";
import classnames from "classnames";
import * as R from "ramda";
import * as React from "react";
import { getTypeAheadContext } from "./typeAheadContext";

import "./typeAhead.scss";

export interface TypeAheadMenuProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "children" | "ref"> {
  className?: string;
  anchorElement: (controller: MenuController) => React.ReactNode;
  anchorRef?: React.RefObject<HTMLDivElement>;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  dropdownWidth?: DropdownWidth;
  dropdownTwoLines?: boolean;
  dense?: boolean;
  corner?: Corner;
  floating?: boolean;
  menuButtonRef?: React.RefObject<any>;
}

export const enum DropdownWidth {
  SAME_WIDTH = "SAME_WIDTH",
  FLOATING_WIDTH = "FLOATING_WIDTH",
}

export const TypeAheadMenu = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<TypeAheadMenuProps>
>(
  (
    {
      className,
      children,
      anchorElement,
      anchorRef,
      dropdownTwoLines = false,
      dropdownWidth,
      onMenuOpen = R.always(undefined),
      onMenuClose = R.always(undefined),
      dense = false,
      corner = Corner.BOTTOM_START,
      ...props
    },
    ref: React.MutableRefObject<HTMLDivElement>
  ) => {
    const menuRef = React.useRef<HTMLDivElement>();
    const typeaheadController = React.useContext(getTypeAheadContext());
    const optionsCollection = typeaheadController.getOptionsCollection();
    const hasItems = Boolean(optionsCollection.getItems().length);
    const [isOpen, setIsOpen] = React.useState(false);

    const scaleMenuWidth = React.useCallback(() => {
      if (menuRef.current && anchorRef?.current) {
        menuRef.current.style[
          dropdownWidth === DropdownWidth.FLOATING_WIDTH ? "minWidth" : "width"
        ] = `${anchorRef?.current.offsetWidth}px`;
      }
    }, []);

    const menuOpenHandler = React.useCallback(() => {
      onMenuOpen();
      if (!hasItems) {
        optionsCollection.load();
      }

      scaleMenuWidth();
      setIsOpen(true);
    }, [hasItems, onMenuOpen]);

    const createMenuRef = React.useCallback((element: HTMLDivElement) => {
      menuRef.current = element;
      if (ref) {
        if (ref instanceof Function) {
          ref(element);
        } else {
          ref.current = element;
        }
      }
    }, []);

    const menuCloseHandler = React.useCallback(() => {
      onMenuClose();
      typeaheadController.forceValue();
      setIsOpen(false);
    }, [typeaheadController]);

    return (
      <MenuWrapper
        autoFocus={false}
        corner={corner}
        className={classnames(className, "typeahead-menu")}
        onOpen={menuOpenHandler}
        onClose={menuCloseHandler}
        ref={createMenuRef}
        twoLines={dropdownTwoLines}
        dense={dense}
        children={anchorElement}
        menu={children}
        aria-hidden={!isOpen}
        {...props}
      />
    );
  }
);
