interface HttpErrorInterface {
  message: string;
  code: number;
  body: unknown;
}

class HttpError extends Error implements HttpErrorInterface {
  constructor(
    readonly message: string,
    readonly code: number,
    readonly body: unknown
  ) {
    super(message);
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, HttpError.prototype);
  }
}

// @ts-ignore
HttpError.prototype = new Error();

export default HttpError;
