import * as React from "react";

interface Props<T> {
  onFocus?: React.FocusEventHandler<T>;
  onBlur?: React.FocusEventHandler<T>;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFocus = <T>({ onFocus, onBlur }: Props<T>) => {
  const [focus, setFocus] = React.useState(false);

  const handleFocus: React.FocusEventHandler<T> = React.useCallback(
    (event) => {
      setFocus(true);

      if (onFocus) {
        onFocus(event);
      }
    },
    [onFocus]
  );

  const handleBlur: React.FocusEventHandler<T> = React.useCallback(
    (event) => {
      setFocus(false);

      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur]
  );

  return {
    focus,
    handleFocus,
    handleBlur,
  };
};

export default useFocus;
