import { useComponentState } from "@natera/material/lib/hooks";
import * as R from "ramda";
import * as React from "react";

interface CheckboxController {
  value: string[];
  name: string;
  changeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hasGroup: boolean;
}

export interface CheckboxGroupProps {
  value?: string[];
  defaultValue?: string[];
  name: string;
  onChange?: (value: string[]) => void;
  children: React.ReactNode;
}

export const CheckboxGroupContext = React.createContext<CheckboxController>({
  value: [],
  name: "",
  changeHandler: () => undefined,
  hasGroup: false,
});

export const CheckboxGroup: React.FunctionComponent<CheckboxGroupProps> = ({
  name,
  value,
  defaultValue,
  onChange,
  children,
}) => {
  const [value$ = [], setValue$] = useComponentState<string[]>({
    controlledValue: value,
    defaultValue,
    onChange,
  });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue: string = e.currentTarget.value;

    const newGroupValue = e.currentTarget.checked
      ? R.uniq([...value$, eventValue])
      : value$.filter((v) => v !== eventValue);

    setValue$(newGroupValue);
  };

  const controller: CheckboxController = {
    value: value$,
    name,
    changeHandler: handleOnChange,
    hasGroup: true,
  };

  return (
    <CheckboxGroupContext.Provider value={controller}>
      {children}
    </CheckboxGroupContext.Provider>
  );
};
