import StepError from "./error";

export type StepId = number | string;

export enum StepStatus {
  UNTREATED = "UNTREATED",
  COMPLETED = "COMPLETED",
}

export type StepData = any;

export interface StepConfig {
  children: React.ReactChild;
  title?: React.ReactNode;
  description?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  data?: StepData;
  status?: StepStatus;
  className?: string;
  index?: number;
  error?: StepError;
}

export interface StepState extends StepConfig {
  stepId: StepId;
  readonly generalData?: StepData;
}

export type IsLoading = () => boolean;
export type GetSteps = () => StepState[];
export type GetCurrentStep = () => StepState | undefined;
export type GetStep = (stepId: StepId) => StepState | undefined;
export type GetData = (stepId: StepId, fallback?: StepData) => StepData;
