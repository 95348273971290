"use client";

import { MDCTextFieldFoundation } from "@material/textfield";
import { Button } from "@natera/material/lib/button";
import { useFocus } from "@natera/material/lib/hooks";
import Input from "@natera/material/lib/input";
import classnames from "classnames";
import * as R from "ramda";
import * as React from "react";
import TrailingIcon from "./trailingIcon";
import LeadIcon from "./leadIcon";

import "./textfield.scss";

export interface TextfieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  fullWidth?: boolean;
  invalid?: boolean;
  outline?: boolean;
  leadIcon?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  filterIcon?: React.ReactNode;
  trailingIconTabIndex?: number;
  hasLabel?: boolean;
  clearable?: boolean;
  selectable?: boolean;
  dropDownActive?: boolean;
  onClear?: () => void;
  onSelectionOpen?: () => void;
  warning?: boolean;
  readonly?: boolean;
  isFocused?: boolean;
  action?: React.ReactNode;
}

export const Textfield = React.forwardRef<HTMLInputElement, TextfieldProps>(
  (
    {
      type,
      className,
      fullWidth,
      invalid,
      outline,
      disabled,
      value,
      defaultValue,
      clearable,
      selectable,
      leadIcon,
      trailingIcon,
      filterIcon,
      trailingIconTabIndex = -1,
      hasLabel,
      onFocus,
      onBlur,
      isFocused = undefined,
      dropDownActive,
      onClear = R.always(undefined),
      onSelectionOpen,
      required,
      warning,
      readonly,
      action,
      ...props
    },
    ref: React.RefObject<HTMLInputElement>
  ) => {
    const inptRef$ = React.useRef<HTMLInputElement>(null);
    const inputRef = ref || inptRef$;

    const { focus, handleFocus, handleBlur } = useFocus({
      onFocus,
      onBlur,
    });

    const isClearable = React.useCallback(() => {
      if (!clearable) {
        return false;
      }
      if (disabled) {
        return false;
      }

      return Boolean(value);
    }, [clearable, disabled, value]);

    const hasTrailingIcon = trailingIcon || selectable || isClearable();

    const trailingIcon$ = (
      <TrailingIcon
        trailingIcon={trailingIcon}
        focus={focus}
        isClearable={isClearable()}
        onClear={onClear}
        selectable={selectable}
        dropDownActive={dropDownActive}
        onSelectionOpen={onSelectionOpen}
      />
    );

    const leadIcon$ = <LeadIcon icon={leadIcon} />;

    const trailingTabIndex: number = React.useMemo(() => {
      if (isClearable()) {
        return -1;
      }
      return trailingIconTabIndex;
    }, [isClearable, trailingIconTabIndex]);

    return (
      <div
        className={classnames(
          className,
          MDCTextFieldFoundation.cssClasses.ROOT,
          {
            [MDCTextFieldFoundation.cssClasses.DISABLED]: disabled,
            [MDCTextFieldFoundation.cssClasses.FOCUSED]:
              isFocused !== undefined ? isFocused : focus,
            [MDCTextFieldFoundation.cssClasses.INVALID]: invalid,
            [MDCTextFieldFoundation.cssClasses.NO_LABEL]: !hasLabel,
            [MDCTextFieldFoundation.cssClasses.OUTLINED]: outline,
            [MDCTextFieldFoundation.cssClasses.WITH_LEADING_ICON]: Boolean(
              leadIcon
            ),
            [MDCTextFieldFoundation.cssClasses.WITH_TRAILING_ICON]: Boolean(
              hasTrailingIcon
            ),
            "mdc-text-field--selectable": selectable,
            "mdc-text-field--filled": !outline,
            "mdc-text-field--fullwidth": fullWidth,
            "mdc-text-field--warning": warning,
            "mdc-text-field--readonly": readonly,
          }
        )}
      >
        {outline && (
          <div className="mdc-notched-outline">
            <div className="mdc-notched-outline__leading" />
            <div className="mdc-notched-outline__trailing" />
          </div>
        )}
        {leadIcon$}
        <Input
          ref={inputRef}
          type={type || "text"}
          autoComplete="off"
          value={value}
          defaultValue={defaultValue}
          className={classnames("mdc-text-field__input")}
          disabled={disabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
          required={required}
          {...props}
        />
        {hasTrailingIcon && (
          <div
            className="mdc-text-field__icon mdc-text-field__icon--trailing"
            tabIndex={trailingTabIndex}
            role={isClearable() ? undefined : "button"}
          >
            {trailingIcon$}
          </div>
        )}
        {hasTrailingIcon && filterIcon && (
          <div className="mdc-text-field__icon--divider" />
        )}
        {filterIcon && (
          <div className="mdc-text-field__icon mdc-text-field__icon--filter">
            {filterIcon}
          </div>
        )}
        {action && (
          <div className="mdc-text-field__button--action">{action}</div>
        )}
      </div>
    );
  }
);

export default Textfield;
