import {
  Drawer as BaseDrawer,
  DrawerProps as BaseDrawerProps,
} from "@natera/material/lib/drawer";
import classnames from "classnames";
import * as React from "react";

import "./drawer.scss";
export interface DrawerProps extends BaseDrawerProps {
  isOpen?: boolean;
}

const Drawer = React.forwardRef<HTMLElement, DrawerProps>(
  ({ className, isOpen, ...props }, ref) => {
    return (
      <BaseDrawer
        ref={ref}
        className={classnames(className, "drawer", {
          "drawer--active": isOpen,
        })}
        {...props}
      />
    );
  }
);

export default Drawer;
