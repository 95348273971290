"use client";

import classnames from "classnames";
import * as React from "react";
import ClearIcon from "../../assets/svg/clear.svg";
import { IconButton } from "@natera/material/lib/button";
import { defineMessages, useIntl } from "react-intl";
import { FocusTrap, FocusOptions } from "@material/dom/focus-trap";
import "./dialog.scss";

const CLOSE_BUTTON = "Escape";

export interface DialogProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "title"> {
  title?: React.ReactNode;
  showCrossButton?: boolean;
  onClose: (event?: React.SyntheticEvent) => void;
  className?: string;
  actions?: React.ReactNode;
  children: React.ReactNode;
  altText?: string;
  focusTrapOptions?: FocusOptions;
}

export const messages = defineMessages({
  ariaLabelCloseButton: {
    id: "material.dialog.ariaLabelCloseButton",
    defaultMessage: "Close",
  },
});

export const Dialog: React.FunctionComponent<DialogProps> = ({
  title,
  onClose,
  showCrossButton,
  className,
  actions,
  children,
  altText,
  focusTrapOptions,
  ...props
}) => {
  const intl = useIntl();

  const dialogRef = React.useRef<HTMLDivElement>();
  const focusTrapRef = React.useRef<FocusTrap>();

  const ariaLabel =
    altText || intl.formatMessage(messages.ariaLabelCloseButton);

  const handleKeyDown = React.useCallback((event: React.KeyboardEvent) => {
    if (event.key === CLOSE_BUTTON) {
      onClose();
    }
  }, []);

  const releaseFocus = React.useCallback(() => {
    focusTrapRef.current?.releaseFocus();
    focusTrapRef.current = undefined;
  }, []);

  const trapFocus = React.useCallback(() => {
    if (
      !dialogRef.current ||
      // eslint-disable-next-line
      // @ts-ignore
      !FocusTrap.prototype.getFocusableElements(dialogRef.current).length
    ) {
      return;
    }

    releaseFocus();

    const focusTrap = new FocusTrap(dialogRef.current, focusTrapOptions);
    focusTrap.trapFocus();
    focusTrapRef.current = focusTrap;
  }, [focusTrapOptions]);

  const createDialogRef = React.useCallback((element: HTMLDivElement) => {
    dialogRef.current = element;
    trapFocus();
  }, []);

  React.useEffect(() => {
    trapFocus();
    return () => {
      releaseFocus();
    };
  }, [focusTrapOptions]);

  return (
    <div
      className={classnames("mdc-dialog", "mdc-dialog--open", className, {
        "mdc-dialog--with-description": children,
      })}
      tabIndex={-1}
      onKeyDown={handleKeyDown}
      {...props}
    >
      <div className="mdc-dialog__container">
        <div
          className="mdc-dialog__surface"
          role="alertdialog"
          aria-modal="true"
          aria-labelledby="dialog-title"
          ref={createDialogRef}
        >
          <div
            className={classnames("mdc-dialog__header", {
              "mdc-dialog--with-title": title,
              "mdc-dialog--with-cross-button": showCrossButton,
            })}
          >
            {title && (
              <h2 className="mdc-dialog__title" id="dialog-title">
                {title}
              </h2>
            )}
            {showCrossButton && (
              <IconButton
                className="mdc-dialog__cross-button"
                onClick={onClose}
                aria-label={ariaLabel}
              >
                {ClearIcon}
              </IconButton>
            )}
          </div>
          <div
            className="mdc-dialog__content"
            id="dialog-content"
            aria-describedby="dialog-content"
          >
            {children}
            {actions && (
              <footer className="mdc-dialog__actions">{actions}</footer>
            )}
          </div>
        </div>
      </div>
      <div className="mdc-dialog__scrim" onClick={onClose} />
    </div>
  );
};

export default Dialog;
