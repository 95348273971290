import { MDCMenuSurfaceFoundation } from "@material/menu-surface";
import { MDCSelectAdapter, MDCSelectFoundation } from "@material/select";
import { Corner } from "@natera/material/lib/menu";
import React from "react";
import { SelectValueType } from "./select";
import { MDCLineRippleFoundation } from "@material/line-ripple";
import { MDCFloatingLabelFoundation } from "@material/floating-label";
import { MDCNotchedOutlineFoundation } from "@material/notched-outline";

interface CreateAdapterProps {
  mdcAnchorElem: HTMLDivElement;
  mdcSelectedText: HTMLDivElement;
  surfaceFoundation: MDCMenuSurfaceFoundation;
  selectRef: React.RefObject<HTMLDivElement>;
  selectChangeHandler: (value$: SelectValueType) => void;
  selectAnchorRef: React.MutableRefObject<HTMLDivElement | null>;
  setSelectedItemText: (value: string | null) => void;
  getDomItemAtIndex: (index: number) => HTMLElement | undefined;
  getDomItems: () => HTMLElement[];
  label?: string;
  labelFoundation: MDCFloatingLabelFoundation;
  outline?: boolean;
  lineFoundation: MDCLineRippleFoundation;
  notchFoundation: MDCNotchedOutlineFoundation;
}
export const createAdapter = ({
  mdcAnchorElem,
  mdcSelectedText,
  surfaceFoundation,
  selectRef,
  selectChangeHandler,
  selectAnchorRef,
  setSelectedItemText,
  getDomItemAtIndex,
  getDomItems,
  label,
  labelFoundation,
  outline,
  lineFoundation,
  notchFoundation,
}: CreateAdapterProps): Partial<MDCSelectAdapter> => ({
  addClass: (className$: string) => {
    selectRef.current?.classList.add(className$);
  },
  removeClass: (className$: string) =>
    selectRef.current?.classList.remove(className$),
  hasClass: (className$: string) =>
    selectRef.current
      ? selectRef.current.classList.contains(className$)
      : false,
  notifyChange: selectChangeHandler,
  setSelectedText: (text: string) => {
    setSelectedItemText(text);
  },
  isSelectAnchorFocused: () => document.activeElement === mdcSelectedText,
  getSelectAnchorAttr: (attr: string) => mdcAnchorElem.getAttribute(attr),
  setSelectAnchorAttr: (attr: string, value$: string) => {
    mdcAnchorElem.setAttribute(attr, value$);
  },
  openMenu: () => {
    surfaceFoundation.open();
    mdcAnchorElem.setAttribute("aria-expanded", "true");
  },
  closeMenu: () => {
    surfaceFoundation.close();
    mdcAnchorElem.setAttribute("aria-expanded", "false");
  },
  getAnchorElement: () => mdcAnchorElem,
  setMenuAnchorElement: (anchorEl: HTMLDivElement) => {
    selectAnchorRef.current = anchorEl;
  },
  setMenuAnchorCorner: (anchorCorner: Corner) => {
    surfaceFoundation.setAnchorCorner(anchorCorner);
  },
  focusMenuItemAtIndex: (index: number) => {
    getDomItemAtIndex(index)?.focus();
  },
  getMenuItemCount: () => getDomItems().length,
  // TODO: we should probably avoid passing values through DOM, and use controller instead
  getMenuItemValues: () =>
    getDomItems().map(
      (element) =>
        element.getAttribute(MDCSelectFoundation.strings.VALUE_ATTR) || ""
    ),
  getMenuItemTextAtIndex: (index: number) =>
    getDomItemAtIndex(index)?.textContent || "",
  getSelectedIndex: () =>
    getDomItems().reduce(
      (acc, item, index) =>
        item.classList.contains("mdc-deprecated-list-item--selected")
          ? index
          : acc,
      -1
    ),
  setSelectedIndex: (index: number) => {
    getDomItems().forEach((item) => {
      item.classList.remove("mdc-deprecated-list-item--selected");
    });
    getDomItemAtIndex(index)?.classList.add(
      "mdc-deprecated-list-item--selected"
    );
  },
  hasLabel: () => !!label,
  floatLabel: (shouldFloat) => labelFoundation.float(shouldFloat),
  getLabelWidth: () => labelFoundation.getWidth(),
  setLabelRequired: (isRequired) => labelFoundation.setRequired(isRequired),
  hasOutline: () => !!outline,
  activateBottomLine: () => lineFoundation.activate(),
  deactivateBottomLine: () => lineFoundation.deactivate(),
  setRippleCenter: (xCoordinate) => lineFoundation.setRippleCenter(xCoordinate),
  notchOutline: (labelWidth) => notchFoundation.notch(labelWidth),
  closeOutline: () => notchFoundation.closeNotch(),
});
