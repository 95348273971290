import { MDCNotchedOutlineAdapter } from "@material/notched-outline";

export type NotchProps = {
  root: React.RefObject<HTMLDivElement | null>;
  notch: React.RefObject<HTMLDivElement | null>;
};

export const createNotchedOutlineAdapter = ({
  root,
  notch,
}: NotchProps): MDCNotchedOutlineAdapter => ({
  addClass: (className) => {
    root.current?.classList.add(className);
  },
  removeClass: (className) => {
    root.current?.classList.remove(className);
  },
  setNotchWidthProperty: (width) =>
    notch.current?.style.setProperty("width", `${width}px`),
  removeNotchWidthProperty: () => notch.current?.style.removeProperty("width"),
});
