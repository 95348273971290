import { Button } from "@natera/material/lib/button";
import Dialog from "@natera/material/lib/dialog";
import * as React from "react";
import { defineMessages, useIntl } from "react-intl";

export interface ConfirmationDialogProps
  extends React.HTMLProps<HTMLDivElement> {
  title: string;
  submitLabel?: string;
  cancelLabel?: string;
  description?: React.ReactNode;
  showCancelButton?: boolean;
  showCrossButton?: boolean;
  resolve: () => void;
  reject: () => void;
  close: () => void;
}

export const messages = defineMessages({
  cancel: {
    id: "platform.dialog.cancel",
    defaultMessage: "Cancel",
    description: "Cancel button",
  },
  confirm: {
    id: "platform.dialog.confirm",
    defaultMessage: "Confirm",
    description: "Confirm button",
  },
});

export const ConfirmationDialog: React.FunctionComponent<ConfirmationDialogProps> = ({
  title,
  cancelLabel,
  submitLabel,
  description,
  showCancelButton = true,
  showCrossButton = false,
  resolve,
  reject,
  close,
  ...rest
}) => {
  const intl = useIntl();

  const rejectHandler = () => {
    reject();
    close();
  };

  const resolveHandler = () => {
    resolve();
    close();
  };

  return (
    <Dialog
      {...rest}
      title={title}
      showCrossButton={showCrossButton}
      onClose={rejectHandler}
      className="confirmation-dialog"
      actions={
        <>
          {showCancelButton && (
            <Button onClick={rejectHandler} className="mdc-dialog__button">
              {cancelLabel ? cancelLabel : intl.formatMessage(messages.cancel)}
            </Button>
          )}
          <Button
            onClick={resolveHandler}
            raised={true}
            className="mdc-dialog__button"
          >
            {submitLabel ? submitLabel : intl.formatMessage(messages.confirm)}
          </Button>
        </>
      }
    >
      {description}
    </Dialog>
  );
};

export default ConfirmationDialog;
