import * as R from "ramda";
import * as React from "react";

interface ControlledStateOpt<T> {
  controlledValue?: T;
  defaultValue?: T;
  onChange?: (value: T | undefined) => void;
}

export function useComponentState<T>({
  controlledValue,
  defaultValue,
  onChange = R.always(undefined),
}: ControlledStateOpt<T>): [
  T | undefined,
  React.Dispatch<React.SetStateAction<T | undefined>>
] {
  const isControlledRef = React.useRef<boolean>();

  const isControlled = React.useMemo(() => {
    if (isControlledRef.current) {
      return true;
    }

    if (defaultValue !== undefined) {
      return false;
    }

    if (controlledValue !== undefined) {
      return true;
    }

    return false;
  }, [controlledValue]);

  isControlledRef.current = isControlled;

  const externals = React.useRef({
    onChange,
  });
  React.useEffect(() => {
    externals.current.onChange = onChange;
  }, [onChange]);

  const [value$, setValue$] = React.useState(defaultValue);
  const value = isControlled ? controlledValue : value$;
  const setValue = React.useCallback(
    (newValue: T | undefined) => {
      if (!isControlled) {
        setValue$(newValue);
      }
      externals.current.onChange(newValue);
    },
    [isControlled]
  );
  return [value, setValue];
}

export default useComponentState;
