"use client";

import React, { FC } from "react";
import { Svg } from "../svg";
import ClearIcon from "@natera/material/assets/svg/clear.svg";
import { DropDownIcon } from "../select";
import * as R from "ramda";

export interface TextfieldProps {
  trailingIcon?: React.ReactNode;
  isClearable?: boolean;
  selectable?: boolean;
  dropDownActive?: boolean;
  onClear?: () => void;
  onSelectionOpen?: () => void;
  focus?: boolean;
}

const TrailingIcon: FC<TextfieldProps> = ({
  trailingIcon,
  focus,
  isClearable,
  onClear = R.always(undefined),
  selectable,
  dropDownActive,
  onSelectionOpen,
}) => {
  const mouseClearHandler: React.MouseEventHandler = React.useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      onClear();
    },
    [onClear]
  );

  const keyboardClearHandler: React.KeyboardEventHandler = React.useCallback(
    (event) => {
      if (
        event.key === "Enter" ||
        event.key === " " ||
        event.key === "Spacebar"
      ) {
        event.preventDefault();
        event.stopPropagation();

        onClear();
      }
    },
    [onClear]
  );

  if (trailingIcon) {
    if (typeof trailingIcon === "string") {
      return <Svg content={trailingIcon} />;
    }
    return <React.Fragment>{trailingIcon}</React.Fragment>;
  }

  if (isClearable) {
    return (
      <Svg
        className="mdc-text-field__icon-button"
        tabIndex={0}
        role="button"
        content={ClearIcon}
        onClick={mouseClearHandler}
        onKeyDown={keyboardClearHandler}
      />
    );
  }

  if (selectable) {
    return (
      <DropDownIcon
        focused={R.isNil(dropDownActive) ? focus : dropDownActive}
        onClick={onSelectionOpen}
      />
    );
  }
  return null;
};

export default React.memo(TrailingIcon);
