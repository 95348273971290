import classnames from "classnames";
import * as React from "react";

import "./grid.scss";

export type GridProps = React.HTMLAttributes<HTMLDivElement>;

export const Grid: React.FunctionComponent<GridProps> = ({
  children,
  className,
  ...props
}) => (
  <div
    {...props}
    className={classnames("natera-layout-grid", "mdc-layout-grid", className)}
  >
    {children}
  </div>
);

export default Grid;
