"use client";

import {
  ListItem,
  ListItemProps,
  ListItemText,
} from "@natera/material/lib/list";

import classnames from "classnames";
import * as React from "react";
import { SelectContext } from "./select";
import "./select.scss";

export interface OptionProps extends ListItemProps {
  value: string | string[] | number;
  disabled?: boolean;
  selected?: boolean;
  className?: string;
  hidden?: boolean;
}

export const Option: React.FunctionComponent<React.PropsWithChildren<
  OptionProps
>> = ({
  children,
  className,
  selected = false,
  disabled = false,
  hidden = false,
  value,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref,
  ...props
}) => {
  const { onItemAction, hasLeadingIcon } = React.useContext(SelectContext);

  return (
    <ListItem
      className={classnames(className, {
        "mdc-deprecated-list-item--hidden": hidden,
      })}
      selected={selected}
      role="option"
      aria-selected={selected}
      aria-disabled={disabled}
      data-value={value}
      disabled={disabled}
      onItemAction={onItemAction}
      hidden={hidden}
      {...props}
    >
      {hasLeadingIcon && <span className="mdc-deprecated-list-item__graphic" />}
      <ListItemText>{children}</ListItemText>
    </ListItem>
  );
};

export default Option;
