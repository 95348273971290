"use client";

import { applyPassive } from "@material/dom/events";
import { matches } from "@material/dom/ponyfill";
import { MDCCheckboxFoundation } from "@material/checkbox";
import { util } from "@material/ripple";
import { MDCRippleAdapter } from "@material/ripple/adapter";
import { MDCRippleFoundation } from "@material/ripple/foundation";

import Label from "@natera/material/lib/label";
import classnames from "classnames";
import * as React from "react";

import "./checkbox.scss";

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  label?: React.ReactNode;
  leftLabel?: React.ReactNode;
  indeterminate?: boolean;
  onLabelClick?: React.MouseEventHandler<HTMLLabelElement>;
  rounded?: boolean;
}

export const Checkbox: React.FunctionComponent<CheckboxProps> = ({
  className,
  label,
  leftLabel,
  id,
  disabled,
  indeterminate = false,
  rounded = false,
  onLabelClick,
  ...props
}) => {
  const checkboxRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [rippleFoundation, setRippleFoundation] = React.useState<
    MDCRippleFoundation
  >();

  const createRippleAdapter = (
    checkboxElement: HTMLDivElement,
    inputElement: HTMLInputElement
  ): MDCRippleAdapter => ({
    browserSupportsCssVars: () => util.supportsCssVariables(window),
    computeBoundingRect: () => checkboxElement.getBoundingClientRect(),
    containsEventTarget: (target) => checkboxElement.contains(target as Node),
    registerDocumentInteractionHandler: (evtType, handler) =>
      document.documentElement.addEventListener(
        evtType,
        handler,
        applyPassive()
      ),
    deregisterDocumentInteractionHandler: (evtType, handler) =>
      document.documentElement.removeEventListener(
        evtType,
        handler,
        applyPassive()
      ),
    registerResizeHandler: (handler) =>
      window.addEventListener("resize", handler),
    deregisterResizeHandler: (handler) =>
      window.removeEventListener("resize", handler),
    getWindowPageOffset: () => ({
      x: window.pageXOffset,
      y: window.pageYOffset,
    }),
    addClass: (cName) => checkboxElement.classList.add(cName),
    removeClass: (cName) => checkboxElement.classList.remove(cName),
    updateCssVariable: (varName, value) =>
      checkboxElement.style.setProperty(varName, value),
    deregisterInteractionHandler: (evtType, handler) => {
      inputElement.removeEventListener(evtType, handler, applyPassive());
    },
    isSurfaceActive: () => matches(inputElement, ":active"),
    isSurfaceDisabled: () => disabled || false,
    isUnbounded: () => true,
    registerInteractionHandler: (evtType, handler) => {
      inputElement.addEventListener(evtType, handler, applyPassive());
    },
  });

  React.useEffect(() => {
    if (checkboxRef.current && inputRef.current) {
      const adapter = createRippleAdapter(
        checkboxRef.current,
        inputRef.current
      );
      const mdcRippleFoundation = new MDCRippleFoundation(adapter);
      setRippleFoundation(mdcRippleFoundation);
    }
  }, []);

  React.useEffect(() => {
    if (!rippleFoundation) {
      return;
    }

    rippleFoundation.init();

    return function () {
      rippleFoundation?.destroy();
    };
  }, [rippleFoundation]);

  return (
    <div className="mdc-checkbox-field">
      {leftLabel && (
        <Label htmlFor={id} onClick={onLabelClick}>
          {leftLabel}
        </Label>
      )}
      <div
        ref={checkboxRef}
        className={classnames(
          MDCCheckboxFoundation.cssClasses.ROOT,
          className,
          {
            [MDCCheckboxFoundation.cssClasses.DISABLED]: disabled,
            "mdc-checkbox--rounded": rounded,
          }
        )}
      >
        <input
          ref={inputRef}
          id={id}
          className={classnames(
            MDCCheckboxFoundation.cssClasses.NATIVE_CONTROL,
            className
          )}
          type="checkbox"
          disabled={disabled}
          data-indeterminate={indeterminate}
          {...props}
        />
        <div className={MDCCheckboxFoundation.cssClasses.BACKGROUND}>
          <svg
            className={MDCCheckboxFoundation.cssClasses.CHECKMARK}
            viewBox="0 0 24 24"
          >
            <path
              className={MDCCheckboxFoundation.cssClasses.CHECKMARK_PATH}
              fill="none"
              d="M1.73,12.91 8.1,19.28 22.79,4.59"
            />
          </svg>
          <div className={MDCCheckboxFoundation.cssClasses.MIXEDMARK} />
        </div>
        <div className="mdc-checkbox__ripple" />
      </div>
      {label && (
        <Label htmlFor={id} onClick={onLabelClick}>
          {label}
        </Label>
      )}
    </div>
  );
};

export default Checkbox;
