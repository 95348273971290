import React, { FC, useMemo } from "react";
import { WeekControls } from "../../components/week-controls";
import { DEFAULT_SLOT_TIMES } from "../../constants";
import { DateRange, SlotTime } from "../../definitions";
import { addDays } from "../../helpers";
import { SelectedSlots } from "../selected-slots";
import { WeekSlots } from "../week-slots";
import { WeekTitle } from "../week-title";
import "./Timeslots.scss";
import { TimeslotsProvider } from "./TimeslotsContext";

interface TimeslotProps {
  startWeek: Date;
  endWeek?: number | Date;
  selectedSlots: DateRange[];
  onChange: (steps: DateRange[]) => void;
  disabledSlots?: DateRange[];
  slotTimes?: SlotTime[];
  maxSlots?: number;
  currentDate?: number | Date;
  availableFrom?: number | Date;
  alert?: React.ReactNode;
  mobile?: boolean;
  oneSlotPerDay?: boolean;
  showSlotPlaceholder?: boolean;
  setRemoveTimeslotAriaLabel?: (slot: DateRange) => string;
  renderSelectedSlotTime?: (slot: DateRange) => React.ReactNode;
  renderSlotChipTime?: (slot: DateRange) => React.ReactNode;
}

export const Timeslots: FC<TimeslotProps> = ({
  startWeek,
  selectedSlots,
  onChange,
  disabledSlots = [],
  slotTimes = DEFAULT_SLOT_TIMES,
  maxSlots = 3,
  alert,
  endWeek: endWeekProp,
  currentDate: currentDateProp,
  availableFrom: availableFromProp,
  oneSlotPerDay = false,
  showSlotPlaceholder = false,
  setRemoveTimeslotAriaLabel,
  renderSelectedSlotTime,
  renderSlotChipTime,
  mobile,
}) => {
  const currentDate = useMemo(
    () => (currentDateProp && new Date(currentDateProp)) || new Date(),
    [currentDateProp]
  );

  const availableFrom = availableFromProp
    ? new Date(availableFromProp)
    : addDays(currentDate, 3);

  const endWeek = endWeekProp
    ? new Date(endWeekProp)
    : addDays(startWeek, 2 * 7);

  return (
    <div className="mobile-phlebotomy__timeslots">
      <TimeslotsProvider
        focusDay={availableFrom}
        focusWeek={startWeek}
        endWeek={endWeek}
        renderSelectedSlotTime={renderSelectedSlotTime}
        renderSlotChipTime={renderSlotChipTime}
        setRemoveTimeslotAriaLabel={setRemoveTimeslotAriaLabel}
      >
        <SelectedSlots
          selectedSlots={selectedSlots}
          maxSlots={maxSlots}
          onChange={onChange}
          mobile={mobile}
          showSlotPlaceholder={showSlotPlaceholder}
        />
        {alert}
        <WeekTitle mobile={mobile} startWeek={startWeek} endWeek={endWeek} />
        <WeekSlots
          selectedSlots={selectedSlots}
          disabledSlots={disabledSlots}
          maxSlots={maxSlots}
          slotTimes={slotTimes}
          onChange={onChange}
          currentDate={currentDate}
          availableFrom={availableFrom}
          oneSlotPerDay={oneSlotPerDay}
          mobile={mobile}
        />
        {!mobile && <WeekControls startWeek={startWeek} endWeek={endWeek} />}
      </TimeslotsProvider>
    </div>
  );
};
