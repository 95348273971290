import { HttpCollection } from "@natera/platform/lib/service";
import * as R from "ramda";
import * as React from "react";
import { BehaviorSubject, merge } from "rxjs";
import { withLatestFrom } from "rxjs/operators";
import { CollectionController, useCollection } from "./useCollection";
import { Limit, Page, Paginator, usePagination } from "./usePagination";
import { Index, StorageController } from "./useStorage";

type GetItems<T> = () => T[];
type Load<T> = (page: Page, limit: Limit) => Promise<HttpCollection<T>>;

interface Props<T> {
  load: Load<T>;
  storage?: StorageController<T>;
  page?: Page;
  limit?: Limit;
  initialRecords?: T[];
}

interface PaginatedCollectionController<T> extends CollectionController<T> {
  getPaginator: () => Paginator;
}

export const usePaginatedCollection = <T>({
  load,
  storage,
  page = 0,
  limit = 20,
  initialRecords = [],
}: Props<T>): PaginatedCollectionController<T> => {
  interface PaginationItem {
    index: Index | undefined;
    record: T;
  }

  const recordToPaginationItem = (record: T): PaginationItem => ({
    index: storage && storage.recordIndex(record),
    record,
  });

  const pagination = usePagination<PaginationItem>({
    defaultLimit: limit,
    defaultPage: page,
    initialRecords: initialRecords.map(recordToPaginationItem),
  });

  const currentPage = pagination.getPage();
  const currentLimit = pagination.getLimit();

  const props = React.useMemo(
    () => ({
      currentPage: new BehaviorSubject(currentPage),
      currentLimit: new BehaviorSubject(currentLimit),
    }),
    []
  );

  React.useLayoutEffect(() => {
    props.currentPage.next(currentPage);
  }, [currentPage]);

  React.useLayoutEffect(() => {
    props.currentLimit.next(currentLimit);
  }, [currentLimit]);

  const loadCollection = React.useCallback(
    () => load(currentPage, currentLimit),
    [currentPage, currentLimit, load]
  );

  const collection = useCollection<T>({
    load: loadCollection,
    storage,
  });

  React.useLayoutEffect(() => {
    // TODO: We should avoid load collection automatically and delegate this behaviour to consumers
    const pageSubscription = merge(
      props.currentPage,
      props.currentLimit
    ).subscribe(() => {
      collection.load();
    });

    const collectionSubscription = collection
      .asObservable()
      .pipe(withLatestFrom(props.currentPage))
      .subscribe(([httpCollection, page$]) => {
        pagination.setTotal(httpCollection.getTotal());
        pagination.setRecords(
          // TODO: here we need to explicitly inject page instead of using one from state
          page$,
          httpCollection.getItems().map(recordToPaginationItem)
        );
      });

    return () => {
      pageSubscription.unsubscribe();
      collectionSubscription.unsubscribe();
    };
  }, []);

  const getItems: GetItems<T> = React.useCallback(() => {
    const paginatedItems = pagination.getRecords().map((item) => {
      if (storage) {
        return item.index ? storage.getRecord(item.index) : undefined;
      }

      return item.record;
    });

    return paginatedItems.filter((value): value is T => !R.isNil(value));
  }, [pagination.getRecords, storage?.getRecord]);

  return React.useMemo<PaginatedCollectionController<T>>(
    () => ({
      asObservable: collection.asObservable,
      getItems,
      load: collection.load,
      isLoading: collection.isLoading,
      getResource: collection.getResource,
      getPaginator: pagination.getPaginator,
    }),
    [getItems, collection]
  );
};
