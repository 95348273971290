interface HttpResourceErrorInterface {
  cause: Error;
  resourceName: string;
  resourceId: string;
}

class HttpResourceError extends Error implements HttpResourceErrorInterface {
  constructor(
    readonly cause: Error,
    readonly resourceName: string,
    readonly resourceId: string
  ) {
    super(cause.message);
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, HttpResourceError.prototype);
  }
}

// @ts-ignore
HttpResourceError.prototype = new Error();

export default HttpResourceError;
