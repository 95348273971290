"use client";

import React from "react";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";

import {
  MDCTooltip,
  XPosition,
  YPosition,
  CssClasses,
  MDCTooltipFoundation,
} from "@material/tooltip";
import { ClientPortal } from "@natera/material/lib/portal";
import { IconButton } from "@natera/material/lib/button";

import ClearIcon from "@natera/material/assets/svg/clear.svg";

import "./tooltip.scss";

export type TooltipPosition = {
  xPos?: XPosition;
  yPos?: YPosition;
};

export interface TooltipProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "content" | "title"> {
  title?: React.ReactNode;
  content?: React.ReactNode;
  actions?: React.ReactNode;
  children: React.FunctionComponentElement<{
    ref: React.ForwardedRef<HTMLElement>;
    className?: string;
    type: string;
  }>;
  opened?: boolean;
  className?: string;
  position?: TooltipPosition;
  timeOut?: number;
  container?: HTMLElement;
  rich?: boolean;
  persistent?: boolean;
  inverse?: boolean;
  showCloseIcon?: boolean;
}

type TooltipElement = HTMLDivElement;

class TooltipComponent extends MDCTooltip {
  getFoundation(): MDCTooltipFoundation {
    return this.foundation;
  }
  show() {
    return this.foundation.show();
  }
}

export const Tooltip: React.FC<TooltipProps> = ({
  id,
  title,
  content,
  actions,
  children,
  position = { xPos: 0, yPos: 0 },
  opened = false,
  className,
  timeOut,
  container,
  rich,
  persistent,
  inverse,
  showCloseIcon,
  ...props
}) => {
  const tooltipRef = React.useRef<TooltipElement>();
  const tooltipComponentRef = React.useRef<TooltipComponent>();
  const [tooltipId, setTooltipId] = React.useState<string>();

  React.useEffect(() => {
    setTooltipId(id || uuidv4());
  }, [id]);

  const destroyTooltipComponent = React.useCallback(() => {
    tooltipComponentRef.current?.destroy();
    tooltipComponentRef.current = undefined;
  }, []);

  const initTooltipComponent = React.useCallback(() => {
    if (!tooltipRef.current) {
      return;
    }

    destroyTooltipComponent();

    const tooltipComponent = new TooltipComponent(tooltipRef.current);
    tooltipComponentRef.current = tooltipComponent;

    tooltipComponent.setTooltipPosition(position);

    if (timeOut) {
      tooltipComponent.setHideDelay(timeOut);
    }

    if (opened) {
      tooltipComponent.show();
    }
  }, [rich]);

  React.useEffect(() => {
    if (timeOut) {
      tooltipComponentRef.current?.setHideDelay(timeOut);
    }
  }, [timeOut]);

  React.useEffect(() => {
    tooltipComponentRef.current?.setTooltipPosition(position);
    recalculateTooltipPosition();
  }, [JSON.stringify(position)]);

  const reopen = () => {
    tooltipComponentRef.current?.hide();
    tooltipComponentRef.current?.show();
  };
  const close = () => {
    tooltipComponentRef.current?.hide();
  };

  const recalculateTooltipPosition = () => {
    const tooltipRect = tooltipRef.current?.getBoundingClientRect();

    if (
      position.xPos === 0 &&
      tooltipRect &&
      (tooltipRect.left + tooltipRect.width > document.body.clientWidth ||
        tooltipRect.right - tooltipRect.width < 0)
    ) {
      reopen();
    }

    if (
      position.yPos === 0 &&
      tooltipRect &&
      (tooltipRect.top + tooltipRect.height > document.body.clientHeight ||
        tooltipRect.bottom - tooltipRect.height < 0)
    ) {
      reopen();
    }
  };

  const createTooltipRef = React.useCallback(
    (element: HTMLDivElement) => {
      tooltipRef.current = element;
      if (children.ref) {
        if (children.ref instanceof Function) {
          children.ref(element);
        } else {
          children.ref.current = element;
        }
      }

      initTooltipComponent();
      recalculateTooltipPosition();
    },
    [rich]
  );

  React.useEffect(() => {
    initTooltipComponent();

    return () => {
      destroyTooltipComponent();
    };
  }, [rich, persistent]);

  React.useEffect(() => {
    recalculateTooltipPosition();
  }, [content]);

  return (
    <>
      <children.type
        {...children.props}
        aria-describedby={tooltipId}
        className={classnames(children.props.className)}
        aria-haspopup="dialog"
      />
      {Boolean(content) && (
        <ClientPortal container={container}>
          <div
            {...props}
            id={tooltipId}
            ref={createTooltipRef}
            tabIndex={persistent ? -1 : undefined}
            data-mdc-tooltip-persistent={!rich || persistent}
            className={classnames(
              "mdc-tooltip",
              {
                [CssClasses.RICH]: rich,
                "mdc-tooltip--inverse": inverse,
              },
              className
            )}
          >
            <div
              className={classnames(
                CssClasses.SURFACE,
                CssClasses.SURFACE_ANIMATION,
                { "mdc-tooltip--with-close-icon": showCloseIcon }
              )}
            >
              {rich ? (
                <>
                  {title && <h2 className="mdc-tooltip__title">{title}</h2>}
                  <p className="mdc-tooltip__content">{content}</p>
                  {actions && (
                    <div className="mdc-tooltip--rich-actions" onClick={close}>
                      {actions}
                    </div>
                  )}
                  {showCloseIcon && (
                    <IconButton
                      className="mdc-tooltip__close-icon"
                      onClick={close}
                    >
                      {ClearIcon}
                    </IconButton>
                  )}
                </>
              ) : (
                <>{content || title}</>
              )}
            </div>
          </div>
        </ClientPortal>
      )}
    </>
  );
};

Tooltip.displayName = "Tooltip";

export default Tooltip;
