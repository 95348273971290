import * as React from "react";
import classnames from "classnames";
import { Grid, GridRow, GridCell } from "@natera/material/lib/layout";
import { Checkbox } from "@natera/material/lib/checkbox";
import * as R from "ramda";
import { Textfield } from "@natera/platform/lib/components/form/field";
import { defineMessages, useIntl } from "react-intl";
import "./ethnicitySelect.scss";

type EthnicityCode = string;

interface Ethnicity {
  code: EthnicityCode;
  description: string;
}

export interface EthnicityController {
  getEthnicities: () => Ethnicity[];
  isOtherEthnicities: (value: string) => boolean;
}

export const EthnicityContext = React.createContext<EthnicityController>({
  getEthnicities: () => [],
  isOtherEthnicities: () => false,
});

export interface EthnicitySelectProps
  extends React.HTMLAttributes<HTMLDivElement> {
  onChangeValue?: (value: string[]) => void;
  value?: string[];
  label?: string | React.ReactNode;
}

export const messages = defineMessages({
  other: {
    id: "patient.ethnicitySelect.other",
    defaultMessage: "Other",
  },
});

const getOtherEthnicitiesArray = (value: string): string[] => {
  return value.length ? value.split(",").map(R.trim) : [];
};

const EthnicitySelect = React.forwardRef<HTMLDivElement, EthnicitySelectProps>(
  ({ className, onChangeValue, value = [], label, ...props }, ref) => {
    const [isOther, setIsOther] = React.useState(false);
    const isMounted = React.useRef(false);
    const [otherEthnicities, setOtherEthnicities] = React.useState("");
    const [selectedEthnicities, setSelectedEthnicities] = React.useState<
      string[]
    >([]);
    const ethnicityContext = React.useContext(EthnicityContext);
    const intl = useIntl();
    const ethnicities = ethnicityContext.getEthnicities();

    const toggleIsOther = () => {
      setIsOther((isOther) => !isOther);
    };

    const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      const { checked, value } = e.currentTarget;

      setSelectedEthnicities(
        checked
          ? [...selectedEthnicities, value]
          : R.without([value], selectedEthnicities)
      );
    };

    const inputHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      setOtherEthnicities(e.currentTarget.value);
    };

    React.useEffect(() => {
      if (!isMounted.current) {
        const [initialOtherEthnicities, initialSelectedEthnicities] =
          R.partition(ethnicityContext.isOtherEthnicities, value || []);

        setSelectedEthnicities(initialSelectedEthnicities);
        setOtherEthnicities(initialOtherEthnicities.join(", "));
        setIsOther(!!initialOtherEthnicities.length);
      }
    }, [value]);

    React.useEffect(() => {
      if (isMounted.current) {
        if (onChangeValue) {
          const currentValue = isOther
            ? R.union(
                selectedEthnicities,
                getOtherEthnicitiesArray(R.toUpper(otherEthnicities))
              )
            : selectedEthnicities;

          onChangeValue(currentValue);
        }
      } else {
        isMounted.current = true;
      }
    }, [selectedEthnicities, isOther, otherEthnicities]);

    return (
      <div
        className={classnames(className, "ethnicity-select")}
        ref={ref}
        {...props}
      >
        <div className="ethnicity-select__title">{label}</div>

        <div className="ethnicity-select__list">
          <Grid>
            <GridRow>
              {ethnicities.map((ethnicity) => (
                <GridCell key={ethnicity.code} span={12}>
                  <Checkbox
                    id={`ethnicity-${ethnicity.code}`}
                    value={ethnicity.code}
                    checked={selectedEthnicities.includes(ethnicity.code)}
                    label={ethnicity.description}
                    leftLabel={false}
                    onChange={onChangeHandler}
                  />
                </GridCell>
              ))}

              <GridCell span={12}>
                <Checkbox
                  id="ethnicity-other"
                  label={intl.formatMessage(messages.other)}
                  leftLabel={false}
                  checked={isOther}
                  onChange={toggleIsOther}
                />

                <Textfield
                  disabled={!isOther}
                  value={otherEthnicities}
                  onChange={inputHandler}
                />
              </GridCell>
            </GridRow>
          </Grid>
        </div>
      </div>
    );
  }
);

export default EthnicitySelect;
