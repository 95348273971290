"use client";

import {
  MaterialOption as Option,
  MaterialSelect as Select,
} from "@natera/material/lib/select";
import { DateInputContext } from "./context";

import * as R from "ramda";
import * as React from "react";
import { getLocalizedMonths, isAfter, isBefore } from "../utils";

interface Props {
  date: Date;
}

const MonthYearSelect: React.FunctionComponent<Props> = ({ date }) => {
  const {
    minDate,
    maxDate,
    changeMonthYear,
    formatMonthInMenu,
  } = React.useContext(DateInputContext);

  const getMonths = getLocalizedMonths(formatMonthInMenu);

  const months = React.useMemo(
    () =>
      getMonths.filter((_, index) => {
        const maxDateIndex =
          maxDate.getFullYear() > date.getFullYear() ? 11 : maxDate.getMonth();
        const minDateIndex =
          minDate.getFullYear() < date.getFullYear() ? 0 : minDate.getMonth();
        return index <= maxDateIndex && index >= minDateIndex;
      }),
    [maxDate, minDate, date]
  );

  const years = React.useMemo(
    () =>
      R.reduce(
        (years$, n) => [...years$, maxDate.getFullYear() - n],
        [maxDate.getFullYear()],
        R.range(1, maxDate.getFullYear() - minDate.getFullYear() + 1)
      ),
    [maxDate, minDate]
  );

  const handleChange = React.useCallback(
    (month: number, year: number) => {
      const date$ = new Date(year, month);

      if (maxDate && isAfter(date$, maxDate)) {
        changeMonthYear(maxDate);
      } else if (minDate && isBefore(date$, minDate)) {
        changeMonthYear(minDate);
      } else {
        changeMonthYear(date$);
      }
    },
    [changeMonthYear, maxDate, minDate]
  );

  const handleChangeMonth = React.useCallback(
    (month: number) => handleChange(Number(month), date.getFullYear()),
    [handleChange, date.getFullYear()]
  );

  const handleChangeYear = React.useCallback(
    (year: number) => handleChange(date.getMonth(), Number(year)),
    [handleChange, date.getMonth()]
  );

  return (
    <div className="rdp-base-month-year">
      <Select
        className="rdp-base-month-year-select rdp-base-month-year-select-month"
        name="month"
        onValueChange={handleChangeMonth}
        value={date.getMonth()}
      >
        {getMonths.map((month) => (
          <Option
            key={month}
            value={getMonths.indexOf(month)}
            hidden={!months.includes(month)}
          >
            {month}
          </Option>
        ))}
      </Select>
      <Select
        className="rdp-base-month-year-select rdp-base-month-year-select-year"
        name="year"
        onValueChange={handleChangeYear}
        value={date.getFullYear()}
      >
        {years.map((year) => (
          <Option key={year} value={year}>
            {year}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default MonthYearSelect;
