import classnames from "classnames";
import * as React from "react";
import { defineMessages } from "react-intl";
import StepperProvider, { StepperController } from "./context";
import { StepId } from "./typings";
import StepperHeader from "./stepperHeader";

import "./stepper.scss";

type ParentProps = Omit<React.HTMLAttributes<HTMLDivElement>, "title">;
export interface StepperProps extends ParentProps {
  title?: React.ReactNode;
  initialStep: StepId;
  contextRef?: React.MutableRefObject<StepperController>;
  className?: string;
  progressStepper?: boolean;
}

export const messages = defineMessages({
  stepCount: {
    id: "stepper.header.stepCount",
    defaultMessage: "Step {resolvedSteps} of {total}",
  },
});

export const Stepper: React.FunctionComponent<StepperProps> = ({
  title,
  initialStep,
  className,
  contextRef,
  children,
  progressStepper = false,
  ...props
}) => (
  <StepperProvider initialStep={initialStep} contextRef={contextRef}>
    {({ getSteps, getCurrentStep, getNumberOfResolvedSteps, getProgress }) => {
      const currentStep = getCurrentStep();
      const steps = getSteps();
      const numberOfResolvedSteps = getNumberOfResolvedSteps();
      const progress = getProgress();

      return (
        <div
          className={classnames(
            progressStepper ? "progress-stepper" : "stepper",
            className
          )}
          {...props}
        >
          {children}
          <StepperHeader
            title={title}
            progressStepper={progressStepper}
            progress={progress}
            numberOfResolvedSteps={numberOfResolvedSteps}
            steps={steps}
          />
          {steps.map((step) => (
            <React.Fragment key={step.stepId}>
              {step === currentStep && (
                <section
                  className={
                    progressStepper
                      ? `progress-stepper__section`
                      : `stepper__section`
                  }
                >
                  {step.children}
                </section>
              )}
            </React.Fragment>
          ))}
        </div>
      );
    }}
  </StepperProvider>
);

export default Stepper;
